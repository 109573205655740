import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.palette.primary.main,
            fontSize: 22,
            fontWeight: 400,
            top: '-26px'
        },
        subLabel: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.custom.flexTextField.subLabelColor
        },
        inputHelperText: {
            fontSize: 22,
            fontWeight: 400,
            color: theme.custom.flexTextField.inputTextHelperColor
        },
        inputHelperTextInTheEnd: {
            fontSize: 22,
            fontWeight: 400,
            color: theme.custom.flexTextField.inputTextHelperColor,
            textAlign: 'end'
        },
        errorText: {
            ...theme.typography.body1
        }
    }
})