import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        wrap: {
            backgroundColor: theme.custom.aboutFlex.background
        },
        container: {
            width: '100%',
            maxWidth: 1130,
            paddingTop: 110,
            paddingBottom: 140,
            [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
                padding: '80px 32px 80px'
            }
        },
        title: {
            color: theme.custom.aboutFlex.text,
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
                fontSize: 16
            }
        },
        description: {
            color: theme.custom.aboutFlex.text,
            marginTop: 8,
            fontFamily: 'Times New Roman',
            fontStyle: 'italic'
        }
    }
})
export { useStyles }
