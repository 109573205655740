import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            maxWidth: '1440px',
            margin: '0 auto',
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        link:{
            margin: '18px 9px',
            textDecoration: 'none',
            [theme.breakpoints.down('xs')]: {
                margin: '4px 7px',
                width: '100%'
            }
        }
    }
})
export { useStyles }
