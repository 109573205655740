export interface FormProductEntity {
    product3DModel: string | File
    productZip:  string | Blob
    productImage: string | File
    productName:  string
    description:  string
    dropDate: Date
    numberOfModels:  string | number
    price:  string | number
}

export class FormProductEntity implements FormProductEntity {
    constructor(formValues?: FormProductEntity) {
        this.product3DModel = formValues?.product3DModel || ''
        this.productZip = formValues?.productZip || ''
        this.productImage = formValues?.productImage || ''
        this.productName = formValues?.productName || ''
        this.description = formValues?.description || ''
        this.dropDate =  formValues?.dropDate || new Date(new Date().setDate(new Date().getDate() + 7))
        this.numberOfModels = formValues?.numberOfModels || ''
        this.price = formValues?.price || ''
    }
}