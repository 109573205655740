import React, { useContext } from 'react'

import { useStyles } from './MoonpayModal.style'
import { Modal } from 'components/Modal'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import { useConnectedAccount } from 'hooks/useConnectedAccount'

export const MoonpayModal = (): JSX.Element => {
    const classes = useStyles()

    const { state, dispatch } = useContext(ProductContext)
    const account = useConnectedAccount()

    const onMoonpayModalClose = () => {
        dispatch({ type: ProductActions.MOONPAY_MODAL_STATE, payload: false })
    }

    return (
        <Modal
            width={500}
            height={550}
            open={state.moonpayModalState}
            title=""
            onClose={onMoonpayModalClose}
        >
            <div className={classes.moonpayContainer}>
                <iframe
                    allow="autoplay; payment"
                    frameBorder="0"
                    height="100%"
                    src={"https://buy.moonpay.com?apiKey=pk_test_4nKaFEXz6dsEd1XUbDLYaOp0gw4IDx&currencyCode=xtz&walletAddress="+account?.address}
                    width="100%"
                >
                    <p>Your browser does not support iframes.</p>
                </iframe>
            </div>
        </Modal>
    );
}
