import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        topBox: {
            [theme.breakpoints.down('xs')]: {
                alignItems: 'center'
            }
        },
        closeButton: {
            width: 'auto',
            minWidth: '42px',
            marginTop: '3px',
            padding: 0,
            boxSizing: 'border-box',
            [theme.breakpoints.down('xs')]: {
                marginTop: '0'
            }
        },
        searchBox: {
            padding: '15px 35px',
            borderBottom: '1px solid rgba(256, 256, 256, 0.3)',
            [theme.breakpoints.down('xs')]: {
                padding: '0 15px 20px',
                borderBottom: 'none'
            }
        },
        logoWrp: {
            width: 73
        },
        logo: {
            width: '100%',
            maxWidth: '100%'
        },
        searchInput: {
            marginTop: 8,
            width: '100%',
            '& div': {
                width: '100%',
                border: 'none'
            },
            '& input': {
                height: 50,
                color: theme.palette.secondary.main,
                ...theme.typography.h2
            },
            [theme.breakpoints.down('xs')]: {
                '& input': {
                    height: 40
                }
            }
        },
        autoComplete: {
            overflowY: 'scroll',
            height: 'calc(100vh - 170px)',
            borderBottom: '1px solid rgba(256, 256, 256, 0.3)',
            [theme.breakpoints.down('xs')]: {
                height: 'calc(100vh - 196px)'
            }
        },
        searchItemWrap: {
            cursor: 'pointer'
        },
        letterBox: {
            padding: '2px 0 2px 130px',
            borderTop: '1px solid rgba(256, 256, 256, 0.3)',
            borderBottom: '1px solid rgba(256, 256, 256, 0.3)',
            backgroundColor: 'rgba(256, 256, 256, 0.1)',
            [theme.breakpoints.down('xs')]: {
                borderTop: 'none',
                borderBottom: 'none',
                backgroundColor: 'unset',
                padding: '10px 0 5px 110px'
            }
        },
        messageContainer: {
            padding: '30px 0'
        }
    }
})
export { useStyles }
