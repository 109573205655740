import React from 'react'

import { Modal as MUIModal, Box, BoxProps, Grid, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './styles'

export interface ModalProps extends BoxProps {
    open: boolean
    title?: string
    titleClass?: string
    onClose: () => void
}

export function Modal({
    width,
    height,
    children,
    title,
    titleClass,
    open,
    onClose,
    className,
    ...props
}: ModalProps): JSX.Element {
    const classes = useStyles({ width, height })
    return (
        <MUIModal onClose={onClose} open={open}>
            <Box className={`${classes.box} ${className}`} {...props}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.modalContentWrapper}
                >
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.modalHeader}>
                        <Typography className={`${classes.tableTitle} ${titleClass}`}>
                            {title}
                        </Typography>
                        <CloseIcon className={classes.closeButton} onClick={onClose} />
                    </Grid>
                    {children}
                </Grid>
            </Box>
        </MUIModal>
    )
}
