import * as yup from 'yup'

export const profileFormSchema = yup.object({
    avatarPicture: yup.mixed(),
    coverPicture: yup.mixed(),
    name: yup.string(),
    email: yup.string().email('This email is not valid'),
    username: yup.string(),
    bio: yup.string().max(240, 'The bio must be less 240 char'),
    instagramUrl: yup.string().url(),
    twitterUrl: yup.string().url(),
    website: yup.string().url(),
    discordUrl: yup.string().url()
})

export type ProfileFormProps = yup.InferType<typeof profileFormSchema>