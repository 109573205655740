import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
    return {
        topBox: {
            paddingBottom: 30
        },
        infoTitle: {
            fontSize: 18
        },
        info_desc: {
            paddingTop: 3,
            wordBreak: 'break-word'
        },
        followStepIcons: {
            marginLeft: 10,
            height: '100%',
            '& img': {
                marginTop: 5
            }
        },
        rotate: {
            animation: '$rotation 1s infinite linear',
            transformOrigin: 'center center'
        },
        '@keyframes rotation': {
            from: { 'transform': 'rotate(0deg)' },
            to: { 'transform': 'rotate(360deg)' }
        }
    }
})

export { useStyles }
