import React, { useContext, useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { AdornedButton } from 'components/Button'
import { Modal } from 'components/Modal'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import { ProductEntity } from 'entities/Product.entity'
import { ProductPreview } from 'components/Product/ProductPreview'
import { apiTokens } from 'services/api/tokens'
import { useAppSelector } from 'hooks/useStoreHooks'

import { useStyles } from './RemoveSaleModal.style'


type RemoveSaleModalProps = {
    product: ProductEntity
}

export const RemoveSaleModal = ({ product }: RemoveSaleModalProps): JSX.Element => {
    const classes = useStyles()
    const { state, dispatch } = useContext(ProductContext)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState<boolean>(false)
    const { accessToken } = useAppSelector(state => state.userReducer)

    const removeSale = async () => {
        try { 
            setLoading(true)
            await apiTokens.remove(accessToken, product.tokenId)
        } catch (error) {
            setLoading(false)
            setErrorMessage('Something went wrong. Please try again later.')
        }
        setLoading(false)
        onSellModalClose()
    }
    const onSellModalClose = () => {
        dispatch({ type: ProductActions.REMOVE_SALE_MODAL_STATE, payload: false })
    }

    return (
        <Modal
            width={740}
            open={state.removeSaleModalState}
            title="ARE YOU SURE YOU WANT TO CANCEL YOUR LISTING?"
            onClose={onSellModalClose}
        >
            <Grid
                container
                justifyContent="space-between"
                wrap="nowrap"
                alignItems="flex-end"
            >
                <ProductPreview key={product.id} className={classes.productPreviewModal} productData={product} />
                <div className={classes.buySection}>
                    <Typography className={classes.errorMsg} variant="h5">{errorMessage}</Typography>
                    
                    <AdornedButton 
                        variant="outlined" 
                        color="primary"
                        loading={loading} 
                        onClick={removeSale}
                    >
                        {loading ? 'Removing...' : 'Confirm'}
                    </AdornedButton>
                </div>
            </Grid>
        </Modal>
    )
}
