import React from 'react'

import { Box, Typography, Container } from '@material-ui/core'
import { Layout } from 'components/Layout'

import { useStyles } from './SiteInfo.style'

enum InfoTypes {
    TERMS = 'TERMS OF USE',
    PRIVACY = 'PRIVACY POLICY',
    ABOUT = 'ABOUT US'
}

type SiteInfoProps = {
    kind: keyof typeof InfoTypes
}

export const SiteInfo = ({ kind }: SiteInfoProps): JSX.Element => {
    const classes = useStyles()
    let title = InfoTypes[kind]

    if (title === InfoTypes.ABOUT) {
        return (
            <Layout>
                <Container className={classes.container}>
                    <Typography className={classes.title}>{title}</Typography>
                    
                    <Typography className={classes.hugeCaption}>
                        {'Our mission is to make digital fashion wearable anywhere and accessible to all.'}
                    </Typography>
                
                    <Box>
                        <Typography className={classes.articleTitle}>
                            {'Who we are'}
                        </Typography>

                        <Typography className={classes.infoTitle}>
                            {'We’ been pioneering  in digital clothes since 2005 and we haven’t stopped since. Our mission'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography className={classes.articleTitle}>
                            {'How does FLEX work?'}
                        </Typography>

                        <Box className={classes.spaceContainer}>
                            <Typography className={classes.infoText}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit nisi, earum dolor exercitationem ullam, culpa ducimus, vitae corporis maxime sint harum beatae quibusdam at repellat obcaecati dicta autem sunt asperiores.
                                Ipsa at cum quam aliquam ea, quidem minima illum assumenda enim nihil error eius alias necessitatibus ratione pariatur? Nobis odit voluptatum a officiis adipisci non, animi reiciendis expedita consequuntur assumenda.
                            </Typography>

                            <Typography className={classes.infoText}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit nisi, earum dolor exercitationem ullam, culpa ducimus, vitae corporis maxime sint harum beatae quibusdam at repellat obcaecati dicta autem sunt asperiores.
                                Ipsa at cum quam aliquam ea, quidem minima illum assumenda enim nihil error eius alias necessitatibus ratione pariatur? Nobis odit voluptatum a officiis adipisci non, animi reiciendis expedita consequuntur assumenda.
                            </Typography>
                            
                            <Typography className={classes.infoText}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit nisi, earum dolor exercitationem ullam, culpa ducimus, vitae corporis maxime sint harum beatae quibusdam at repellat obcaecati dicta autem sunt asperiores.
                                Ipsa at cum quam aliquam ea, quidem minima illum assumenda enim nihil error eius alias necessitatibus ratione pariatur? Nobis odit voluptatum a officiis adipisci non, animi reiciendis expedita consequuntur assumenda.
                            </Typography>

                            <Typography className={classes.infoText}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit nisi, earum dolor exercitationem ullam, culpa ducimus, vitae corporis maxime sint harum beatae quibusdam at repellat obcaecati dicta autem sunt asperiores.
                                Ipsa at cum quam aliquam ea, quidem minima illum assumenda enim nihil error eius alias necessitatibus ratione pariatur? Nobis odit voluptatum a officiis adipisci non, animi reiciendis expedita consequuntur assumenda.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Layout>
        )
    }

    return (
        <Layout>
            <Container className={classes.container}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.date}>July 24th, 2020</Typography>

                <Typography className={classes.infoText}>
                    {'Vyking Limited (a private limited company registered in England and Wales (registered number 11092363) with its registered office at City House, Sutton Park Road, Sutton, SM1 2AE, United Kingdom) ("us", "we", or "our") operates the Vyking SneakerKit iOS mobile application and the Vyking http://www.vyking.io/ website (hereinafter referred to as the "Service").'}
                </Typography>
                <Typography className={classes.infoText}>
                    {'This policy informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.'}
                </Typography>
                
                <Box>
                    <Typography className={classes.articleTitle}>
                        {'Definitions'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Services'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'Services means the Vyking SneakerKit iOS mobile application and website http://www.vyking.io/ operated by Vyking.'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Personal Data'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'Personal Data means data about a living inliidual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Usage Data'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Cookies'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'Cookies are small files stored on your device (computer or mobile device).'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Data Controller'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Data Processors (or Service Providers)'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Data Subject (or User)'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'Data Subject is any living inliidual who is using our Service and is the subject of Personal Data. Information Collection and Use We collect several different types of information for various purposes to provide and improve our Service to you.'}
                    </Typography>
                </Box>

                <Box>
                    <Typography className={classes.articleTitle}>
                        {'Types of Data Collected'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Personal Data'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Usage Data'}
                    </Typography>
                    <Typography className={classes.infoText}>
                        {'We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data"). This Usage Data may include information such as your computer\'s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.'}
                    </Typography>

                    <Typography className={classes.infoTitle}>
                        {'Tracking & Cookies Data'}
                    </Typography>
                    <Box className={classes.spaceContainer}>
                        <Typography className={classes.infoText}>
                            {'We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.'}
                        </Typography>

                        <Typography className={classes.infoText}>
                            {'You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.'}
                        </Typography>

                        <Box>
                            <Typography className={classes.infoText}>
                                {'Examples of Cookies we use:'}
                            </Typography>

                            <Typography className={classes.infoText}>
                                {'Session Cookies. We use Session Cookies to operate our Service.'}
                            </Typography>

                            <Typography className={classes.infoText}>
                                {'Preference Cookies. We use Preference Cookies to remember your preferences and various settings.'}
                            </Typography>

                            <Typography className={classes.infoText}>
                                {'Security Cookies. We use Security Cookies for security purposes.'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Layout>
    )
}