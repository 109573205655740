import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            width: '100%',
            padding: '134px 0 180px',
            background: theme.custom.emailSubscriptions.background,
            borderRadius: 20,
            boxSizing: 'border-box',
            [theme.breakpoints.down('xs')]: {
                padding: '80px 32px'
            }
        },
        emailHeading: {
            fontWeight: 700,
            textTransform: 'uppercase',
            fontStyle: 'italic',
            [theme.breakpoints.down('xs')]: {
                fontSize: 30
            }
        },
        loadingContainer: {
            margin: '100px auto'
        }
    }
})
export { useStyles }
