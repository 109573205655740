import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Grow,
    Paper,
    Popper,
    Button,
    IconButton,
    Typography,
    ClickAwayListener,
    Avatar,
    CircularProgress
} from '@material-ui/core'
import { getAccountBalance } from 'utils/beacon-wallet'
import { AccountEntity } from 'entities/Account.entity'
import { ProfileEntity } from 'entities/Profile.entity' 
import { useAppSelector } from 'hooks/useStoreHooks'
import { ProductActions, ProductContext } from 'context/ProductPageContext'

import { useStyles } from './ConnectWalletModule.style'

interface ConnectWalletProfileProps {
    account: AccountEntity
    disconnect: () => void
}

function ConnectWalletProfile({
    account,
    disconnect
}: ConnectWalletProfileProps): JSX.Element {
    const classes = useStyles()

    const { state, dispatch } = useContext(ProductContext)
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const { user } = useAppSelector(state => state.userReducer)
    const profileData = new ProfileEntity(user)

    const unit = 'TEZ'
    const accountName = 'T'
    const avatarPic = profileData.profileImageUrl === '' ? '/defaultProfileImage.png' : profileData.profileImageUrl

    const [accountBalance, setAccountBalance] = useState<string | undefined>()

    useEffect(() => {
        fetchBalance()
    }, [])

    const fetchBalance = async () => {
        const balance = (await getAccountBalance(account.address)).toString()
        setAccountBalance(balance)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const closeMenu = () => {
        setOpen(false)
    }

    const openMoonpay = () => {
        dispatch({ type: ProductActions.MOONPAY_MODAL_STATE, payload: true })
        setOpen(false)
    }

    const handleDisconnect = () => {
        setOpen(false)
        disconnect()
    }

    return (
        <Grid container direction="row" justifyContent="center">
            <IconButton 
                className={classes.profileSubMenuButton}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                onClick={handleToggle}
            >
                <Avatar className={classes.profileAvatar} src={avatarPic}>{accountName}</Avatar>
            </IconButton >
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                modifiers={{
                    offset: {
                        enabled: true,
                        offset: '0, 20'
                    },
                    preventOverflow: {
                        enabled: true,
                        options: {
                            altBoundary: true
                        }
                    }
                }}
                placement={'bottom'}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom'
                        }}
                    >
                        <Paper className={classes.profileMenuPaper}>
                            <ClickAwayListener onClickAway={closeMenu}>
                                <Grid
                                    className={classes.profileMenu}
                                    container
                                    direction="column"
                                >
                                    {accountBalance ? (
                                        <Typography
                                            variant="subtitle1"
                                            color="primary"
                                            className={classes.profileSubMenuBalance}
                                            noWrap
                                        >
                                            Balance: {`${accountBalance} ${unit}`}
                                        </Typography>
                                    ) : (
                                        <CircularProgress
                                            className={classes.profileProgressBar}
                                            color="secondary"
                                            size={26}
                                        />
                                    )}
                                    <Button
                                        className={classes.profileMenuButton}
                                        variant="outlined"
                                        onClick={openMoonpay}
                                    >
                                        Top-up
                                    </Button>
                                    <Button
                                        className={classes.profileMenuButton}
                                        variant="outlined"
                                        onClick={handleDisconnect}
                                    >
                                        Disconnect
                                    </Button>
                                </Grid>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Grid>
    )
}

export { ConnectWalletProfile }
