import React from 'react'

import { Grid, Typography, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './Alert.style'

interface AlertProps {
    title?: string
    children?: JSX.Element
    onClose?: () => void
    className?: string
}

function Alert({ title, children, onClose, className }: AlertProps): JSX.Element {
    const classes = useStyles()

    return (
        <Grid className={`${classes.container} ${className}`} container alignItems='center' justifyContent="space-between">
            {title ? <Typography variant="body1">{title}</Typography> : <Box>{children}</ Box>}
            {onClose && 
                <CloseIcon onClick={onClose} className={classes.closeButton}/>
            }
        </Grid>
    )
}

export { Alert }
