import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        itemContainer: {
            padding: '23px 35px',
            color: theme.palette.primary.main,
            borderBottom: '1px solid rgba(256, 256, 256, 0.3)',
            '&:last-child': {
                borderBottom: 'none'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '18px 25px'
            }
        },
        itemBox: {
            paddingLeft: '15px'
        },
        itemAvatar: {
            width: 67,
            height: 67
        }
    }
})
export { useStyles }
