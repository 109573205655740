import axios from 'axios'

import { ApiCore } from '../utilities'

const url = 'profile-galleries'
const plural = 'profile-galleries'
const single = 'profile-gallerie'

const apiProfileGalleries = new ApiCore({
    allowGetAll: true,
    allowGetSingle: true,
    allowPost: true,
    allowPut: false,
    allowPatch: false,
    allowRemove: false,
    url: url,
    plural: plural,
    single: single
})

export { apiProfileGalleries }
