import { makeStyles, Theme } from '@material-ui/core'

import { ModalProps } from '.'

export const useStyles = makeStyles<Theme, Pick<ModalProps, 'width' | 'height'>>((theme) => ({
    box: {
        height: (props) => props.height,
        width: (props) => props.width,
        maxHeight: '100%',
        maxWidth: '100%',
        position: 'fixed',
        overflow: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 0,
        background: theme.palette.secondary.main
    },
    modalHeader: {
        alignSelf: 'flex-start'
    },
    tableTitle: {
        fontWeight: 700,
        fontSize: 24
    },
    modalContentWrapper: {
        height: '100%',
        padding: '20px 30px'
    },
    closeButton: {
        cursor: 'pointer'
    }
}))
