import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            maxWidth: '1440px',
            margin: '0 auto',
            padding: '0 18px 20px',
            boxSizing: 'border-box',
            textTransform: 'uppercase',
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '13px 6px 0'
            }
        },
        title: {
            width: '100%',
            margin: '10px 25px',
            fontSize: 36,
            fontWeight: 700,
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                marginBottom: 20,
                fontSize: 28
            }
        },
        previewContainer: {
            cursor: 'pointer',
            maxWidth: '420px',
            borderRadius: '8px',
            margin: '24px',
            '&:hover': {
                boxShadow: '0 0 5px 2px rgba(0,0,0,0.1)'
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                margin: '0 0 13px 0',
                boxShadow: '0 0 5px 2px rgba(0,0,0,0.1)'
            }
        }
    }
})

export { useStyles }
