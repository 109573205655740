import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        productPreviewModal: {
            width: '380px',
            height: '450px',
            margin: '25px 0 0 0',
            padding: '0px 10px 32px',
            boxSizing: 'border-box',
            border: `1px solid ${theme.custom.buyModal.productPreviewBorder}`,
            borderRadius: 0
        },
        buySection: {
            width: '100%',
            maxWidth: 240,
            minWidth: 200,
            boxSizing: 'border-box'
        },
        subSpan: {
            float: 'right',
            '& svg': {
                width: '11px'
            }
        },
        balance: {
            color: theme.custom.buyModal.balanceColor
        },
        price: {
            marginTop: 15
        },
        quantity: {
            marginTop: 10
        },
        line: {
            display: 'block',
            width: '100%',
            height: '1px',
            marginTop: 15,
            background: theme.palette.primary.main
        },
        total: {
            marginTop: 15
        },
        fee: {
            marginTop: 10,
            fontWeight: 400
        },
        buyButton: {
            float: 'right',
            width: '200px',
            height: '40px',
            marginTop: 28,
            padding: '8px 15px'
        },
        orderContainer: {
            height: '450px'
        },
        orderTitle: {
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '40px',
            marginTop: '20px'
        },
        orderTada: {
            height: '70px',
            width: '70px'
        },
        errorMsg: {
            wordBreak: 'keep-all',
            maxWidth: '100%',
            marginTop: 30,
            color: theme.custom.common.red
        },
        notEnoughCrypto: {
            position: 'absolute',
            right: 27,
            bottom: 80,
            width: '200px',
            fontSize: '10px',
            color: theme.custom.common.red
        }
    }
})

export { useStyles }
