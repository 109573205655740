import React from 'react'

import { Link } from 'react-router-dom'
import _ from 'lodash'
import Close from 'shared/icons/close.svg'
import Logo from 'shared/icons/white_logo.png'
import { Input, Button, Grid, Box, Typography, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { getSearchResults, resetSearchState } from 'store/search'
import type { SearchData } from 'store/search'
import { useAppDispatch, useAppSelector } from 'hooks/useStoreHooks'

import { useStyles } from './Search.style'
import { SearchItem } from './SearchItem'

type searchProps = {
    closeSearchPopup: () => void;
}

const DEBOUNCE_TIMEOUT = 300

function Search({ closeSearchPopup }: searchProps): JSX.Element {
    const classes = useStyles()
    const history = useHistory()

    const { loading, message, searchItems } = useAppSelector(state => state.searchReducer)
    const dispatch = useAppDispatch()

    const search = _.debounce((searchText: string) => {
        dispatch(getSearchResults(searchText))
    }, DEBOUNCE_TIMEOUT)

    const handleClose = () => {
        closeSearchPopup()
        dispatch(resetSearchState())
    }

    const handleItemClick = (item: SearchData) => {
        history.push(item.url)
        dispatch(resetSearchState())
        closeSearchPopup()
    }

    return (
        <div>
            <div className={classes.searchBox}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={classes.topBox}
                >
                    <Link to="/">
                        <div className={classes.logoWrp}>
                            <img src={Logo} alt="Flex" className={classes.logo} />
                        </div>
                    </Link>
                    <Button className={classes.closeButton} onClick={handleClose}>
                        <img src={Close} alt="Close" />
                    </Button>
                </Grid>
                <Input
                    className={classes.searchInput}
                    onChange={(e) => search(e.target.value)}
                    disableUnderline={true}
                    autoFocus={true}
                    placeholder="Search..."
                />
            </div>
            { loading ? (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.messageContainer}
                >
                    <CircularProgress size={60} color="secondary" />
                </Grid>
            ) : message ? (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.messageContainer}
                >
                    <Typography variant="caption" color="secondary">{message}</Typography>
                </Grid>
            ) : (
                <div className={classes.autoComplete}>
                    {
                        Object.entries(searchItems).map(([letter, items]) => <Box key={`searchItems_${letter}`}>
                            <Box  className={classes.letterBox}>
                                <Typography variant="subtitle1" color="secondary">{letter}</Typography>
                            </Box>
                            {
                                items.map(item => (<Box key={item.id} className={classes.searchItemWrap} onClick={() => handleItemClick(item)}>
                                    <SearchItem item={item} />
                                </Box>))
                            }
                        </Box>)
                    }    
                </div>
            )}
        </div>
    )
}

export { Search }
