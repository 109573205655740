import React from 'react'

import { Link } from 'react-router-dom'
import { Typography, Box, Grid } from '@material-ui/core'
import Logo from 'shared/icons/logo.png'

import { useStyles } from './Footer.style'

const footerMenu = [
    {
        title: 'Company',
        links: [
            {
                label: 'About Us',
                url: '/about'
            },
            {
                label: 'Privacy Policy',
                url: '/privacy-policy'
            },
            {
                label: 'Term of use',
                url: '/terms-of-use'
            }
        ]
    },
    {
        title: 'Follow Us',
        links: [
            {
                label: 'Instagram',
                url: ''
            },
            {
                label: 'Facebook',
                url: ''
            },
            {
                label: 'Twitter',
                url: ''
            }
        ]
    }
]

function Footer(): JSX.Element {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            className={classes.container}
        >
            <Grid
                item
                container
                xs={12}
                sm={6}
                direction="row"
                justifyContent="space-between"
                className={classes.footerBox}
            >
                {footerMenu.map((menu) => (
                    <Box key={menu.title}>
                        <Typography variant="h3" className={classes.title}>
                            {menu.title}
                        </Typography>
                        <nav className={classes.navMenu}>
                            {menu.links.map((menuItem) => {
                                return (
                                    <a
                                        key={menuItem.label}
                                        className={classes.link}
                                        href={menuItem.url}
                                    >
                                        <Typography variant="body2" className={classes.linkText}>
                                            {menuItem.label}
                                        </Typography>
                                    </a>
                                )
                            })}
                        </nav>
                    </Box>
                ))}
            </Grid>
            <Grid
                item
                container
                xs={12}
                sm={6}
                direction="row"
                justifyContent="space-between"
                className={classes.footerBox}
            >
                <Box>
                    <Grid container className={classes.footerCopyright}>
                        <Typography
                            variant="body2"
                            className={classes.copyright}
                        >
                            ©Flex 2021
                        </Typography>
                    </Grid>
                </Box>
                <Link to="/">
                    <Box className={classes.logoWrap}>
                        <img
                            src={Logo}
                            alt="Flex"
                            className={classes.logoImg}
                        />
                    </Box>
                </Link>
            </Grid>
        </Grid>
    )
}

export { Footer }
