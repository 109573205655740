import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            width: '100%',
            margin: '0 auto',
            boxSizing: 'border-box',
            background: theme.custom.video.background,
            overflow: 'hidden',
            paddingBottom: 42,
            [theme.breakpoints.down('xs')]: {
                padding: '9px 0'
            }
        },
        playerWrap: {
            position: 'relative',
            overflow: 'hidden',
            '& div': {
                maxHeight: 511,
                [theme.breakpoints.down('xs')]: {
                    maxHeight: 411,
                    marginTop: '-5px',
                    display: 'flex',
                    justifyContent: 'center'
                },
                '&  video': {
                    width: '100% !important',
                    height: '100% !important',
                    [theme.breakpoints.down('xs')]: {
                        width: '1440px !important',
                        height: '730px !important'
                    }
                }
            }
        },
        titleWrap: {
            position: 'absolute',
            top: 43,
            left: 40,
            maxWidth: 670,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 430
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: 330
            }
        },
        titleText: {
            color: theme.custom.video.text,
            fontSize: '100px',
            fontWeight: 700,
            lineHeight: '97px',
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: '54px',
                lineHeight: '57px'
            }
        }
    }
})
export { useStyles }
