export function handleResponse(response: any): any {
    if (response.results) {
        return response.results
    }
    if (response.data) {
        return response.data
    }
    return response
}

export function handleError(error: any): void {
    throw error.response?.data || error
}
