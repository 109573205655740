import { ProductEntity } from 'entities/Product.entity'

type ProductsByMonth = {
    [month: string]: ProductEntity[];
}

export const useCreateDropsList = (productsList: ProductEntity[] | undefined) => {
    if (productsList) {
        return productsList.reduce<ProductsByMonth>((accum, currentValue) => {
            if (new Date(currentValue.dropDate) > new Date()) {
                const month = new Date(currentValue.dropDate).toLocaleString('en-us', { month: 'long'})
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (accum[month]) {
                    accum[month].push(currentValue)
                } else {
                    accum[month] = [currentValue]
                }
                return accum
            }
            return accum
        }, {})
    }
    return {}
}