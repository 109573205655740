import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import followStepCheck  from 'shared/icons/followStep_check.svg'
import followStepLoading  from 'shared/icons/followStep_loading.svg'

import { useStyles } from './FollowSteps.style'

type FollowStepsProps = {
    approveLoading: boolean,
    purchaseLoading: boolean
}

export const FollowSteps = ({approveLoading, purchaseLoading}: FollowStepsProps ): JSX.Element => {
    const classes = useStyles()

    return (
        <>
            <Grid container justifyContent="space-between" className={classes.topBox} wrap="nowrap">
                <div>
                    <Typography variant="h4" className={classes.infoTitle}>APPROVE ASSET</Typography>
                    <Typography variant="subtitle2" className={classes.info_desc}>This transaction is conducted once per asset type</Typography>
                </div>
                <div className={classes.followStepIcons}>
                    <img
                        className={approveLoading ? classes.rotate : ''}
                        src={approveLoading ? followStepLoading : followStepCheck}
                    />
                </div>
            </Grid>
            <Grid container justifyContent="space-between" wrap="nowrap">
                <div>
                    <Typography variant="h4" className={classes.infoTitle}>PURCHASE</Typography>
                    <Typography variant="subtitle2" className={classes.info_desc}>Send transaction to purchase asset</Typography>
                </div>
                <div className={classes.followStepIcons}>
                    <img
                        className={purchaseLoading ? classes.rotate : ''}
                        src={purchaseLoading ? followStepLoading : followStepCheck}
                    />
                </div>
            </Grid>
        </>
    )
}
