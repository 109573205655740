import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        moonpayContainer: {
            width: '100%',
            height: '450px',
            overflow: 'hidden'
        }
    }
})

export { useStyles }
