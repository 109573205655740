import React from 'react'

import { Layout } from 'components/Layout'
import { Grid, Typography } from '@material-ui/core'

import { useStyles } from './NotFound.style'

function NotFound(): JSX.Element {
    const classes = useStyles()
    return (
        <Layout>
            <Grid>
                <Typography variant="h1" className={classes.title}>
                    Page not found!
                </Typography>
            </Grid>
        </Layout>
    )
}

export { NotFound }
