import React, { useState, useEffect }  from 'react'

import { PRODUCT_STATUSES } from 'constants/product'

import { useParams, useLocation, Redirect } from 'react-router-dom'
import { Grid, Typography, Link } from '@material-ui/core'
import { ProductEntity } from 'entities/Product.entity'
import { Model3D } from 'components/Model3D'
import { CountdownTimer } from 'components/CountdownTimer'
import { Status } from 'components/Product/Status'
import { useGetCurrencyRate } from 'hooks/useGetCurrencyRate'
import { getUSD } from 'utils/helpers'
import { apiProfiles } from 'services/api/profiles'
import { ReactComponent as TezosLogo } from 'shared/icons/tezos_logo.svg'
import { getActiveAccount } from 'utils/beacon-wallet'

import { BackgroundVideo } from '../BackgroundVideo'

import { useStyles } from './ProductInfo.style'
import { ProductActions } from './ProductActions'
import { ProductActionsNotify} from './ProductActionsNotify'

interface ProductInfoProps {
    productData: ProductEntity
    qrCodeUrl?: string
    titleTimer?: boolean
}

function ProductInfo({ productData, titleTimer, qrCodeUrl }: ProductInfoProps): JSX.Element {
    const classes = useStyles()
    const dropDate = Date.now() + 2 * 60 * 1000 - (new Date(0).getHours() * 60 * 60 * 1000)
    const productDropDate = new Date(productData.dropDate).toDateString()
    const [isUserOwner, setIsUserOwner] = useState(false)
    const [isUserCreator, setIsUserCreator] = useState(false)
    const [isPageMounted, setIsPageMounted] = useState(false) 

    const { search } = useLocation()
    const queryParams = new URLSearchParams(search)
    const adminSecret = search ? queryParams.get('secret') : null

    const [error, setError] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const [isProductPublished, setIsProductPublished] = useState(true)

    const { id } = useParams<{ id: string }>()
    const arCameraUrl = qrCodeUrl || window.location.origin + '/ar-camera/' + id

    const currentDateTimestamp = new Date().getTime()
    const dropDateTimestamp = new Date(productData.dropDate).getTime()

    const verifyAdminSecret = async (secret: string | null) => {
        if (!secret) return false
        const isSecretValid = await apiProfiles.isAdmin(secret)
        setIsAdmin(isSecretValid === true)
        return isSecretValid === true
    }

    const usd = getUSD(productData.price, useGetCurrencyRate())
    
    useEffect(() => {
        if (isPageMounted) return
        const getAccount = async () => {
            const walletInfo = await getActiveAccount()
            const isAdmin = await verifyAdminSecret(adminSecret)
            const isUserCreator = productData.createdBy === walletInfo.address
            setIsUserOwner(productData.owned > 0)
            setIsUserCreator(isUserCreator)
            if (!!productData.status) setIsProductPublished(isUserCreator || isAdmin || productData.status === PRODUCT_STATUSES.minted)
            setIsPageMounted(true)
        }
        getAccount()
    }, [])

    return (
        <Grid container className={classes.container}>
            { isPageMounted && !isProductPublished && <Redirect to="/" /> }
            <Grid item md={7} xs={12} container className={classes.productModel}>
                {
                    productData.modelUrl && (
                        <>
                            {(isUserOwner || isUserCreator) && <BackgroundVideo />}
                            <Model3D filePath={productData.modelUrl} />
                        </>
                    )
                }
            </Grid>
            <Grid item md={5} xs={12} className={classes.productInfo}>
                <Grid container justifyContent="space-between" alignItems="center" style={{ gap: 10 }}>
                    <Typography variant="caption" className={classes.productTitle}>
                        {productData.title}
                    </Typography>
                    {titleTimer ? 
                        <CountdownTimer classTimerText={classes.productTitleTimer} date={dropDate} /> : 
                        <Typography variant="h3" noWrap>
                            {productData.price ?
                                <>{productData.price}<TezosLogo className="tezos-logo" />
                                    {usd && (
                                        <Typography variant="body1" display="inline" className={classes.productPriceDollar}>
                                            {` ~ $${usd}`}
                                        </Typography>
                                    )}
                                </> : '-'}
                        </Typography>}
                </Grid>
                <Grid container justifyContent="space-between" direction="column" className={classes.productData}>
                    <Typography variant="h5" className={classes.productInfoLabel}>
                        DESIGNER
                    </Typography>
                    <Typography variant="subtitle1" className={classes.productInfoText}>
                        <Link href={`/profile/${productData.createdBy}?tab=created`}>
                            {productData.designerName || '-'} { isUserCreator && ' (you)'}
                        </Link>
                    </Typography>
                    <Typography variant="h5" className={classes.productInfoLabel}>
                        RELEASED ON
                    </Typography>
                    <Typography variant="subtitle1" className={classes.productInfoText}>
                        {productDropDate || '-'}
                    </Typography>
                    <Typography variant="h5" className={classes.productInfoLabel}>
                        DESCRIPTION
                    </Typography>
                    <Typography variant="subtitle1" className={classes.productInfoText}>
                        {productData.description || '-'}
                    </Typography>
                    <Typography variant="h5" className={classes.productInfoLabel}>
                        AVAILABILITY
                    </Typography>
                    <Typography variant="subtitle1">
                        {productData.count ? `${productData.onSale}/${productData.count}` : '-'}
                    </Typography>
                </Grid>
                {
                    (isUserCreator || isAdmin) &&  productData.status !== PRODUCT_STATUSES.minted ? 
                        <Grid container className={classes.mintContainer} style={{ gap: 10 }}>
                            {productData.status &&
                                <Status
                                    productStatus={productData.status}
                                    mintingStatus={productData.mintingStatus}
                                />
                            }
                            {error && <Typography variant="subtitle1">{error}</Typography>}
                        </Grid> : currentDateTimestamp > dropDateTimestamp 
                            ? <ProductActions productData={productData} qrCodeUrl={arCameraUrl} />
                            : productData.dropDate && ( <ProductActionsNotify dropDate={new Date(productData.dropDate)}/> )
                }
            </Grid>
        </Grid>
    )
}

export { ProductInfo }
