import React from 'react'

import MUIButton, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core'

interface CustomProps extends ButtonProps {
    loading: boolean
    spinnerSize?: number
}

const spinnerStyles = {
    root: {
        marginLeft: 5
    }
}
const SpinnerAdornment = withStyles(spinnerStyles)((props: any) => (
    <CircularProgress className={props.classes.spinner} size={props.size} />
))
function AdornedButton(props: CustomProps): JSX.Element {
    const { children, loading, spinnerSize = 20, ...rest } = props
    return (
        <MUIButton {...rest}>
            {children}&nbsp;
            {loading && <SpinnerAdornment size={spinnerSize} />}
        </MUIButton>
    )
}

export { AdornedButton }
