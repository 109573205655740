import React, { useRef, useEffect, useContext } from 'react'

import { useParams, NavLink } from 'react-router-dom'
import { Grid, Typography, Button, useTheme, useMediaQuery } from '@material-ui/core'
import { ProductActions as ProductContextActions, ProductContext } from 'context/ProductPageContext'
import { generateQRCode } from 'utils/qr-code'
import { useConnectedAccount } from 'hooks/useConnectedAccount'
import { ProductEntity } from 'entities/Product.entity'
import vector from 'shared/icons/vector.png'

import { useStyles } from './ProductInfo.style'


//probably it will be removed when approval procces will be fully implemented
type ProductActionsProps = {
    productData: ProductEntity
    qrCodeUrl: string
}

function ProductActions({ productData, qrCodeUrl }: ProductActionsProps): JSX.Element {
    const classes = useStyles()
    const { dispatch } = useContext(ProductContext)
    const { id } = useParams<{ id: string }>()
    const canvasQRCode = useRef<HTMLCanvasElement>(null)
    const theme = useTheme()
    const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'))
    const matchMdUp = useMediaQuery(theme.breakpoints.up('md'))

    // Now disabled buttons depends on account state (connected/disconnected) In future user should have "owner" property
    const walletData = useConnectedAccount()
    const isUserOwner = productData.owned > 0
    const isUserCreator = productData.createdBy === walletData?.address 

    const onPurchaseModalOpen = () => {
        dispatch({ type: ProductContextActions.PURCHASE_MODAL_STATE, payload: true })
    }

    const onSellModalOpen = () => {
        dispatch({ type: ProductContextActions.SELL_MODAL_STATE, payload: true })
    }


    useEffect(() => {
        generateQRCode(
            canvasQRCode.current,
            qrCodeUrl,
            {
                margin: 2,
                width: 80,
                color: {
                    light: '#fff',
                    dark: '#000'
                }
            }
        )
    })

    return (
        <React.Fragment>
            {

                matchMdUp && (
                    <Grid item className={classes.qrCodeWrap}>
                        <canvas ref={canvasQRCode}/>
                        <p className={classes.qrCodeLabel}>
                        SCAN TO TRY-ON
                        </p>
                    </Grid>
                )
            }
            {
                matchMdUp && (
                    <>
                        <Grid container className={classes.actionsContainer}>
                            <Grid container wrap="nowrap">
                                <Button 
                                    className={classes.actionBtn}
                                    variant="outlined"
                                    color="primary"
                                    disabled={!productData.onSale}
                                    onClick={onPurchaseModalOpen}>
                                    {productData.onSale ? 'Buy' : 'Sold Out'}
                                </Button>
                                <Button 
                                    className={classes.actionBtn}
                                    variant="outlined"
                                    color="primary"
                                    disabled={!walletData || !isUserOwner}
                                    onClick={onSellModalOpen}>
                                    Sell
                                </Button>
                            </Grid>
                            <Button
                                className={classes.dressUpBtn}
                                component={NavLink}
                                to={{
                                    pathname: '/dressing'
                                }}
                                variant="contained" 
                                color="primary" 
                                disabled={!walletData || (!isUserOwner && !isUserCreator)}>
                                Dress Up
                            </Button>
                        </Grid>                
                    </>
                )
            }
            {
                matchSmDown && (
                    <Grid container className={classes.actionsContainer}>
                        <Button variant="outlined" color="primary"
                            className={classes.mobileBtn}
                            component={NavLink}
                            to={{
                                pathname: '/ar-camera/' + id
                            }}>
                            <img src={vector} />
                            <span className={classes.tryOnText}>Virtual Try-On</span>
                        </Button>
                        <Button 
                            component={NavLink}
                            disabled={!walletData || (!isUserOwner && !isUserCreator)}
                            to={{
                                pathname: '/dressing'
                            }}
                            variant="contained" 
                            color="primary"
                            className={classes.mobileBtn}
                        >
                                Dress up
                        </Button>
                    </Grid>
                )
            }
            {
                matchSmDown && (
                    <Typography variant="h4" color="textPrimary" align="center" className={classes.actionsWarn}>Use the desktop version to purchase</Typography>
                )
            }
        </React.Fragment>
    )
}

export { ProductActions }
