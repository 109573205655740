import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        productPreviewModal: {
            width: '380px',
            height: '450px',
            margin: '25px 0 0 0',
            padding: '0px 10px 32px',
            boxSizing: 'border-box',
            border: `1px solid ${theme.custom.buyModal.productPreviewBorder}`,
            borderRadius: 0
        },
        transcationAction: {
            width: '275px',
            paddingLeft: '54px',
            boxSizing: 'border-box',
            '& p': {
                '& span': {
                    float: 'right',
                    paddingRight: '10px'
                }
            }
        },
        pricingHelper: {
            width: 'auto',
            fontSize: '12px',
            color: theme.custom.sellModal.pricingHelperColor
        },
        subSpan: {
            float: 'right'
        },
        setPriceLable: {
            marginTop: 35
        },
        setPrice: {
            marginTop: '10px',
            padding: '0 15px',
            border: `1px solid ${theme.custom.sellModal.setPriceBorder}`
        },
        price: {
            textAlign: 'right',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: 400
        },
        tz: {
            marginTop: 5,
            marginLeft: 0,
            '& p': {
                fontSize: '30px',
                lineHeight: '30px',
                color: theme.custom.sellModal.tzColor
            }
        },
        quantityContainer: {
            marginTop: 20
        },
        quantity: {
            height: 25,
            '& *': {
                fontSize: '14px'
            },
            '& button': {
                width: 22
            }
        },
        line: {
            display: 'block',
            width: '100%',
            height: '1px',
            marginTop: 15,
            background: theme.palette.primary.main
        },
        mpFee: {
            marginTop: 15
        },
        crFee: {
            marginTop: 10
        },
        youGet: {
            marginTop: 20
        },
        sellButton: {
            float: 'right',
            width: '200px',
            height: '40px',
            marginTop: 25
        },
        orderTitle: {
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '40px',
            marginTop: '20px'
        },
        orderTada: {
            height: '70px',
            width: '70px'
        },
        errorMsg: {
            wordBreak: 'keep-all',
            maxWidth: '100%',
            marginTop: 30,
            color: theme.custom.common.red
        }
    }
})

export { useStyles }
