import * as yup from 'yup'

export const uploadingDataSchema = yup.object({
    product3DModel: yup.mixed().required('The product model is required'),
    productImage: yup.mixed().required('This product image is required'),
    productName: yup.string().max(20, 'The description must be less 20 char').required('This field is required'),
    description: yup.string().max(350, 'The description must be less 350 char').required('This field is required'),
    dropDate: yup
        .date()
        .min(new Date(new Date().getDate() + 7))
        .required('This field is required'),
    numberOfModels: yup
        .number()
        .required('This field is required')
        .moreThan(0, 'The number of models cannot be 0')
        .lessThan(1001, 'The number of models cannot be more that 1000')
        .positive('The number of models cannot be negative'),
    price: yup
        .number()
        .required('This field is required')
        .moreThan(0, 'The price cannot be 0')
        .positive('The price cannot be negative')
})