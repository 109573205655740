import { useState, useEffect, useMemo } from 'react'

import { apiCoinbase } from 'services/api/coinbase'

export const useGetCurrencyRate = (): number | undefined => {
    const [currencyRate, setCurrencyRate] = useState<number|undefined>()
    useEffect(() => {
        async function getCurrencyRate() {
            apiCoinbase.getCurrencies().then((currencies) => {
                setCurrencyRate(parseFloat(currencies.data.rates.USD))
            })
        }
        getCurrencyRate()
    }, [])

    return useMemo(() => (currencyRate), [currencyRate])
}