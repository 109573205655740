import JSZip from 'jszip'

const ARFiles = {
    required: [
        'model_l.glb',
        'ankle_occ_l.glb',
        'leg_occ_l.glb',
        'plane_occ_l.glb',
        'offsets.json',
        'envMap.jpg',
        'shoeIcon.png'
    ],
    notRequired: [
        'model_r.glb',
        'ankle_occ_r.glb',
        'leg_occ_r.glb',
        'plane_occ_r.glb'
    ]
}

export const get3DModel = async (file: File | '') => {
    if (file === '') return
    const new_zip = new JSZip()

    const zip = await new_zip.loadAsync(file)
    let model_r = ''
    let model_l = ''
    Object.keys(zip.files).forEach(function (filename) {
        if (filename === 'model_r.glb') {
            model_r = filename
        } else if (filename === 'model_l.glb') {
            model_l = filename
        }
    })

    if (model_r) {
        const rightModel = zip.files[model_r].async('blob')
        // setArModel(await rightModel)
        return rightModel
    } else if (model_l) {
        const leftModel = zip.files[model_l].async('blob')
        // setArModel(await leftModel)
        return leftModel
    }
}

export const checkZipARPackage = async (file: File) => {
    const new_zip = new JSZip()

    const zip = await new_zip.loadAsync(file)
    const ARFilesClone = JSON.parse(JSON.stringify(ARFiles))

    Object.keys(zip.files).forEach(function (filename) {
        let parsedFileName = filename
        if (['model_l.glb', 'model_r.glb'].includes(filename)) {
            parsedFileName = 'model_l.glb'
        }
        if (['ankle_occ_l.glb', 'ankle_occ_r.glb'].includes(filename)) {
            parsedFileName = 'ankle_occ_l.glb'
        }
        if (['leg_occ_l.glb', 'leg_occ_r.glb'].includes(filename)) {
            parsedFileName = 'leg_occ_l.glb'
        }
        if (['plane_occ_l.glb', 'plane_occ_r.glb'].includes(filename)) {
            parsedFileName = 'plane_occ_l.glb'
        }
        const indexR = ARFilesClone.required.indexOf(parsedFileName)
        if (indexR !== -1) ARFilesClone.required.splice(indexR, 1)

        const indexN = ARFilesClone.notRequired.indexOf(filename)
        if (indexN !== -1) ARFilesClone.notRequired.splice(indexN, 1)
    })

    return ARFilesClone.required.length === 0
        ? { status: 1, files: [] } :
        {
            status: 0,
            files: ARFilesClone.required.concat(ARFilesClone.notRequired)
        }
         
}