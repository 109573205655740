import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        model3D: {
            width: '100%',
            height: '100%',
            cursor: 'move'
        },
        modelViewer: {
            '&:host': {
                width: '100%',
                height: '100%'
            }
        }
    }
})
export { useStyles }
