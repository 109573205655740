import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
    return {
        arCamera: {
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        },
        header: {
            position: 'absolute',
            paddingTop: 25,
            zIndex: 10
        },
        backBtn: {
            left: 11,
            top: 25,
            position: 'absolute',
            border: 'none',
            backgroundColor: 'transparent'
        },
        backIcon: {
            color: '#fff'
        },
        itemName: {
            fontSize: 24,
            color: '#fff'
        },
        feature: {
            width: '100%',
            height: '100%'
        },
        camera: {
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            transition: '0.5s',
            '& .vyking-sneaker-window': {
                width: '100%',
                height: '100%'
            }
        },
        picture: {
            width: '100%',
            height: '100%',
            '& .vyking-sneaker-window': {
                width: '100%',
                height: '100%'
            }
        },
        takePictureBtn: {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: 25,
            width: 56,
            height: 51,
            border: 'solid 1px #fff',
            backgroundColor: 'transparent',
            borderRadius: 31,
            zIndex: 5,
            '&:hover': {
                backgroundColor: '#fff'
            }
        },
        loaderWrp: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10
        }
    }
})
export { useStyles }
