import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: '95px 0px 180px',
            backgroundColor: theme.palette.secondary.main,
            [theme.breakpoints.down('xs')]: {
                padding: '45px 0px 55px'
            }
        },
        loadingErrorContainer: {
            maxWidth: '1440px',
            margin: '100px auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
})
export { useStyles }
