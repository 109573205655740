import React, { useState } from 'react'

import { Search } from './Search'
import { Header } from './Header'
import { Footer } from './Footer'
import { useStyles } from './Layout.style'

type LayoutProps = {
    children: JSX.Element
    viewFooter?: boolean
    viewHeader?: boolean
}

function Layout({ children, viewFooter = true, viewHeader = true }: LayoutProps): JSX.Element {
    const classes = useStyles()

    const [searchPopup, setSearchPopup] = useState(false)

    const openSearchPopup = () => {
        setSearchPopup(true)
    }

    const closeSearchPopup = () => {
        setSearchPopup(false)
    }

    return (
        <div
            className={
                searchPopup
                    ? classes.container + ' ' + classes.boxHidden
                    : classes.container
            }
        >
            {searchPopup && (
                <div className={classes.search}>
                    <Search closeSearchPopup={closeSearchPopup} />
                </div>
            )}
            {viewHeader && (
                <div className={classes.header}>
                    <Header openSearchPopup={openSearchPopup} />
                </div>
            )}
            <div className={classes.content}>{children}</div>
            {viewFooter && (
                <footer className={classes.footer}>
                    <Footer />
                </footer>
            )}
        </div>
    )
}

export { Layout }
