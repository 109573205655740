import React from 'react'

import { Grid, Box, Typography } from '@material-ui/core'
import { Avatar } from 'components/Avatar'
import type { SearchData } from 'store/search'

import { useStyles } from './SearchItem.style'

type SearchItemProps = {
    item: SearchData
}

function SearchItem({ item }: SearchItemProps): JSX.Element {
    const classes = useStyles()

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            className={classes.itemContainer}
        >
            <Avatar className={classes.itemAvatar} alt={item.type} src={item.img} creator={item.isCreator} />
            <Box className={classes.itemBox}>
                <Typography variant="h2" color="secondary">{item.name}</Typography>
                {item.info && <Typography variant="subtitle1" color="secondary">{item.info}</Typography>}
            </Box>
        </Grid>
    )
}

export { SearchItem }
