import QRCode, { QRCodeRenderersOptions } from 'qrcode'

export function generateQRCode(
    canvas: HTMLCanvasElement | null,
    text: string,
    options: QRCodeRenderersOptions
): void {
    if (!canvas) return
    QRCode.toCanvas(canvas, text, options)
}
