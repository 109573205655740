import React from 'react'

import { Input, InputLabel, FormControl, FormHelperText, InputProps, Grid } from '@material-ui/core'
import { FormikErrors } from 'formik'

import { useStyles } from './FlexTextField.style'

interface FlexTextFieldProps extends InputProps {
    label: string
    subLabel?: string
    errorLabel?: string
    inputHelperText?: string
    isHelperTextInTheEnd?: boolean
    errorMessage?: string | FormikErrors<Date>
}

export const FlexTextField = React.forwardRef(function FlexTextField({
    label,
    subLabel,
    errorLabel,
    inputHelperText,
    isHelperTextInTheEnd,
    className,
    error,
    errorMessage,
    onClick,
    ...props
}: FlexTextFieldProps, ref) {
    const classes = useStyles()
    return (
        <FormControl className={className}>
            <InputLabel shrink={true} className={classes.label}>{label}</InputLabel>
            {subLabel && <InputLabel shrink={true} className={classes.subLabel}>{subLabel}</InputLabel>}
            <Input {...props} inputRef={ref} onClick={onClick} placeholder="Type here" />
            <Grid container direction="row" justifyContent={error ? 'space-between' : isHelperTextInTheEnd ? 'flex-end' : 'flex-start'}>
                {error && (
                    <Grid item>
                        <FormHelperText className={classes.errorText} error>{errorMessage}</FormHelperText>
                    </Grid>
                )}
                {inputHelperText && (
                    <Grid item>
                        <FormHelperText className={isHelperTextInTheEnd ? classes.inputHelperTextInTheEnd : classes.inputHelperText}>{inputHelperText}</FormHelperText>
                    </Grid>
                )}
            </Grid>
        </FormControl>
    )
})