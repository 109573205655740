import React, { useEffect, useRef } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { generateQRCode } from 'utils/qr-code'
import { ProductEntity } from 'entities/Product.entity'
import { useGetCurrencyRate } from 'hooks/useGetCurrencyRate'
import { getUSD } from 'utils/helpers'
import clsx from 'clsx'
import { ReactComponent as TezosLogo } from 'shared/icons/tezos_logo.svg'

import { useStyles } from './ProductPreviewInfo.style'

type ProductPreviewInfoProps = {
    className?: string,
    qrCodeUrl?: string,
    product: ProductEntity
    hidePrice?: boolean
}

function ProductPreviewInfo({className, qrCodeUrl, product, hidePrice = false }: ProductPreviewInfoProps): JSX.Element {
    const classes = useStyles()

    const canvasQRCode = useRef<HTMLCanvasElement>(null)
    const arCameraUrl = qrCodeUrl || window.location.origin + '/ar-camera/' + product.id

    const usd = getUSD(product.price, useGetCurrencyRate())

    useEffect(() => {
        generateQRCode(
            canvasQRCode.current,
            arCameraUrl,
            {
                margin: 6,
                width: 81,
                color: {
                    light: '#fff',
                    dark: '#000'
                }
            }
        )
    }, [arCameraUrl])

    return (
        <div className={clsx(classes.prodInfo, className)}>
            <div className={classes.prodTitle}>
                <Typography variant="h3">{product.title}</Typography>
            </div>
            <Grid container justifyContent="space-between">
                <div className={classes.qr}>
                    <canvas ref={canvasQRCode}/>
                </div>
                <Grid container
                    direction="column"
                    justifyContent="space-between"
                    className={classes.data}
                >
                    <div className={classes.designer}>
                        <Typography component="span">DESIGNER:</Typography>
                        <Typography component="h4">{product.designerName}</Typography>
                    </div>
                    <Grid className={classes.bottomBox} container justifyContent="space-between">
                        <div>
                            <Typography component="span">ITEMS:</Typography>
                            <Typography component="h4">{product.count}</Typography>
                        </div>
                        {!hidePrice && <div className={classes.price}>
                            <Typography component="span">PRICE:</Typography>
                            <Typography component="h4">
                                {product.price}<TezosLogo className="tezos-logo--small" /> {usd && (<b>~ ${usd}</b>)}
                            </Typography>
                        </div>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
export { ProductPreviewInfo }
