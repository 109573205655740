const typography = {
    fontFamily: 'Arial Narrow',
    h1: {
        fontSize: 48,
        fontWeight: 400
    },
    h2: {
        fontSize: 48,
        fontWeight: 400,
        lineHeight: '54px',
        '@media (max-width:600px)': {
            fontSize: 30,
            lineHeight: '33px'
        }
    },
    h3: {
        fontSize: 21,
        fontWeight: 700
    },
    h4: {
        fontSize: 16,
        fontWeight: 700
    },
    h5: {
        fontSize: 14,
        fontWeight: 700
    },
    h6: {
        fontSize: 12,
        fontWeight: 700
    },
    caption: {
        fontSize: 35,
        fontWeight: 700
    },
    subtitle1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '18px'
    },
    subtitle2: {
        fontSize: 14,
        fontWeight: 400
    },
    body1: {
        fontSize: 18,
        fontWeight: 400,
        '@media (max-width:600px)': {
            fontSize: 14
        }
    },
    body2: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '22px',
        '@media (max-width:600px)': {
            fontSize: 16
        }
    }
}

const palette = {
    primary: {
        main: '#000000'
    },
    secondary: {
        main: '#FFFFFF'
    }
}

const common = {
    white: '#FFFFFF',
    whiteSmoke: '#E5E5E5',
    black: '#000000',
    gray: '#E5E5E5',
    lightGray: '#C4C4C4',
    primaryGray: '#CCCCCC',
    secondaryGray: '#797979',
    orange: '#FF6800',
    green: '#69F287',
    red: '#F26969',
    liteGray: '#F0EEEA',
    dark: '#222',
    mediumGray: '#888'
}

const custom = {
    common,
    status: {
        pending: '#FFA800BA',
        rejected: '#FF6645',
        approved: '#D6D3CE'
    },
    alert: {
        border: common.liteGray
    },
    layout: {
        background: common.white,
        header: common.white
    },
    table: {
        stickyHeader: common.white,
        rowBorder: common.liteGray
    },
    video: {
        text: common.white,
        background: common.white
    },
    pricingHelperModal: {
        title: common.orange,
        tableHeaderLabel: '#D6D3CE'
    },
    purchaseModal: {
        tableHeaderLabel: '#D6D3CE'
    },
    buyModal: {
        closeIconColor: common.black,
        productPreviewBorder: '#DFDFDF',
        balanceColor: common.orange,
        buyButtonBorder: common.black,
        buyButtonBackground: common.white
    },
    sellModal: {
        closeIconColor: common.black,
        productPreviewBorder: '#DFDFDF',
        pricingHelperColor: common.orange,
        setPriceBorder: common.liteGray,
        tzColor: common.black,
        sellButtonBorder: common.black,
        sellButtonBackground: common.white
    },
    quantityCounter: {
        background: common.liteGray,
        text: common.orange
    },
    emailSubscriptions: {
        inputLabelText: 'rgba(0,0,0, 0.5)',
        buttonBackground: common.orange,
        background: common.white,
        successMessageColor: common.mediumGray
    },
    aboutFlex: {
        text: common.black,
        background: common.liteGray
    },
    dressing: {
        blockBorder: common.liteGray,
        sneakerImgBoxShadow: 'rgba(0,0,0,0.12)',
        trackColor: common.dark
    },
    dropzone: {
        secondaryText: common.primaryGray,
        btnBorder: common.whiteSmoke,
        iconBackground: common.white,
        iconHover: common.orange
    },
    form: {
        subtitleColor: '#D0D0D0',
        inputSubLabelColor: '#D6D3CE',
        benefitInfoColor: common.orange,
        infoTextColor: '#CFCDC7',
        activeInputColor: common.orange,
        dropzoneButtonBorder: '#D6D3CE'
    },
    productPage: {
        background: common.white,
        modelBackground: common.liteGray,
        infoBorder: common.liteGray,
        infoBackground: common.white,
        priceText: '#D4D4D4',
        labelText: common.orange,
        buttonBorderColor: common.white,
        fotoSectionBackground: common.black,
        counterColor: common.liteGray,
        socialsBackground: common.liteGray,
        socialsActiveBackground: common.orange,
        wardrobeBackground: '#202020',
        indicatorsColor: '#AAA',
        indicatorsActiveColor: common.white,
        editButtonLabel: common.orange,
        dropsIn: common.orange,
        notifyButtonBorderColor: common.orange,
        notifyButtonBackgroundHover: common.white,
        notifyButtonColorHover: common.black
    },
    creatorPreview: {
        background: common.white,
        border: '#D3D3D3',
        creatorUserName: common.orange,
        profileImageBorderColor: common.white
    },
    profilePage: {
        profileImageBorder: common.white,
        actionButtonsBorderColor: '#CFCDC7',
        actionButtonsBackgroundColor: common.white,
        userNameColor: common.orange,
        bioColor: '#9B9B9B',
        tabBorderBottom: common.black,
        lineBackgroundColor: '#DDD'
    },
    flexTextField: {
        subLabelColor: '#CFCDC7',
        inputTextHelperColor: '#CFCDC7'
    }
}

const overrides = {
    MuiTypography: {
        root: {
            color: common.black
        },
        colorTextPrimary: {
            color: common.orange
        },
        colorTextSecondary: {
            color: common.primaryGray
        }
    },
    MuiFormLabel: {
        root: {
            color: common.white,
            fontSize: 16
        }
    },
    MuiFilledInput: {
        root: {
            fontSize: 16,
            color: common.white,
            borderRadius: 0,
            border: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        },
        input: {
            padding: '20px 15px 16px'
        }
    },
    MuiInputBase: {
        root: {
            border: 'none'
        }
    },
    MuiButton: {
        root: {
            width: '100%'
        },
        contained: {
            ...typography.subtitle1,
            color: common.white,
            boxShadow: 'none',
            border: 'solid 1px',
            borderRadius: 0,
            backgroundColor: common.black,
            padding: '12px 48px',
            '&:hover': {
                color: common.white,
                backgroundColor: common.black
            }
        },
        outlined: {
            ...typography.subtitle1,
            color: common.orange,
            boxShadow: 'none',
            border: 'solid 1px',
            borderRadius: 0,
            borderColor: common.orange,
            backgroundColor: common.white,
            padding: '12px 48px',
            '&:hover': {
                color: common.white,
                backgroundColor: common.orange,
                boxShadow: 'none'
            }
        },
        outlinedPrimary: {
            ...typography.h5,
            color: common.black,
            borderColor: common.black,
            '&:hover': {
                color: common.white,
                backgroundColor: common.black
            }
        },
        outlinedSecondary: {
            ...typography.h5,
            color: common.orange,
            border: '1px solid #D6D3CE',
            '&:hover': {
                color: common.orange,
                backgroundColor: common.white,
                border: '1px solid'
            }
        }
    },
    MuiTableCell: {
        root: {
            borderBottom: '1px solid #F0EEEA',
            border: '1px solid #F0EEEA',
            padding: '8px 16px'
        },
        head: {
            border: 'none'
        },
        body: {
            borderLeft: 'none',
            borderRight: 'none'
        }
    },
    MuiBackdrop: {
        root: {
            backdropFilter: 'blur(6px)'
        }
    },
    MuiPickersModal: {
        dialogRoot: {
            '& .MuiDialogActions-root': {
                '& .MuiButtonBase-root': {
                    color: common.black
                }
            }
        }
    }
}

export { typography, palette, custom, overrides }
