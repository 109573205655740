import React from 'react'

import { Grid, Input, InputProps, FormHelperText } from '@material-ui/core'

import { useStyles } from './SocialNetworkInput.style'

interface SocialNetworkInputProps extends InputProps {
    socialNetworkIcon: JSX.Element
    errorMessage?: string
}

export const SocialNetworkInput = ({
    socialNetworkIcon,
    errorMessage,
    ...props
}: SocialNetworkInputProps) => {
    const classes = useStyles()
    return (
        <Grid container>
            {<div className={classes.iconStyle}>{socialNetworkIcon}</div>}
            <Input {...props}/>
            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Grid>
    )
}