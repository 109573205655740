import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            position: 'relative',
            width: '423px',
            height: '322px',
            backgroundColor: theme.custom.creatorPreview.background,
            border: `1px solid ${theme.custom.creatorPreview.border}`,
            boxSizing: 'border-box',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                height: '300px'
            }
        },
        creatorCover: {
            width: '100%',
            height: '153px',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
        coverImage: {
            width: '100%',
            height: '161px',
            '& img': {
                width: '100%',
                height: '100%'
            },
            [theme.breakpoints.down('xs')]: {
                height: '150px'
            }
        },
        profileImage: {
            position: 'absolute',
            width: '93px',
            height: '93px',
            top: '110px',
            borderRadius: '50px',
            boxSizing: 'border-box',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            border: `4px solid ${theme.custom.creatorPreview.profileImageBorderColor}`
        },
        creatorName: {
            paddingTop: '30px',
            '& h3': {
                fontSize:'30px',
                lineHeight: '34px',
                textTransform: 'uppercase'
            },
            '& h6': {
                paddingTop: '5px',
                color: theme.custom.creatorPreview.creatorUserName
            }
        }
    }
})
export { useStyles }
