import axios from 'axios'

import { ApiCore } from '../utilities'
import { handleResponse, handleError } from '../utilities/response'

const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://127.0.0.1:1337/'

const url = 'tokens'
const plural = 'tokens'
const single = 'token'

class ApiTokens extends ApiCore {
    mint: any
}

const apiTokens = new ApiTokens({
    allowGetAll: true,
    allowGetSingle: true,
    allowPost: true,
    allowPut: true,
    allowPatch: true,
    allowRemove: true,
    url: url,
    plural: plural,
    single: single
})

apiTokens.mint = (token: string | null, id: string) => {
    return axios
        .post(`${BASE_URL}/${url}/mint/${id}`, null, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(handleResponse)
        .catch(handleError)
}

export { apiTokens }
