import React from 'react'

import { styled } from '@material-ui/core'

const VideoPlayer = styled('video')({
    position: 'absolute', 
    overflow: 'hidden',
    width: '100%', 
    height: '100%', 
    objectFit: 'cover'
})

export const BackgroundVideo = () => {
    return (
        <VideoPlayer autoPlay loop muted>
            <source type="video/mp4" src="/productOwnerBackground.mp4"/>
        </VideoPlayer>
    )
}