import React from 'react'

import ReactDOM from 'react-dom'

import './index.css'
import { App } from './App'

function FlexNft() {
    return <App />
}

ReactDOM.render(<FlexNft />, document.getElementById('root'))
