import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import { useStyles } from './NotAvailableEdition.style'


export const NotAvailableEdition = (): JSX.Element => {
    const classes = useStyles()

    return (
        <>
            <Grid container justifyContent="space-between" className={classes.topBox}>
                <div>
                    <Typography variant="h4" className={classes.infoTitle}>OOPS!</Typography>
                    <Typography className={classes.info_desc}>
                        It seems like this edition is not available anymore. You can select another edition of the
                        same sneaker if available. Sorry about that!
                    </Typography>
                </div>
            </Grid>
        </>
    )
}
