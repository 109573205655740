import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: theme.custom.layout.background
        },
        boxHidden: {
            overflow: 'hidden'
        },
        search: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            zIndex: 10000,
            background: 'rgba(0,0,0,0.5)',
            backdropFilter: 'blur(18px)'
        },
        header: {
            position: 'sticky',
            top: 0,
            backgroundColor: theme.custom.layout.header,
            zIndex: 999
        },
        content: {
            flex: '1 0 auto',
            backgroundColor: theme.palette.secondary.main
        },
        footer: {
            position: 'relative',
            marginTop: 0,
            zIndex: 30,
            background: 'black',
            width: '100%',
            flex: '0 0 auto'
        }
    }
})
export { useStyles }
