import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        tab: {
            fontSize: 16,
            '&.Mui-selected': {
                fontWeight: 700
            }
        },
        tableTitle: {
            color: theme.custom.pricingHelperModal.title
        },
        tableCellHeader: {
            color: theme.custom.pricingHelperModal.tableHeaderLabel
        },
        tableDataCell: {
            textTransform: 'uppercase'
        },
        backButton: {
            width: '200px',
            height: '40px',
            marginTop: 30
        },
        loadingContainer: {
            margin: '170px auto'
        },
        noItems: {
            width: '100%',
            padding: '170px 0',
            fontSize: 24,
            color: theme.custom.common.orange,
            textAlign: 'center'
        }
    }
})

export { useStyles }
