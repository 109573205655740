import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
    return {
        container: {
        },
        time: {
          
        }
    }
})
export { useStyles }
