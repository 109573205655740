import { AccountInfo } from '@airgap/beacon-sdk'
import { LoginInfo  } from 'kukai-embed'

export enum SignInProvider {
    WEB_KUKAI = 'web-kukai',
    EXTENSION = 'extension'
}

export type AccountModelType = LoginInfo | AccountInfo | null

export interface AccountEntity {
    address: string
    publicKey: string
    verifierOrigin: SignInProvider
    wallet: AccountModelType
}

export class AccountEntity implements AccountEntity {
    constructor(wallet?: AccountModelType) {
        if (!wallet) return
        if ((wallet as LoginInfo).userData) {
            const { pkh, pk } = wallet as LoginInfo
            this.address = pkh
            this.publicKey = pk
            this.verifierOrigin = SignInProvider.WEB_KUKAI
        } else {
            const { address, publicKey } = wallet as AccountInfo
            this.address = address
            this.publicKey = publicKey
            this.verifierOrigin = SignInProvider.EXTENSION
        }
        this.wallet = wallet
    }
}
