import axios from 'axios'

import { ApiCore } from '../utilities'
import { handleResponse, handleError } from '../utilities/response'

const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://127.0.0.1:1337/'

const url = 'profiles'
const plural = 'profiles'
const single = 'profile'

class ApiProfiles extends ApiCore {
    getMe: any
    updateMe: any
    beaconAuth: any
    isAdmin: any
}

const apiProfiles = new ApiProfiles({
    allowGetAll: true,
    allowGetSingle: true,
    allowPost: true,
    allowPut: true,
    allowPatch: true,
    allowRemove: true,
    url: url,
    plural: plural,
    single: single
})

apiProfiles.getMe = ({
    token
}: {
    token: string
}) => {
    return axios
        .get(`${BASE_URL}/${url}/me`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(handleResponse)
        .catch(handleError)
}

apiProfiles.updateMe = ({
    token,
    data
}: {
    token: string
    data: any
}) => {
    return axios
        .put(`${BASE_URL}/${url}/me`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(handleResponse)
        .catch(handleError)
}

apiProfiles.beaconAuth = (data: any) => {
    return axios
        .post(`${BASE_URL}/${url}/beacon-auth`, data)
        .then(handleResponse)
        .catch(handleError)
}

apiProfiles.isAdmin = (secret: string) => {
    return axios
        .get(`${BASE_URL}/${url}/is_admin/${secret}`)
        .then(handleResponse)
        .catch(handleError)
}

export { apiProfiles }
