// import axios from 'axios'

import { ApiCore } from '../utilities'
//  import { handleResponse, handleError } from '../utilities/response'

const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://127.0.0.1:1337/'

const url = 'users'
const plural = 'users'
const single = 'user'

// class ApiUsers extends ApiCore {
//     getMe: any
//     updateMe: any
//     beaconAuth: any
// }

const apiUsers = new ApiCore({
    allowGetAll: true,
    allowGetSingle: true,
    allowPost: true,
    allowPut: true,
    allowPatch: true,
    allowRemove: true,
    url: url,
    plural: plural,
    single: single
})

// apiProfiles.beaconAuth = (data: any) => {
//     return axios
//         .post(`${BASE_URL}/${url}/beacon-auth`, data)
//         .then(handleResponse)
//         .catch(handleError)
// }

export { apiUsers }
