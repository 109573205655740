import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        productPreviewContainer: {
            position: 'relative',
            maxWidth: '420px'
        },
        productPreview: {
            position: 'relative',
            maxWidth: '420px',
            height: 'auto',
            padding: '36px',
            boxSizing: 'border-box',
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                padding: '22px'
            },
            transition: 'all .3s ease'
        },
        pimg: {
            height: '240px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            [theme.breakpoints.down('xs')]: {
                height: 'auto'
            },
            '& img': {
                height: 'auto',
                width: '75%',
                [theme.breakpoints.down('xs')]: {
                    width: '85%'
                }
            }
        },
        mobSection: {
            display: 'none',
            width: '265px',
            [theme.breakpoints.down('xs')]: {
                display: 'block'
            }
        },
        tryon: {
            width: '100%',
            padding: '12px',
            border: '1px solid rgba(213, 213, 213, 0.5)',
            borderRadius: '10px',
            boxSizing: 'border-box',
            textTransform: 'none',
            color: theme.palette.primary.main,
            textDecoration: 'none',
            textAlign: 'center',
            '& img': {
                width: '20px',
                marginRight: '10px'
            },
            '& span': {
                fontSize: '16px',
                lineHeight: '18px',
                fontWeight: 700
            }
        },
        dropLabelContainer: {
            position: 'absolute',
            top: 24,
            left: 28,
            [theme.breakpoints.down('xs')]: {
                top: 15,
                left: 17 
            }
        },
        dropLable: {
            fontWeight: 700
        },
        dropDate: {
            color: '#FF6800'
        },
        productStatus: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontWeight: 'bold'
        },
        soldOut: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 2,
            backgroundColor: 'rgba(255,255,255,0.8)',
            top: 0,
            left: 0
        },
        soldOutHeading: {
            color: theme.custom.common.lightGray
        },
        greenColor: {
            color: theme.custom.common.green
        },
        orangeColor: {
            color: theme.custom.common.orange
        },
        redColor: {
            color: theme.custom.common.red
        },
        productAction: {
            position: 'absolute',
            zIndex: theme.zIndex.drawer,
            top: '10px',
            right: '10px',
            padding: '3px 0 3px 3px',
            fontWeight: 'bold',
            fontSize: '24px',
            letterSpacing: '3px',
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        unlist: {
            position: 'absolute',
            zIndex: theme.zIndex.drawer,
            top: '10px',
            right: '10px',
            width: '68px',
            height: '21px',
            textTransform: 'capitalize',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '6px',
            color: theme.custom.common.orange,
            '&:hover': {
                backgroundColor: theme.custom.common.lightGray
            }
        }
    }
})

export { useStyles }
