import React, { useContext} from 'react'

import { Grid, Typography } from '@material-ui/core'
import { Modal } from 'components/Modal'
import { EmailSubscription } from 'components/EmailSubscription/EmailSubscription'
import { ProductActions, ProductContext } from 'context/ProductPageContext'

import { useStyles } from './EmailSubscriptionModal.styles'

export const EmailSubscriptionModal = (): JSX.Element => {
    const classes = useStyles()
    const { state, dispatch } = useContext(ProductContext)

    const onModalClose = () => {
        dispatch({ type: ProductActions.EMAIL_SUBSCRIPTION_MODAL_STATE, payload: false })
    }

    return (
        <Modal
            width={825}
            height={333}
            open={state.emailSubscriptionModalState}
            className={classes.modalContainer}
            onClose={onModalClose}
        >
            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                className={classes.modalContentWrapper}
            >
                <EmailSubscription
                    classNames={{
                        container: classes.emailSubscriptionContainer,
                        subscribeContainer: classes.subscribeContainer
                    }}
                >
                    <Typography variant="h1" className={classes.emailHeading}>
                        Get notified when this sneaker drops
                    </Typography>
                </EmailSubscription>
            </Grid>
        </Modal>
    )
}
