import React, { useEffect, useState } from 'react'

import { Grid, Button, Typography, Box, Link, useMediaQuery, useTheme, FormHelperText } from '@material-ui/core'
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import CloseIcon from '@material-ui/icons/Close'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'

import { useStyles } from './Dropzone.style'

type uploadBlocks = {
    viewUploadAlt?: boolean
    uploadAltText?: string
    uploadAltBtn?: string

    viewUpload?: boolean
    uploadText?: string
    uploadBtn?: string

    viewDropText?: boolean
    dropText?: string
}

export type DropzoneProps = uploadBlocks & {
    width?: number
    height?: number
    description?: string
    errorMessage?: string
    className?: string
    profileView?: boolean
    viewUploadBtn?: boolean
    dropAccepted?: boolean
    getRootProps?: <T extends DropzoneRootProps>(props?: T) => T
    getInputProps?: DropzoneInputProps
    handleReset?: (props?: any) => void
    handleDrop: (arg: File[]) => void
    children?: JSX.Element
    [key: string]: any // For dropzone state
}

function Dropzone({ 
    description,
    errorMessage,
    className,
    children,
    handleReset,
    handleDrop,
    uploadAltText,
    uploadAltBtn,
    uploadText,
    uploadBtn,
    dropText,
    profileView,
    dropAccepted = false,
    viewDropText = true,
    viewUploadAlt = true,
    viewUpload = true,
    viewUploadBtn = true,
    ...dropzoneState
}: DropzoneProps): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const [isDropAccepted, setDropAccepted] = useState(dropAccepted)
    const [error, setError] = useState<string>('')

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDropAccepted: (files) => {
            handleDrop(files)
            setDropAccepted(true)
        },
        onDropRejected: (files) => {
            let file = files[0]
            let errors = file.errors
            if (errors[0].code === 'file-too-large') {
                errors.shift()
            }
            setError(errors[0].message)
        },
        validator: (file) => {
            if(file.size > dropzoneState.maxSize) {
                let mb = Math.floor(dropzoneState.maxSize / 1024 / 1000)
                return {
                    code: 'file-too-large-mb',
                    message: `File is larger than ${mb} mb`
                }
            }
            setError('')
            return null
        },
        noClick: true,
        noKeyboard: true,
        ...dropzoneState
    })

    const onCaptureInput = (e: React.FormEvent<HTMLInputElement>) => {
        const target= e.target as HTMLInputElement
        const files: any = target.files
        if (files && handleDrop) {
            handleDrop(files)
            setDropAccepted(true)
        }
    }
    const onClose = (e: React.MouseEvent<SVGSVGElement>) => {
        e.stopPropagation()
        handleReset && handleReset()
        setDropAccepted(false)
    }

    useEffect(() => {
        setDropAccepted(dropAccepted)
    }, [dropAccepted])

    return (
        <Grid 
            container
            direction="column"
            justifyContent="flex-start">
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={`${classes.dragAndDropContainer} ${className} ${isDragActive && classes.dragAndDropActive}`}
                style={{ gap: matchSmDown ? 5 : 15 }}
                {...getRootProps()}
            >
                {!profileView ? <>
                    <input {...getInputProps()} className={classes.dropInput}/>
                    {isDropAccepted && !isDragActive && (
                        <Box className={classes.uploadedFileContainer}>
                            <Box className={classes.childWrap}>
                                <CloseIcon className={classes.closeBtn} onClick={onClose} />
                                {children}
                            </Box>
                            {
                                viewUploadAlt &&
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography
                                            className={classes.newPhotoText}
                                            color="textSecondary"
                                            align="center"
                                            variant="subtitle2">
                                            {uploadAltText || 'New Photo? Drag and Drop or '}
                                        </Typography>
                                        <Link component="span" className={classes.chooseText} onClick={open} color="textPrimary">
                                            {uploadAltBtn || 'Choose from Files'}
                                        </Link>
                                    </Grid>
                            }
                        </Box>)}
                    {isDragActive &&  <>
                        <InsertDriveFileIcon fontSize="large" className={classes.dropIcon} />
                        {viewDropText && 
                    <Typography
                        align="center"
                        variant="h3"
                        color="textPrimary"
                    >
                        {dropText || 'Drop File Here'}
                    </Typography>}
                    </>}
                    {!matchSmDown && viewUpload && !isDragActive && !isDropAccepted && <>
                        <Typography
                            align="center"
                            variant="subtitle1"
                            color="textSecondary"
                        >
                            {uploadText || 'Drag And Drop or'}
                        </Typography>
                        {
                            viewUploadBtn && <Button onClick={open} variant="outlined" color="secondary" className={classes.chooseFileButton}>
                                {uploadBtn || 'Choose From Files'}
                            </Button>
                        }
                        {description && (<Typography
                            align="center"
                            variant="body1"
                            color="textSecondary"
                        >
                            {description}
                        </Typography>)}
                    </>}
                    {matchSmDown && !isDropAccepted && !isDragActive && <>
                        <label htmlFor="captureInput">
                            <Button variant="outlined" color="secondary" className={classes.chooseFileButton}>
                                {'Take A Picture'}
                                <input 
                                    id="captureInput"
                                    className={classes.captureInput}
                                    onInput={onCaptureInput}
                                    type="file"
                                    capture="environment"
                                    accept="image/*"
                                />
                            </Button>
                        </label>

                        <Typography
                            align="center"
                            variant="subtitle1"
                            color="textSecondary"
                        >
                            {'or'}
                        </Typography>
                        <Button onClick={open} variant="outlined" color="secondary" className={classes.chooseFileButton}>
                            {'Choose From Gallery'}
                        </Button>
                        {description && (<Typography
                            className={classes.description}
                            align="center"
                            variant="subtitle1"
                            color="textSecondary"
                        >
                            {description}
                        </Typography>)}
                    </>
                    }
                </> : <>
                    <input {...getInputProps()} className={classes.dropInput}/>
                    {isDropAccepted && !isDragActive && (
                        <Box className={classes.uploadedFileContainer}>
                            <CloseIcon className={classes.closeBtn} onClick={onClose} />
                            {children}
                        </Box>)}
                    {isDragActive &&
                    <InsertDriveFileIcon fontSize="large" className={classes.dropIcon} />
                    }
                    {!isDragActive && !isDropAccepted && (
                        <PhotoCameraIcon fontSize="large" className={classes.dropIcon} />
                    )}
                </>}
            </Grid>
            {error || errorMessage && <FormHelperText className={classes.errorText} error>
                {error || errorMessage}
            </FormHelperText>}
        </Grid>

    )
}

export { Dropzone }
