import axios from 'axios'

import { ApiCore } from '../utilities'
import { handleResponse, handleError } from '../utilities/response'

const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://127.0.0.1:1337/'

const url = 'products'
const plural = 'products'
const single = 'product'
class ApiProducts extends ApiCore {
    getProductsByUser: any
}
const apiProducts = new ApiProducts({
    allowGetAll: true,
    allowGetSingle: true,
    allowPost: true,
    allowPut: false,
    allowPatch: false,
    allowRemove: false,
    url: url,
    plural: plural,
    single: single
})

apiProducts.getProductsByUser = (token: string, userID: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    return axios
        .get(`${BASE_URL}/${url}/byUserID/${userID}`, token ? config : {})
        .then(handleResponse)
        .catch(handleError)
}

export { apiProducts }
