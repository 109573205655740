import React, { useCallback, useState, useEffect} from 'react'

import { Layout } from 'components/Layout'
import { Grid, Box, CircularProgress } from '@material-ui/core'
import { CreatorsList } from 'components/Creators/CreatorsList'
import { apiUsers } from 'services/api/users'
import { ProfileEntity, UserModelType } from 'entities/Profile.entity'

import { useStyles } from './Creators.style'

function Creators(): JSX.Element {
    const classes = useStyles() 
    const [creatorsList, setCreatorsList] = useState<ProfileEntity[]>([])
    const [error, setError] = useState('')

    const fetchCreatorsData = useCallback(async () => {
        try {
            const creatorsList: UserModelType[] = await apiUsers.getAll('', '?role.type=creator') 
            setCreatorsList(creatorsList.map(item => new ProfileEntity(item)))
        } catch (error) {
            setError(error as string)
        }
    }, [])

    useEffect(() => {
        fetchCreatorsData()
    }, [fetchCreatorsData])

    if (!creatorsList.length) {
        return (
            <Layout>
                <Box>
                    <div className={classes.loadingErrorContainer}>
                        <CircularProgress size={60}/>
                    </div>
                </Box>
            </Layout>
        )
    }

    return (
        <Layout>
            <Grid className={classes.container}>
                <CreatorsList creatorsList={creatorsList} />
            </Grid>
        </Layout>
    )
}

export { Creators }
