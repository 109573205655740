import React, { useState, useContext, useEffect, useCallback } from 'react'

import { SECONDARY_MARKET_TYPE } from 'constants/product'

import { useHistory } from 'react-router-dom'
import { TransactionStatus } from 'components/Product/TransactionStatus'
import { FollowSteps } from 'components/Product/FollowSteps'
import { Link } from 'react-router-dom'
import { Grid, Typography, InputAdornment, FormControl, Input, Button } from '@material-ui/core'
import { OpKind, TransactionWalletOperation } from '@taquito/taquito'
import { Modal } from 'components/Modal'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import { ProductEntity } from 'entities/Product.entity'
import { ProductPreview } from 'components/Product/ProductPreview'
import { QuantityCounter } from 'components/QuantityCounter'
import { ReactComponent as TezosLogo } from 'shared/icons/tezos_logo.svg'
import { embed, Tezos } from 'utils/beacon-wallet'
import { useConnectedAccount } from 'hooks/useConnectedAccount' 
import { apiTokens } from 'services/api/tokens'
import { useAppSelector } from 'hooks/useStoreHooks'
import { SignInProvider } from 'entities/Account.entity'

import { useStyles } from './SellModal.style'
import { TezosOperationType } from '@airgap/beacon-sdk'

const additionalData = {
    creatorFee: 5,
    marketplaceFee: 5
}

enum SELL_TOKEN_STEPS { 
    INITIAL = 'INITIAL',
    APPROVING_TRANSFER = 'APPROVE_TRANSFER',
    CREATING_TRANSACTION = 'CREATING_TRANSACTION',
    TRANSACTION_CREATED = 'TRANSACTION_CREATED'
}

type SellModalProps = {
    productData: ProductEntity
}

export const SellModal = ({ productData }: SellModalProps): JSX.Element => {
    const walletData = useConnectedAccount()
    const classes = useStyles()
    const history = useHistory()

    const { state, dispatch } = useContext(ProductContext)
    const [error, setError] = useState<string>('')
    const [price, setPrice] = useState('0')
    const [sellTokenStep, setSellTokenStep] = useState<string>(SELL_TOKEN_STEPS.INITIAL)
    const [title, setTitle] = useState('LIST SNEAKER(S)')
    const [quantity, setQuantity] = useState(1)
    const [operationHash, setOperationHash] = useState('')
    const [feeCalculations, setFeeCalculations] = useState({
        marketplaceAmount: 0,
        creatorRoyaltyAmount: 0,
        youGetAmout: 0
    })

    const matchSellStep = (step: string) => sellTokenStep === step

    const resetState = () => {
        setPrice('0')
        setQuantity(1)
        setOperationHash('')
        setError('')
        setSellTokenStep(SELL_TOKEN_STEPS.INITIAL)
    }

    const { user, accessToken }: { user: any, accessToken: string } = useAppSelector(state => state.userReducer)
    const amountCalculations = useCallback(() => {
        const marketplaceAmount = (Math.round((parseFloat(price)*100*quantity*additionalData.marketplaceFee)/100))/100
        const creatorRoyaltyAmount = (Math.round((parseFloat(price)*100*quantity*additionalData.creatorFee)/100))/100
        const youGetAmout = Math.round((parseFloat(price)*100*quantity-marketplaceAmount*100-creatorRoyaltyAmount*100))/100

        setFeeCalculations({
            marketplaceAmount: marketplaceAmount,
            creatorRoyaltyAmount: creatorRoyaltyAmount,
            youGetAmout: youGetAmout
        })
    }, [quantity, price])

    const transferToken = async (amount: number, token_id: string) => {
        let response: any
        try {
            if (walletData?.address) {
                const sContract = await Tezos.contract.at('KT1Modu3vbvx3KJueUQfdRGWLczDUsaNB3ez')
                const fa2TransferParams = [{
                    from_: walletData.address,
                    txs: [
                        { 
                            to_: 'tz1SMmHA4ckCeYEgff1ALiPu7CiNHBrspWd5',
                            token_id,
                            amount
                        }
                    ]
                }]

                const transactions: any = [{
                    kind: TezosOperationType.TRANSACTION,
                    ...sContract.methods.transfer(fa2TransferParams).toTransferParams(),
                    amount: '0',
                    destination: sContract.address
                }]

                switch(walletData.verifierOrigin) {
                    case SignInProvider.EXTENSION: {
                        const batch = Tezos.wallet.batch(transactions)
                        const batchOp = await batch.send()
                        response = batchOp.opHash
                        break
                    }
                    case SignInProvider.WEB_KUKAI: {
                        transactions.map((transaction: any) => {
                            if (transaction['parameter']) {
                                transaction['parameters'] = transaction['parameter']
                                delete transaction['parameter']
                            }
                            return transaction
                        })
    
                        response = await embed.send(transactions)
                        break
                    }
                }

                return response
            }
            // throw new Error('wallet is not connected')
        } catch (error: any) {
            console.log(`[${walletData?.verifierOrigin}]`, error.message)
            if(error.message.includes('aborted')) {
                setError('Oops! Transaction was rejected. Retry to buy again.')
            } else {
                setError(error.message)
            }
        }
    }

    const onSellProduct = async () => {
        const opHash = await transferToken(quantity, productData.idx)
        setSellTokenStep(SELL_TOKEN_STEPS.APPROVING_TRANSFER)
        if (!opHash) return

        const response = await apiTokens.post(accessToken, {
            onSale: quantity,
            product: productData.id,
            seller: user.id,
            marketType: SECONDARY_MARKET_TYPE,
            price
        })
        setOperationHash(opHash)
        setSellTokenStep(SELL_TOKEN_STEPS.TRANSACTION_CREATED)
        console.log({ response })
    }

    const actionHandler = async () => {
        switch (sellTokenStep) {
            case SELL_TOKEN_STEPS.INITIAL: 
                onSellProduct()
                break
            case SELL_TOKEN_STEPS.APPROVING_TRANSFER:
            case SELL_TOKEN_STEPS.CREATING_TRANSACTION:
                setSellTokenStep(SELL_TOKEN_STEPS.INITIAL)
                resetState()
                break
            case SELL_TOKEN_STEPS.TRANSACTION_CREATED:
                history.push(`/profile/${walletData?.address}`)
                break
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let priceVal = event.target.value.replace(/[^\d.]/g, '')
        priceVal = priceVal.replace(/(\.{1}[\d]?)\./g, '$1')
        priceVal = priceVal.replace(/(\.\d{2})([\d.])/g, '$1')

        if (price == '0') {
            priceVal = priceVal.replace(/0/g, '')
        }

        if(priceVal.length > 0){
            priceVal = (priceVal.charAt(0) === '.') ? '0' + priceVal : priceVal
            priceVal = priceVal.replace(/(^0(?=\d+))/g, '')
        }else{
            priceVal = '0'
        }

        setPrice( priceVal )
    }

    useEffect(() => {
        amountCalculations()
    }, [amountCalculations])

    useEffect(() => {
        if(sellTokenStep === SELL_TOKEN_STEPS.APPROVING_TRANSFER || sellTokenStep === SELL_TOKEN_STEPS.CREATING_TRANSACTION) {
            setTitle('FOLLOW STEPS')
        } else if (sellTokenStep === SELL_TOKEN_STEPS.TRANSACTION_CREATED) {
            setTitle(`Putting ${productData.title} on sale`)
        }
    }, [sellTokenStep, productData.title])

    const onSellModalClose = () => {
        resetState()
        dispatch({ type: ProductActions.SELL_MODAL_STATE, payload: false })
    }

    const onHelperModalOpen = () => {
        dispatch({ type: ProductActions.PRICING_HELPER_MODAL_STATE, payload: true })
    }

    const handleQuantity = (value: number) => {
        setQuantity(value)
    }

    return (
        <Modal
            width={741}
            height={564}
            open={state.sellModalState}
            title={title}
            onClose={onSellModalClose}
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"

            >
                <ProductPreview key={productData.id} className={classes.productPreviewModal} productData={productData} />
                <div className={classes.transcationAction}>
                    {
                        matchSellStep(SELL_TOKEN_STEPS.APPROVING_TRANSFER) || matchSellStep(SELL_TOKEN_STEPS.CREATING_TRANSACTION) ?
                            <FollowSteps approveLoading={matchSellStep(SELL_TOKEN_STEPS.APPROVING_TRANSFER)} purchaseLoading={matchSellStep(SELL_TOKEN_STEPS.CREATING_TRANSACTION)} /> :
                            matchSellStep(SELL_TOKEN_STEPS.TRANSACTION_CREATED) ?
                                <TransactionStatus status={'Confirmed'} hash={operationHash} /> :
                                <>
                                    <Link to="#" onClick={onHelperModalOpen} className={classes.pricingHelper}>PRICING HELPER</Link>
                                    <Typography variant="subtitle2" className={classes.setPriceLable}>ENTER A PRICE IN TEZ</Typography>
                                    <FormControl className={classes.setPrice} variant="filled">
                                        <Input
                                            id="price"
                                            value={price}
                                            onChange={handleChange}
                                            endAdornment={<InputAdornment className={classes.tz} position="end"><TezosLogo className="tezos-logo" /></InputAdornment>}
                                            inputProps={{
                                                className: classes.price
                                            }}
                                            disableUnderline={true}
                                        />
                                    </FormControl>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        className={classes.quantityContainer}
                                    >
                                        <Typography variant="subtitle2">QUANTITY</Typography>
                                        <QuantityCounter
                                            value={1}
                                            min={1}
                                            max={productData.owned}
                                            onChange={handleQuantity}
                                            className={classes.quantity}
                                        />
                                    </Grid> 
                                    <span className={classes.line}></span>
                                    <Typography variant="subtitle2" className={classes.mpFee}>
                                        MARKETPLACE FEE ({additionalData.marketplaceFee}%)
                                        <span className={classes.subSpan}>{feeCalculations.marketplaceAmount}<TezosLogo className="tezos-logo--small" /></span>
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.crFee}>
                                        CREATOR ROYALTY ({additionalData.creatorFee}%)
                                        <span className={classes.subSpan}>{feeCalculations.creatorRoyaltyAmount}<TezosLogo className="tezos-logo--small" /></span>
                                    </Typography>
                                    <Typography variant="h5" className={classes.youGet}>
                                        WHAT YOU WILL GET
                                        <span className={classes.subSpan}>{feeCalculations.youGetAmout}<TezosLogo className="tezos-logo--small" /></span>
                                    </Typography>
                                </>
                    }
                    <Typography className={classes.errorMsg} variant="h5">{error}</Typography>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        className={classes.sellButton}
                        disabled={!walletData || !Number(price)} 
                        onClick={actionHandler}
                    >
                        {
                            matchSellStep(SELL_TOKEN_STEPS.APPROVING_TRANSFER) || matchSellStep(SELL_TOKEN_STEPS.CREATING_TRANSACTION) ?
                                'Cancel' :
                                matchSellStep(SELL_TOKEN_STEPS.TRANSACTION_CREATED) ?
                                    'Go to profile':
                                    error ?
                                        'Retry'
                                        : 'List'
                        }
                    </Button>
                </div>
            </Grid>
        </Modal>
    )
}
