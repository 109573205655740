import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            height: '100%',
            padding: '0 40px 30px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 10px 20px'
            }
        },
        dropzone: {
            minHeight: 390
        },
        dressBlock: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            padding: '15px 35px',
            border: 'solid 1px',
            borderColor: theme.custom.dressing.blockBorder,
            '&:last-child': {
                borderLeftWidth: 0
            },
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                padding: '50px 5px 0',
                '&:last-child': {
                    borderLeftWidth: 1,
                    borderTopWidth: 0
                }
            }
        },
        errorMsg: {
            padding: '100px 0'
        },
        dressingImageBox: {
            position: 'relative'
        },
        logo: {
            position: 'absolute',
            top: 10,
            left: 10
        },
        imgWrap: {
            display: 'inline-block',
            position: 'relative',
            maxHeight: 450
        },
        uploadedImg: {
            maxWidth: '100%',
            maxHeight: 'inherit'
        },
        arCamera: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            maxHeight: 'auto'
        },
        prodInfoSmall: {
            position: 'absolute',
            left: 10,
            bottom: 10,
            transform: 'scale(0.51) translate(-48%, 42%)',
            background: '#fff',
            zIndex: 10
        },
        stepCaption: {
            lineHeight: '36px',
            marginBottom: 20
        },
        noProductsText: {
            marginTop: 15
        },
        productListContainer: {
            height: '100%',
            minHeight: '550px',
            flexGrow: 1
        },
        shoppingBtn: {
            fontSize: 16,
            marginTop: 20,
            textTransform: 'none',
            lineHeight: '21px',
            padding: '10px 0'
        },
        sneakerImgWrapper: {
            display: 'block',
            width: '130px',
            height: '124px',
            overflow: 'hidden',
            cursor: 'pointer',
            margin: '5px',
            boxSizing: 'border-box',
            borderRadius: '10px',
            '&:hover': {
                boxShadow: `0px 0px 8px ${theme.custom.dressing.sneakerImgBoxShadow}`
            },
            '& img': {
                width: '100%',
                height: '100%'
            }
        },
        scrollbar: {
            width: '100%',
            maxHeight: '500px',
            [theme.breakpoints.down('sm')]: {
                height: '144px !important'
            }
        },
        sneakerBox: {
            width: '100%',
            paddingRight: '5px',
            '& a': {
                margin: '15px 22px',
                [theme.breakpoints.down('sm')]: {
                    margin: '5px'
                }
            },
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'nowrap',
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '134px'
            }
        },
        trackY: {
            width: '4px !important',
            height: '100% !important',
            '& .ScrollbarsCustom-Thumb': {
                backgroundColor: `${theme.custom.dressing.trackColor} !important`
            }
        },
        trackX: {
            height: '4px !important',
            width: '100% !important',
            left: '0 !important',
            '& .ScrollbarsCustom-Thumb': {
                backgroundColor: `${theme.custom.dressing.trackColor} !important`
            }
        }
    }
})
export { useStyles }
