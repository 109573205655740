import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Grid, Typography, Button, Tabs, Tab, CircularProgress } from '@material-ui/core'
import { Modal } from 'components/Modal'
import { BodyCellRendererProps, Table, TableColumn } from 'components/Table'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import { apiOrders } from 'services/api/orders'
import { apiTokens } from 'services/api/tokens'
import { formatDate } from 'utils/helpers'
import { TokenModelType } from 'entities/Token.entity'
import { useAppSelector } from 'hooks/useStoreHooks'

import { useStyles } from './PricingHelperModal.styles'

enum TabsTitles {
    LAST_SALES = 'LAST SALES',
    CURRENT_LISTINGS = 'CURRENT LISTINGS'
}

const columnsKeys = {
    PRICE: 'price',
    SELLER: 'seller',
    QUANTITY: 'quantity',
    TIME: 'time'
}

const createColumns = (columnKeys: object):TableColumn[] => {
    let columnWidth = 100 / Object.values(columnKeys).length
    return Object.values(columnKeys).map(value => ({ key: value, title: value.toUpperCase(), width: `${columnWidth}%`}))
}

const salesColumns: TableColumn[] = createColumns(columnsKeys)
const listingsColumns: TableColumn[] = createColumns({
    PRICE: columnsKeys.PRICE,
    SELLER: columnsKeys.SELLER,
    QUANTITY: columnsKeys.QUANTITY
})

export const PricingHelperModal = (): JSX.Element => {
    const classes = useStyles()

    const { state, dispatch } = useContext(ProductContext)
    const { accessToken } = useAppSelector(state => state.userReducer)

    const [tab, setTab] = useState(TabsTitles.LAST_SALES)
    const [orders, setOrders] = useState([])
    const [loadingOrders, setLoadingOrders] = useState(true)
    const [tokens, setTokens] = useState([])
    const [loadingTokens, setLoadingTokens] = useState(true)
    const { id } = useParams<{ id: string }>()

    const getData = (data: any) => {
        return data.map((row: any) => {
            return {
                key: row.id,
                price: row.total ? row.total : row.price,
                seller: row.seller?.profile?.username,
                qty: row.quantity ? row.quantity : row.onSale,
                time: formatDate(row.createdAt)
            }
        })
    }

    const getOrders = useCallback(async () => {
        const allOrdersResponse = await apiOrders.getAll(accessToken, `?product=${id}`)
        setOrders(getData(allOrdersResponse))
        setLoadingOrders(false)
    }, [id])

    const getTokens = useCallback(async () => {
        const allTokensResponse: TokenModelType[] = await apiTokens.getAll(accessToken, `?product=${id}`)
        setTokens(getData(allTokensResponse))
        setLoadingTokens(false)
    }, [id])

    const handleTabChange = (event: any, newTab:TabsTitles) => {
        setTab(newTab)
    }

    const onModalsClose = () => {
        dispatch({ type: ProductActions.PRICING_HELPER_MODAL_STATE, payload: false })
        dispatch({ type: ProductActions.SELL_MODAL_STATE, payload: false })
    }

    const onHelperModalClose = () => {
        dispatch({ type: ProductActions.PRICING_HELPER_MODAL_STATE, payload: false })
    }

    const bodyRenderer = ({ columnKey, row }: BodyCellRendererProps) => {
        let dataCell

        switch (columnKey) {
            case columnsKeys.PRICE:
                dataCell = `${row.price} TEZ`
                break
            case columnsKeys.SELLER:
                dataCell = `@${row.seller}`
                break
            case columnsKeys.QUANTITY:
                dataCell = row.qty
                break
            case columnsKeys.TIME:
                dataCell = row.time
                break
        }
        
        return <Typography className={classes.tableDataCell} variant="subtitle1">{dataCell}</Typography>
    }

    useEffect(() => {
        getOrders()
        getTokens()
    }, [])

    return (
        <Modal
            width={740}
            open={state.pricingHelperModalState}
            title="PRICING HELPER"
            titleClass={classes.tableTitle}
            onClose={onModalsClose}
        >
            <Tabs
                value={tab}
                onChange={handleTabChange}
                textColor="primary"
                centered
            >
                {Object.values(TabsTitles).map(tab => (<Tab key={tab} label={tab} value={tab} className={classes.tab}/>))}
            </Tabs>
            {(
                (tab === TabsTitles.LAST_SALES && loadingOrders) ||
                (tab === TabsTitles.CURRENT_LISTINGS && loadingTokens)
            ) ? (
                    <Grid container justifyContent="center" className={classes.loadingContainer}>
                        <CircularProgress size={60}/>
                    </Grid>
                ) : (tab === TabsTitles.LAST_SALES && orders.length === 0) ?
                    (
                        <Typography className={classes.noItems}>No orders</Typography>
                    ) : (tab === TabsTitles.CURRENT_LISTINGS && tokens.length === 0) ?
                        (
                            <Typography className={classes.noItems}>No current listings</Typography>
                        ) : (
                            <Table
                                columns={tab === TabsTitles.LAST_SALES ? salesColumns : listingsColumns}
                                rows={tab === TabsTitles.LAST_SALES ? orders : tokens}
                                bodyCellRenderer={bodyRenderer}
                                headerCellRenderer={({ title }) => (
                                    <Typography className={classes.tableCellHeader} variant="subtitle1">{title}</Typography>
                                )}
                            />
                        )}
            <Grid container justifyContent="flex-end">
                <Button variant="outlined" color="primary" className={classes.backButton} onClick={onHelperModalClose}>
                        Back
                </Button>
            </Grid>
        </Modal>
    )
}
