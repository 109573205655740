import React, { useState, useContext, useEffect } from 'react'

import { ORDER_AWAITING_PAYMENT_STATUS } from 'constants/order'

import { NavLink } from 'react-router-dom'
import { Grid, Typography, Link } from '@material-ui/core'
import { AdornedButton } from 'components/Button'
import { Modal } from 'components/Modal'
import { BodyCellRendererProps, Table, TableRow } from 'components/Table'
import { QuantityCounter } from 'components/QuantityCounter'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import { TokenModelType, TokenEntity } from 'entities/Token.entity'
import { apiOrders } from 'services/api/orders'
import { useConnectedAccount } from 'hooks/useConnectedAccount' 
import { useAppSelector } from 'hooks/useStoreHooks'

import { useStyles } from './PurchaseModal.style'
import { getAccountBalance } from 'utils/beacon-wallet'

type Order = {
    quantity: number
    tokenId: string,
    productId: string,
    sellerId: string,
    sellerName: string | undefined,
    price: string,
    totalPrice: number,
    marketType: string
}

enum TabsTitles {
    AVAILABILITY = 'availability',
    PRICE = 'price',
    SELLER = 'seller',
    QUANTITY = 'quantity'
}

const columns = [
    { key: 'availability', title: 'AVAILABILITY', width: '25%' },
    { key: 'price', title: 'PRICE', width: '25%' },
    { key: 'seller', title: 'SELLER', width: '25%' },
    { key: 'quantity', title: 'QUANTITY', width: '25%' }
]

export const PurchaseModal = ({ tokens }: { tokens: TokenModelType[] }): JSX.Element => {
    const classes = useStyles()
    const walletData = useConnectedAccount()
    const { state, dispatch } = useContext(ProductContext)
    const [order, setOrder] = useState<Order | null>(null)
    const [quantity, setQuantity] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false) 
    const [error, setError] = useState<string>('')
    const [balanceError, setBalanceError] = useState<boolean>(false)
    const [accountBalance, setAccountBalance] = useState<number>(0)
    const tokenRows: TableRow[] = tokens.map(token => new TokenEntity(token)).filter(item => item.onSale)
    const { user, accessToken }: { user: any, accessToken: string } = useAppSelector(state => state.userReducer)

    const fetchBalance = async () => {
        if (!user) return 0
        const balance = (await getAccountBalance(user.username)).toString()
        setAccountBalance(balance)
    }

    // b4dPotato TODO: Move order functionality to Store
    const handleProductQty = (quantity: number, row: any) => {
        const { 
            key: tokenId,
            productId,  
            sellerId, 
            sellerName,
            price,
            marketType 
        } = row

        setQuantity(quantity)
        setError('')

        const totalPrice = quantity * price
        const checkBalance = checkBalanceCount(totalPrice)

        if (checkBalance && quantity) {
            setOrder({
                quantity,
                tokenId,
                productId,
                sellerId,
                sellerName,
                price,
                totalPrice,
                marketType 
            })
        } else {
            setOrder(null)
        }
    }

    const checkBalanceCount = (totalPrice: number) => {
        if(!accountBalance || accountBalance < totalPrice){
            setBalanceError(true)
            return false
        }
        setBalanceError(false)
        return true
    }

    const topUp = (e: any) => {
        e.preventDefault()
        dispatch({ type: ProductActions.MOONPAY_MODAL_STATE, payload: true })
    }

    const createOrder = async () => {
        if (!order) return
        setLoading(true)
        try { 
            const orderData = {
                quantity: order.quantity,
                token: order.tokenId,
                product: order.productId,
                seller: order.sellerId,
                purchaser: user.id,
                total: order.totalPrice,
                orderStatus: ORDER_AWAITING_PAYMENT_STATUS 
            }
            const createOrderResponse = await apiOrders.post(accessToken, orderData)
            console.log({createOrderResponse})
            dispatch({ type: ProductActions.BUY_MODAL_STATE, payload: true })
            dispatch({ type: ProductActions.BUY_MODAL_ORDER, payload: createOrderResponse.order })
            dispatch({ type: ProductActions.BUY_MODAL_PAYMENT, payload: createOrderResponse.payment })
        } catch(error: any) {
            console.log({ error })
            if (error.message) setError(error.message)
        } finally {
            setLoading(false)
        }
    }

    const onPurchaseModalClose = () => {
        dispatch({ type: ProductActions.PURCHASE_MODAL_STATE, payload: false })
    }

    const onBuyModalTrue = () => {
        createOrder()
    }

    useEffect(() => {
        if (state.purchaseModalState) {
            fetchBalance()
        }
    }, [state.purchaseModalState])

    useEffect(() => {
        setError('')
        setBalanceError(false)
        return () => setOrder(null)
    }, [state.purchaseModalState])

    const bodyRenderer = ({ columnKey, row }: BodyCellRendererProps) => {
        switch (columnKey) {
            case TabsTitles.AVAILABILITY:
                return <Typography className={classes.tableDataCell} variant="subtitle1">{row.onSale}/{row.total}</Typography>
            case TabsTitles.PRICE:
                return <Typography className={classes.tableDataCell} variant="subtitle1">{row.price}TEZ</Typography>
            case TabsTitles.SELLER:
                return <Link component={NavLink} to={{ pathname: '/profile/' + row.sellerAddress }} className={classes.tableDataCell} variant="subtitle1">@{row.sellerName}</Link>
            case TabsTitles.QUANTITY:
                return (
                    <QuantityCounter
                        max={row.onSale}
                        disabled={Boolean(order?.tokenId) && order?.tokenId !== row.key || row.sellerId === user?.id}
                        onChange={(value) => handleProductQty(value, row)}
                    />
                )
            default:
                return <div />
        }
    }

    return (
        <Modal
            width={740}
            open={state.purchaseModalState}
            title="PURCHASE A SNEAKER(S)"
            onClose={onPurchaseModalClose}
        >
            <>
                <Table
                    className={classes.table}
                    columns={columns}
                    rows={tokenRows}
                    bodyCellRenderer={bodyRenderer}
                    headerCellRenderer={({ title }) => (
                        <Typography className={classes.tableCellHeader} variant="subtitle1">{title}</Typography>
                    )}
                />
                <Grid container justifyContent="flex-end" alignItems="center">
                    { !walletData?.address && (<Typography variant="body1" className={classes.buyError}>You need to connect your wallet first</Typography>)}
                    { error && (<Typography variant="body1" className={classes.buyError}>{error}</Typography>)}
                    {balanceError && (
                        <Typography variant="body1" className={classes.buyError}>
                            Not enough crypto. Click <Link href="#" onClick={topUp}>here</Link> to top-up
                        </Typography>
                    )}
                    <AdornedButton 
                        variant="outlined" 
                        color="primary" 
                        className={classes.buyButton}
                        disabled={quantity < 1 || !walletData?.address || balanceError}
                        loading={loading} 
                        onClick={onBuyModalTrue}
                    >
                        {loading ? 'Reserving...' : 'Buy'}
                    </AdornedButton>
                    
                </Grid>
            </>
        </Modal>
    )
}
