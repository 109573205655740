import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        loadingErrorContainer: {
            maxWidth: '1440px',
            margin: '100px auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
})
export { useStyles }
