import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        signInTitle: {
            fontSize: '36px',
            lineHeight: '41px',
            textTransform: 'uppercase',
            fontStyle: 'italic'
        },
        signInDesc: {
            maxWidth: '370px',
            paddingTop: '20px',
            textAlign: 'center',
            '& p': {
                fontSize: '14px',
                lineHeight: '16px'
            }
        },
        bottonsContainer: {
            paddingTop: '20px'
        },
        signInBottonContainer: {
            width: '130px'
        },
        signInBottons: {
            padding: 0,
            height: '33px',
            fontWeight: 700,
            '& span': {
                padding: 0
            }
        },
        socialSignIn: {
            borderRadius: 0,
            backgroundColor: theme.custom.common.orange,
            color: theme.custom.common.white,
            '&:hover': {
                backgroundColor: theme.custom.common.white,
                border: `1px solid ${theme.custom.common.orange}`,
                color: theme.custom.common.orange
            }
        },
        kukai: {
            fontSize: '6px',
            textAlign: 'right',
            color: theme.custom.common.lightGray,
            '& img': {
                position: 'relative',
                top: '1px'
            }
        },
        message: {
            marginTop: 20
        },
        walletsInfo: {
            position: 'absolute',
            bottom: '10px',
            fontSize: '10px',
            color: theme.custom.common.lightGray,
            '&:hover': {
                textDecoration: 'none'
            }
        }
    }
})

export { useStyles }
