import React, { useEffect, useState } from 'react'

import { Button, Grid, Typography } from '@material-ui/core'
import vector from 'shared/icons/vector.png'
import { getTimeDuration, timeViewer } from 'utils/helpers'
import { ProductEntity } from 'entities/Product.entity'
import { useLocation, useHistory } from 'react-router-dom'
import { ProductPreviewInfo } from 'components/Product/ProductPreviewInfo'
import clsx from 'clsx'

import { useStyles } from './ProductPreview.style'

type ProductPreviewProps = {
    className?: string,
    qrCodeUrl?: string
    productData: ProductEntity,
    onRemoveSaleOpen?: (product: ProductEntity) => void
    onSale?: boolean
}

function ProductPreview({ className, productData, qrCodeUrl, onSale, onRemoveSaleOpen }: ProductPreviewProps): JSX.Element {
    const classes = useStyles()

    const location = useLocation()
    const history = useHistory()

    const isDropPage = location.pathname === '/drops'
    //const dropDate = new Date(productData.dropDate).toDateString().split(' ').slice(1).join(' ')

    const [time, setTimer] = useState(
        getTimeDuration(new Date(productData.dropDate))
    )

    const [unlist, setUnlist] = useState<boolean>(false)

    useEffect(() => {
        if(isDropPage) {
            const timerId = setInterval(() => {
                setTimer(getTimeDuration(new Date(productData.dropDate)))
            }, 1000)
            return () => clearInterval(timerId)
        }
    }, [isDropPage, productData])

    const navigateToArCameraPage = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        history.push('/ar-camera/' + productData.id)
    }

    const getProductStatus = () => {
        switch (productData.status) {
            case 'approved':
                return <Typography variant="subtitle1" className={classes.productStatus + ' ' + classes.greenColor }>READY TO DROP</Typography>
            case 'rejected':
                return <Typography variant="subtitle1" className={classes.productStatus + ' ' + classes.redColor }>REJECTED</Typography>   
            case 'pending-approval':
                return <Typography variant="subtitle1" className={classes.productStatus + ' ' + classes.orangeColor }>PENDING</Typography>    
        }
    }

    return (
        <div className={classes.productPreviewContainer}>
            {(onSale && !unlist) && (
                <Typography
                    variant="subtitle1"
                    className={classes.productAction}
                    onClick={() => {setUnlist(true)}}
                >
                    &bull;&bull;&bull;
                </Typography>
            )}
            { unlist && (
                <Button className={classes.unlist} onClick={() => onRemoveSaleOpen && onRemoveSaleOpen(productData)}>Unlist</Button>
            )}
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className={clsx(classes.productPreview, className)}
                onClick={() => {history.push(`/products/${productData.id}`)}}
            >
                {isDropPage && <Grid className={classes.dropLabelContainer}>
                    <Typography variant="subtitle2" className={classes.dropLable}>DROPPING IN</Typography>
                    <Typography variant="h3" className={classes.dropDate}>{ timeViewer(time) }</Typography>
                </Grid>}

                {getProductStatus()}
                {productData.onSale < 1 && <Grid className={classes.soldOut} container alignItems="center" justifyContent="center"><Typography variant="caption" className={classes.soldOutHeading}>SOLD OUT</Typography></Grid>}
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.pimg}
                >
                    <img src={productData.imageUrl} alt={productData.imageUrl} />
                </Grid>
                <ProductPreviewInfo qrCodeUrl={qrCodeUrl} product={productData} />
                <div className={classes.mobSection}>
                    <Button
                        className={classes.tryon}
                        onClick={navigateToArCameraPage}
                    >
                        <img src={vector} />
                        <span>Virtual Try-On</span>
                    </Button>
                </div>
            </Grid>
        </div>
    )
}
export { ProductPreview }
