import { useState, useEffect, useMemo } from 'react'

import { getActiveAccount } from 'utils/beacon-wallet'
import { AccountEntity } from 'entities/Account.entity'

export const useConnectedAccount = (): AccountEntity | undefined => {
    const [account, setAccount] = useState<AccountEntity>()
    useEffect(() => {
        async function getWalletData() {
            let activeAccount = await getActiveAccount()
            setAccount(activeAccount)
        }
        getWalletData()
    }, [])

    return useMemo(() => (account), [account])
}