import React, { useEffect, useState } from 'react'

import { PRODUCT_STATUS_IDS } from 'constants/product'

import { Grid, Box, Typography, CircularProgress } from '@material-ui/core'
import { Layout } from 'components/Layout'
import { ProductList } from 'components/Product/ProductList'
import { 
    ProductEntity,
    ProductModelType
} from 'entities/Product.entity'
import { EmailSubscription } from 'components/EmailSubscription'
import { apiProducts } from 'services/api/products'
import { useCreateDropsList } from 'hooks/useCreateDropsList'
import { useAppSelector } from 'hooks/useStoreHooks'

import { useStyles } from './Drops.style'


export const DropsPage = (): JSX.Element => {
    const classes = useStyles()

    const [allProducts, setAllProducts] = useState<ProductEntity[]>()
    const { accessToken } = useAppSelector(state => state.userReducer)

    const dropsList = useCreateDropsList(allProducts)

    const fetchAllProducts = async () => {
        const nowDate = new Date(Date.now())
        const filter = {
            status: PRODUCT_STATUS_IDS['minted'], 
            dropDate: { $gt: nowDate.toISOString() }
        }
        const sort = {
            dropDate: 'asc'
        }
        const qs = new URLSearchParams({ filter:  JSON.stringify(filter), sort: JSON.stringify(sort) })
        const products: ProductModelType[] = await apiProducts.getAll(accessToken, `?${qs}`)
        setAllProducts(
            products.map((product: ProductModelType) =>
                new ProductEntity(product)
            )
        )
    }

    useEffect(() => {
        fetchAllProducts()
    }, [])

    if (!allProducts) {
        return (
            <Layout>
                <Grid container justifyContent="center" className={classes.loadingContainer}>
                    <CircularProgress size={60}/>
                </Grid>
            </Layout>
        )
    }

    return (
        <Layout>
            <Box>
                {Object.keys(dropsList).length > 0 ? Object.entries(dropsList).map(([month, productData], index) => (
                    <div key={`${month}_${index}`} className={classes.dropsContainer}>
                        <ProductList title={month} products={productData} />
                    </div>
                )) : 
                    <div className={classes.loadingContainer}>
                        <Typography className={classes.dropMonthLabel}>No drops yet</Typography>
                    </div>
                }
                <Box className={classes.wrapper}>
                    <EmailSubscription />
                </Box>
            </Box>
        </Layout>
    )
}