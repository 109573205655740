import React, { useContext, useEffect, useState } from 'react'

import { Link, useHistory, useLocation } from 'react-router-dom'
import { Box, Grid, Typography, IconButton } from '@material-ui/core'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import SearchIcon from '@material-ui/icons/Search'
import Logo from 'shared/icons/logo.png'
import { SignInModal } from 'components/Layout/SignInModal'
import { MoonpayModal } from 'components/MoonpayModal'
import { getActiveAccount } from 'utils/beacon-wallet'
import { disconnectWallet } from 'utils/beacon-wallet'
import { ConnectWalletProfile } from 'components/ConnectWalletModule/ConnectWalletProfile'
import { useAppDispatch, useAppSelector } from 'hooks/useStoreHooks'
import { resetUser } from 'store/user'
import { AccountEntity } from 'entities/Account.entity'

import { useStyles } from './Header.style'

const getMenuItems = (user: any) => ([
    {
        label: 'Marketplace',
        url: '',
        isVisible: true
    },
    {
        label: 'Drops',
        url: '/drops',
        isVisible: true
    },
    {
        label: 'Creators',
        url: '/creators',
        isVisible: true
    },
    {
        label: 'Profile',
        url: `/profile/${user?.username}`,
        isVisible: user
    },
    {
        label: 'Dressing',
        url: '/dressing',
        isVisible: user
    }
])

type MenuItem = {
    label: string,
    url: string,
    isVisible: boolean
}

type iHeader = {
    openSearchPopup: () => void;
}

function Header({ openSearchPopup }: iHeader): JSX.Element {
    const classes = useStyles()
    const { dispatch } = useContext(ProductContext)
    const location = useLocation()
    const history = useHistory()
    const { user } = useAppSelector(state => state.userReducer)
    const appDispatch = useAppDispatch()

    const [isMobileMenuVisible, toggleMobileMenu] = useState(false)
    const [menuItems, setMenuItems] = useState<MenuItem[]>(getMenuItems(user))
    const [account, setAccount] = useState<AccountEntity>()

    const handleMenuButtonClick = () => {
        toggleMobileMenu(!isMobileMenuVisible)
    }

    const handleSignIn = (e: any) => {
        e.preventDefault()
        dispatch({ type: ProductActions.SIGNIN_MODAL_STATE, payload: true })
    }

    const initAccount = async () => {
        setAccount(await getActiveAccount())
    }

    const disconnect = async () => {
        try {
            await disconnectWallet()
            history.push('/')
            appDispatch(resetUser())
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
    }

    useEffect(() => {
        initAccount()
        setMenuItems(getMenuItems(user))
    }, [user])

    const mobileMenu = isMobileMenuVisible ? (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.mobileNav}
        >
            <nav>
                {menuItems.map((menuItem) => {
                    return menuItem.isVisible && (
                        <Link
                            key={menuItem.label}
                            className={classes.link}
                            to={menuItem.url}
                        >
                            <Typography variant="body2" className={classes.linkText} noWrap>
                                {menuItem.label}
                            </Typography>
                        </Link>
                    )}
                )}

                {account?.address ? (
                    <ConnectWalletProfile
                        account={account}
                        disconnect={disconnect}
                    />
                ) : (
                    <Link
                        className={classes.link}
                        to="#"
                        onClick={handleSignIn}
                    >
                        <Typography variant="body2" className={classes.linkText} noWrap>
                                Sign-In
                        </Typography>
                    </Link>
                )}
            </nav>
        </Grid>
    ) : null
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            className={classes.container}
        >
            <Grid
                container
                direction="row"
                className={classes.leftCol}
                wrap="nowrap"
            >
                <Link to="/">
                    <Box className={classes.logoWrap}>
                        <img src={Logo} alt="Flex" className={classes.logoImg} />
                    </Box>
                </Link>
                <Box className={classes.buttons}>
                    <IconButton
                        component="div"
                        className={classes.searchButton}
                        onClick={openSearchPopup}
                    >
                        <SearchIcon color="primary" style={{ fontSize: 27 }}/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        component="div"
                        className={`${classes.menuButton} ${
                            isMobileMenuVisible ? 'active' : ''
                        }`}
                        onClick={handleMenuButtonClick}
                    />
                </Box>
            </Grid>
            <nav className={classes.navContainer}>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    {menuItems.map((menuItem) => {
                        return menuItem.isVisible && (
                            <Link
                                key={menuItem.label}
                                className={classes.link}
                                to={menuItem.url}
                            >
                                <Typography variant="body2" className={location.pathname === menuItem.url ? classes.activeLinkText : classes.linkText} noWrap>
                                    {menuItem.label}
                                </Typography>
                            </Link>
                        )
                    })}

                    {account?.address ? (
                        <ConnectWalletProfile
                            account={account}
                            disconnect={disconnect}
                        />
                    ) : (
                        <Link
                            className={classes.link}
                            to="#"
                            onClick={handleSignIn}
                        >
                            <Typography variant="body2" className={classes.linkText} noWrap>
                                Sign-In
                            </Typography>
                        </Link>
                    )}
                </Grid>
            </nav>
            {mobileMenu}
            <SignInModal />
            <MoonpayModal />
        </Grid>
    )
}

export { Header }
