import React from 'react'

import '@google/model-viewer/dist/model-viewer'
import { useStyles } from './Model3D.style'

type Model3DProps = {
    filePath: string
    className?: string
}

function Model3D({ filePath, className }: Model3DProps): JSX.Element {
    const classes = useStyles()

    return (
        <div className={`${classes.model3D} ${className}`}>
            <model-viewer
                style={{ width: '100%', height: '100%' }}
                className={classes.modelViewer}
                src={filePath}
                alt="Sneaker"
                camera-controls
                auto-rotate
            />
        </div>
    )
}

export { Model3D }
