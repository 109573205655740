import React, { useContext } from 'react'

import { Grid, Button } from '@material-ui/core'


import { useStyles } from './ConnectWalletModule.style'

type ConnectWalletModuleProps = {
    onConnect: () => void
}

function ConnectWalletModule({ onConnect }: ConnectWalletModuleProps): JSX.Element {
    const classes = useStyles()

    return (
        <Grid container className={classes.walletButtonContainer}>
            <Button
                variant="outlined"
                color="primary"
                className={classes.connectWalletButton}
                onClick={onConnect}
            >
                Connect Wallet
            </Button>

        </Grid>
    )
}

export { ConnectWalletModule }
