
import { PRODUCT_STATUS_IDS } from 'constants/product' 

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import qs from 'qs'
import { apiUsers } from 'services/api/users'
import { apiProducts } from 'services/api/products'
import { isProfileACreator } from 'utils/profile'

import { store } from './store'

enum Messages {
    NO_TEXT = 'Enter text to search for products or users.'
}

export type SearchData = {
    id: number;
    type: string;
    name: string;
    url: string;
    isCreator?: boolean;
    img?: string;
    info?: string;
}

type KeyLetterItems = {
    [key: string]: SearchData[]
}

interface SearchState {
    searchItems: KeyLetterItems
    lastRequestId: string
    loading: boolean
    message: string
}

const initialState: SearchState = {
    searchItems: {},
    lastRequestId: '',
    loading: false,
    message: ''
}

const getSearchResults = createAsyncThunk(
    'search/getSearchResults',
    async (searchText: string) => {
        if(!searchText) throw Messages.NO_TEXT
        let searchValue = searchText && searchText.trim()
        const usersQuery = qs.stringify({
            _where: {
                _or: [{ 'profile.username_contains': searchValue }, { 'profile.fullName_contains': searchValue }] 
            },
            _limit: 5
        })
        const accessToken = store.getState().userReducer.accessToken
        const users = await apiUsers.getAll(accessToken, `?${usersQuery}`) 
        const filter = {
            status: PRODUCT_STATUS_IDS['minted'], 
            productName: { $regex: searchValue, $options: 'i' }
        }
        const sort = {
            createdAt: 'desc'
        }
        const productsQuery = new URLSearchParams({ filter:  JSON.stringify(filter), sort: JSON.stringify(sort) })
        const products = await apiProducts.getAll(accessToken, `?${productsQuery}`)
        return [...users, ...products]
    }
)

export const searchSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetSearchState () {
            return initialState
        },
        setRequestId(state, action: PayloadAction<string>) {
            state.lastRequestId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSearchResults.pending, (state, action) => {
            state.loading = true
            state.lastRequestId = action.meta.requestId
        }),
        builder.addCase(getSearchResults.rejected, (state, action) => {
            state.searchItems = {}
            state.message = ''
            state.loading = false
        })
        builder.addCase(getSearchResults.fulfilled, (state, action) => {
            if (state.lastRequestId !== action.meta.requestId) return

            state.message = ''
            let keyLetterItems:KeyLetterItems = {}
            let sortedData = action.payload.sort((itemA, itemB) => {
                let wordA = itemA.profile ? itemA.profile?.fullName : itemA.productName
                let wordB = itemB.profile ? itemB.profile?.fullName : itemB.productName
                return wordA.localeCompare(wordB)
            })

            sortedData.forEach((item) => {
                let entity:SearchData
                if (item.username) {
                    entity = {
                        id: item.id,
                        url: `/profile/${item.username}`,
                        type: item.role.name,
                        isCreator: isProfileACreator(item),
                        name: item.profile?.fullName,
                        img: item.profile?.profileImage?.url,
                        info: '@' + item.profile?.username
                    }
                } else {
                    entity = {
                        id: item.id,
                        url: `/products/${item.id}`,
                        isCreator: false,
                        type: 'product',
                        name: item.productName,
                        img: item.productImage?.url
                    }
                }

                if(keyLetterItems[entity.name[0]]) {
                    keyLetterItems[entity.name[0]].push(entity)
                } else {
                    keyLetterItems[entity.name[0]] = [entity]
                }
            })

            state.searchItems = keyLetterItems
            if (!Object.keys(keyLetterItems).length) {
                state.message = 'No results'
            }
            state.loading = false
        })
    }
})

export { getSearchResults }

export const { resetSearchState, setRequestId } = searchSlice.actions

export const searchReducer = searchSlice.reducer