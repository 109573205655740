import React from 'react'

import {
    Table as MuiTable,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TableContainer
} from '@material-ui/core'

import { useStyles } from './style'

export type TableColumn = {
    title: string
    key: string
    width?: string | number
}

export type TableRow = {
    key: string
    rowClassName?: string
    [columnKey: string]: any | undefined
}

export type BodyCellRendererProps = {
    columnKey: string
    row: TableRow
}

export type TableProps = {
    rows: Array<TableRow>
    columns: Array<TableColumn>
    bodyCellRenderer?: (props: BodyCellRendererProps) => JSX.Element
    headerCellRenderer?: (column: TableColumn) => JSX.Element
    onRowClick?: (row: TableRow) => void
    className?: string
    tableClassName?: string
    bodyRowClass?: string
    tableCellHeadClass?: string
    tableCellBodyClass?: string
}

export const Table: React.FC<TableProps> = ({
    rows,
    columns,
    bodyCellRenderer,
    headerCellRenderer,
    bodyRowClass,
    onRowClick,
    className,
    tableClassName,
    tableCellHeadClass,
    tableCellBodyClass
}): JSX.Element => {
    const classes = useStyles()

    return (
        <TableContainer className={`${classes.tableContainer} ${className}`}>
            <MuiTable stickyHeader className={`${classes.table} ${tableClassName}`}>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.key} width={column.width} className={`${classes.tableCell} ${classes.tableHeadCell} ${tableCellHeadClass}`}>
                                {headerCellRenderer
                                    ? headerCellRenderer(column)
                                    : column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.key}
                            className={`${classes.tableRow} ${bodyRowClass} ${row.rowClassName}`}
                            onClick={() => onRowClick && onRowClick(row)}
                        >
                            {columns.map((column) => {
                                return (
                                    <TableCell
                                        key={`${row.key}-${column.key}`}
                                        width={column.width}
                                        className={`${classes.tableCellBody} ${classes.tableCell} ${tableCellBodyClass}`}
                                    >
                                        {bodyCellRenderer
                                            ? bodyCellRenderer({
                                                columnKey: column.key,
                                                row
                                            })
                                            : row[column.key]}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    )
}
