import React, { useContext, useEffect, useState } from 'react'

import { Grid, Typography, Button, useTheme, useMediaQuery } from '@material-ui/core'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import { getTimeDuration, timeViewer } from 'utils/helpers'
import { EmailSubscription } from 'components/EmailSubscription/EmailSubscription'

import { useStyles } from './ProductInfo.style'

function ProductActionsNotify(props: {dropDate: Date} ): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    const matchMdUp = useMediaQuery(theme.breakpoints.up('md'))
    const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const { dropDate } = props
    const { dispatch } = useContext(ProductContext)

    const [time, setTimer] = useState(
        getTimeDuration(new Date(dropDate))
    )

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer(getTimeDuration(new Date(dropDate)))
        }, 1000)
        return () => clearInterval(timerId)
    }, [dropDate])

    const notifyHandler = () => {
        dispatch({ type: ProductActions.EMAIL_SUBSCRIPTION_MODAL_STATE, payload: true })
    }

    return (
        <React.Fragment>
            <Grid container direction="column" justifyContent="flex-end" className={classes.notifySection}>
                <Grid item className={matchSmDown ? classes.mobDropsInSection : '' }>
                    <Typography variant="h4" className={classes.dropsIn}>DROPS IN:</Typography>
                    <Typography className={classes.time}>{ timeViewer(time) }</Typography>
                </Grid>
                {
                    matchMdUp && (
                        <Grid container className={classes.notifyButtonContainer} style={{ gap: 10 }}>
                            <Button className={classes.notifyButton} variant="outlined" color="primary" onClick={notifyHandler}>
                            Notify Me
                            </Button>
                        </Grid>
                    )
                }
                {
                    matchSmDown && (
                        <Grid container style={{ gap: 10 }}>
                            <EmailSubscription
                                classNames={{
                                    container: classes.mobEmailContainer,
                                    subscribeContainer: classes.mobEmailSubContainer
                                }}
                                buttonLabel="Notify Me"
                            />
                        </Grid>
                    )
                }
            </Grid>
        </React.Fragment>
    )
}

export { ProductActionsNotify }
