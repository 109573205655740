export type UserModelType = {
    _id: string
    username: string
    profile: ProfileModelType | null
    role: UserRoleModelType | null
}

export type ProfileModelType = {
    _id: string
    fullName: string
    bio: string
    username: string
    email: string
    discord: string
    instagram: string
    twitter: string
    website: string
    coverImage: any
    profileImage: any
}
export type UserRoleModelType = {
    _id: string
    name: string
    type: string
}

export interface ProfileEntity {
    id: string
    fullName: string | undefined
    bio: string | undefined
    username: string | undefined
    email: string | undefined
    wallet: string
    coverImageUrl: string | undefined
    profileImageUrl: string | undefined
    discord: string | undefined
    instagram: string | undefined
    twitter: string | undefined
    website: string | undefined
}

export class ProfileEntity implements ProfileEntity {
    constructor (user?: UserModelType) {
        this.id = user ? user._id : ''
        this.fullName = user ? user.profile?.fullName : ''
        this.bio = user ? user.profile?.bio : ''
        this.username = user ? user.profile?.username : ''
        this.email = user ? user.profile?.email : ''
        this.wallet = user ? user.username : ''
        this.coverImageUrl = user?.profile?.coverImage?.url ? user.profile.coverImage.url : ''
        this.profileImageUrl = user?.profile?.profileImage?.url ? user.profile.profileImage.url : ''
        this.discord = user ? user.profile?.discord : ''
        this.instagram = user ? user.profile?.instagram : ''
        this.twitter = user ? user.profile?.twitter : ''
        this.website = user ? user.profile?.website : ''
    }
}