import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: '0 40px 30px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 10px 20px'
            }
        },
        link: {
            cursor: 'pointer'
        },
        previewContainer: {
            width: 'fit-content',
            maxWidth: '420px',
            borderRadius: '8px',
            boxShadow: '0 0 5px 2px rgba(0,0,0,0.1)'
        },
        productTitle: {
            marginBottom: 5,
            fontSize: 24,
            fontWeight: 700
        },
        alert: {
            marginTop: 30
        },
        infoTitle: {
            marginTop: 25
        },
        cardTitle: {
            marginTop: 40
        }
    }
})
export { useStyles }
