import React, { useState, useEffect } from 'react'

import { Grid, IconButton, TextField } from '@material-ui/core'

import { useStyles } from './QuantityCounter.style'

type QuantityCounterProps = {
    value?: number
    min?: number
    max?: number
    disabled?: boolean
    incDisabled?: boolean
    decDisabled?: boolean
    className?: string
    onChange: (value: number) => void
}

export const QuantityCounter = ({
    value = 0,
    min = 0,
    max = 100,
    disabled = false,
    incDisabled = false,
    decDisabled = false,
    className,
    onChange
}: QuantityCounterProps): JSX.Element => {
    const classes = useStyles()

    const [count, setCount] = useState(value)
    const [inputWidth, setInputWidth] = useState(30)

    const handleDecrement = () => {
        let newVal = count - 1
        setCount(newVal)
        onChange(newVal)
    }

    const handleIncrement = () => {
        let newVal = count + 1
        setCount(newVal)
        onChange(newVal)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newVal = Number(e.target.value.replace(/[^0-9.]/g, ''))

        if(newVal < min) {
            setCount(min)
            onChange(min)
        } else if (newVal > max) {
            setCount(max)
            onChange(max)
        } else {
            setCount(newVal)
            onChange(newVal)
        }
    }

    const resizeInput = () => {
        let initialWidth = 30
        let value = String(count)
        let width = value.length === 1 ? initialWidth : initialWidth + value.length * 5
        setInputWidth(width)
    }

    useEffect(() => {
        resizeInput()
    }, [count])

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            wrap="nowrap"
            className={`${classes.container} ${className}`}
        >
            <IconButton
                onClick={handleDecrement}
                disabled={decDisabled || disabled || count <= min}
                className={classes.iconButton}
            >
                -
            </IconButton>
            <TextField
                style={{ width: inputWidth }}
                disabled={disabled}
                className={`${classes.countInput} ${disabled && classes.disabledLabel}`}
                InputProps={{ disableUnderline: true }}
                value={count}
                type="number"
                onChange={handleChange}
            />
            <IconButton
                onClick={handleIncrement}
                disabled={incDisabled || disabled || count >= max}
                className={classes.iconButton}
            >
                +
            </IconButton>
        </Grid>
    )
}
