type Timer = {
    days: string,
    hours: string,
    minutes: string,
    seconds: string
}

export function shortenAddress(address: string, slashIndex = 4): string {
    const addressLength = address.length
    return (
        address.slice(0, slashIndex) +
        '...' +
        address.slice(addressLength - 4, addressLength)
    )
}

export function getTimeDuration(date: Date) {
    let timeDiff = date.getTime() - new Date().getTime()

    if (timeDiff < 0) {
        return { days: '00', hours: '00', minutes: '00', seconds: '00' }
    }

    const days = Math.floor(timeDiff / 86400000)
    timeDiff -= days * 86400000

    const hours = Math.floor(timeDiff / 3600000)
    timeDiff -= hours * 3600000

    const minutes = Math.floor(timeDiff / 60000)
    timeDiff -= minutes * 60000

    const seconds = Math.floor(timeDiff / 1000)

    const toTimeString = (val: number): string =>
        val < 10 ? `0${val}` : `${val}`

    return {
        days: toTimeString(days),
        hours: toTimeString(hours),
        minutes: toTimeString(minutes),
        seconds: toTimeString(seconds)
    }
}

export function timeViewer(time: Timer) {
    let timeViewer = ''
    if (parseInt(time.days) > 0) {
        timeViewer = time.days.padStart(2, '0')
            + ((parseInt(time.days) > 1) ? ' days' : ' day')
    } else {
        timeViewer = time.hours.padStart(2, '0')
            + ':' + time.minutes.padStart(2, '0')
            + ':' + time.seconds.padStart(2, '0')
    }

    return timeViewer
}

export function getUSD(tz: number, currency: number | undefined): number {
    if(tz && currency) {
        let usd = tz * currency

        return Math.round(usd)
    }

    return 0
}

export function getNumber(val: string) {
    let num =  val.replace(/[^\d]/g, '')
    return num.replace(/^0*(\d+)/g, '$1')
}

export function formatDate(date: string) {
    let newDate = new Date(date)
    let hours = newDate.getHours()
    let ampm = hours >= 12 ? 'PM' : 'AM'

    return `${newDate.getDate()}/${newDate.getMonth() + 1} - ${hours}:${newDate.getMinutes()}${ampm}`
}

export async function srcToFile (src: string, fileName: string, mimeType: string) {
    const file = await fetch(src)
    const buffer = await file.arrayBuffer()
    return new File([buffer], fileName, {type:mimeType})
}