import React from 'react'

import { Button, Grid } from '@material-ui/core'
import instagram from 'shared/icons/instagram_icon.svg'
import twitter from 'shared/icons/twitter_icon.svg'
import facebook from 'shared/icons/facebook_icon.svg'
import download from 'shared/icons/download_icon.svg'

import { useStyles } from './SocialIcons.style'

type SocialsType = {
    network: 'instagram' | 'twitter' | 'facebook'
    link: string
    src: string
}

const socials: SocialsType[] = [
    {
        network: 'instagram',
        link: 'https://www.instagram.com/?url=',
        src: instagram
    },
    {
        network: 'twitter',
        link: 'https://twitter.com/intent/tweet?url=',
        src: twitter
    },
    {
        network: 'facebook',
        link: 'https://www.facebook.com/sharer/sharer.php?u=',
        src: facebook
    }
]

type SocialIconsProps = {
    disabled: boolean
    imgUrl: string
    handleDownload: () => void
}

function SocialIcons({ disabled, imgUrl, handleDownload }: SocialIconsProps): JSX.Element {
    const classes = useStyles()

    const handleShare = (e: React.SyntheticEvent) => {
        e.preventDefault()
        if(disabled) return

        socials.forEach((val) => {
            if (val.network == e.currentTarget.id) {
                const url = imgUrl
                window.open(val.link + url, '_blank')
            }
        })
    }

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
        >
            <div
                className={`${classes.socials} ${
                    disabled ? 'not_active' : 'active'
                }`}
            >
                {socials.map((val, key) => (
                    <Button key={key} id={val.network} onClick={handleShare}>
                        <img src={val.src} alt={val.network} />
                    </Button>
                ))}

                <Button onClick={handleDownload}>
                    <img src={download} alt="download" />
                </Button>
            </div>
        </Grid>
    )
}

export { SocialIcons }