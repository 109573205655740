export const PRODUCT_STATUSES = {
    minted: 'minted',
    approved: 'approved',
    rejected: 'rejected',
    pendingApproval: 'pending-approval'
}

export const PRODUCT_STATUS_IDS = {
    minted: '64245ceac0b8a37663c4628f'
}

export const SECONDARY_MARKET_TYPE = 'Secondary'
export const PRIMARY_MARKET_TYPE = 'Primary'