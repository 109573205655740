import React from 'react'

import { Container, Typography, Box } from '@material-ui/core'

import { useStyles } from './AboutFlex.style'

const aboutFlexData = {
    title: 'About Flex',
    description:
        'Flex is the first marketplace for digital sneakers powered by in-house AR try-on technology allowing you to wear your digital sneakers, anywhere.'
}

function AboutFlex(): JSX.Element {
    const classes = useStyles()

    return (
        <Box className={classes.wrap}>
            <Container className={classes.container}>
                <Typography className={classes.title} variant="h3">
                    {aboutFlexData.title}
                </Typography>
                <Typography className={classes.description} variant="h2">
                    {aboutFlexData.description}
                </Typography>
            </Container>
        </Box>
    )
}

export { AboutFlex }
