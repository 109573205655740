/* eslint-disable no-console */
export type vykingArOptions  = {
    handleTakePhoto?: (props?: any) => void
    handleFeetDetection?: (feetDetected: boolean) => void
    pictureIframe?: boolean
    cameraIframe?: boolean
}

interface vykingArArgs {
    iframe: any
    loader: any
    imgFile: any
    targetPath: string
    options: vykingArOptions
    selectedAccessories: string
}

const configUri = '/modeld.bin'
const configKey = 'com.herokuapp.flex-nft'

const targetOrigin = 'https://d1ux9mupljc68q.cloudfront.net'

async function initializeVykingAR({
    iframe,
    imgFile,
    loader,
    targetPath,
    options,
    selectedAccessories
}: vykingArArgs): Promise<void> {
    const { handleTakePhoto, handleFeetDetection, pictureIframe, cameraIframe } = options
    let feetDetected = false

    async function getConfig(uri: string) {
        const response = await fetch(encodeURI(uri))
        if (!response.ok) {
            throw new Error(
                `Failed to load configuration from ${configUri}. Status: ${response.status}`
            )
        }
        return response.arrayBuffer()
    }

    function showLoader(): void {
        loader.style.visibility = 'visible'
    }
    
    function hideLoader(): void {
        loader.style.visibility = 'hidden'
    }

    function postConfig(
        config: ArrayBuffer,
        clientWidth: number,
        clientHeight: number
    ) {
        let cameraWidth = 360
        let cameraHeight = 640
        if (clientWidth > clientHeight) {
            cameraWidth = 640
            cameraHeight = 360
        }

        iframe.contentWindow.postMessage({
            type: 'VYKING_SNEAKER_WINDOW_CONFIG',
            config: config,
            key: configKey,
            cameraWidth: cameraWidth,
            cameraHeight: cameraHeight,
            autoPlay: true
        }, targetOrigin)
    }

    function replaceAccessories(uri: string) {
        iframe.contentWindow.postMessage({
            type: 'VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORIES',
            accessoryDescriptionUrl: uri
        }, targetOrigin)
    }

    
    function postIframeMessage (message: any, transfer?: any) {
        iframe.contentWindow.postMessage(message, targetOrigin, transfer)
    }
    
    function takePhoto () {
        postIframeMessage({
            type: 'VYKING_SNEAKER_WINDOW_TAKE_PHOTO',
            toDataURL: {
                type: 'image/jpeg',
                encoderOptions: 0.5
            }
        })
    }

    function startImage () {
        showLoader()
        createImageBitmap(imgFile as File)
            .then((imageBitmap: any) => {
                postIframeMessage({
                    type: 'VYKING_SNEAKER_WINDOW_START',
                    imageBitmap: imageBitmap
                }, [imageBitmap])

                imageBitmap.close()
            })
    }

    function pictureMessageHandler(event: MessageEvent, config: ArrayBuffer) {
        const { data } = event

        switch (data.type) {
            case 'VYKING_SNEAKER_WINDOW_WAITING_FOR_CONFIG':
                postConfig(config, window.innerWidth, window.innerHeight)
                break
            case 'VYKING_SNEAKER_WINDOW_EXPIRY_TIME':
                console.info(`Licence expiry date: ${data.expiryTime.toString()}`)
                if (data.expiryTime.getTime() - new Date().getTime() < 1 * 24 * 60 * 60 * 1000) {
                    alert(`Expired on ${data.expiryTime.toString()}`)
                    fetch(configUri, {
                        method: 'GET',
                        cache: 'reload'
                    })
                }
                break
            case 'VYKING_SNEAKER_WINDOW_READY':
                startImage()
                break
            case 'VYKING_SNEAKER_WINDOW_START':
                replaceAccessories(selectedAccessories)
                break
            case 'VYKING_SNEAKER_WINDOW_FINISH':
                hideLoader()
                break
            case 'VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORIES':
                if (data.complete === 1) {
                    takePhoto()
                }
                break
            case 'VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORY_TEXTURES':
                if (data.complete === 1) {
                    hideLoader()
                }
                break
            case 'VYKING_SNEAKER_WINDOW_ARE_FEET_DETECTED':
                feetDetected = true
                handleFeetDetection && handleFeetDetection(feetDetected)
                console.log(`Are feet detected: ${feetDetected}`)
                break
            case 'VYKING_SNEAKER_WINDOW_TAKE_PHOTO':
                if (!feetDetected) {
                    handleFeetDetection && handleFeetDetection(feetDetected)
                    console.log(`Are feet detected: ${feetDetected}`)
                    return
                }
                if(handleTakePhoto) {
                    handleTakePhoto(data)
                }
                hideLoader()
                break
            case 'VYKING_SNEAKER_WINDOW_BUSY_ERROR':
                hideLoader()
                break
            case 'VYKING_SNEAKER_WINDOW_ERROR':
                hideLoader()
                alert(`${data.requestType} ${data.value}`)
                break
        }
    }

    function cameraMessageHandler(event: MessageEvent, config: ArrayBuffer) {
        const { data } = event

        switch (data.type) {
            case 'VYKING_SNEAKER_WINDOW_WAITING_FOR_CONFIG':
                postConfig(config, window.innerWidth, window.innerHeight)
                break
            case 'VYKING_SNEAKER_WINDOW_EXPIRY_TIME':
                console.info(`Licence expiry date: ${data.expiryTime.toString()}`)
                if (data.expiryTime.getTime() - new Date().getTime() < 1 * 24 * 60 * 60 * 1000) {
                    alert(`Expired on ${data.expiryTime.toString()}`)
                    fetch(configUri, {
                        method: 'GET',
                        cache: 'reload'
                    })
                }
                break
            case 'VYKING_SNEAKER_WINDOW_READY':
                replaceAccessories(selectedAccessories)
                break
            case 'VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORIES':
                if (data.complete === 1) {
                    hideLoader()
                }
                break
            case 'VYKING_SNEAKER_WINDOW_BUSY_ERROR':
                hideLoader()
                break
            case 'VYKING_SNEAKER_WINDOW_ERROR':
                hideLoader()
                alert(`${data.requestType} ${data.value}`)
                break
        }
    }

    function main(config: ArrayBuffer) {
        const targetUri = targetOrigin + targetPath
        window.onmessage = null
    
        if (pictureIframe) {
            window.onmessage = (e: MessageEvent) => {
                console.log(e)
                pictureMessageHandler(e, config)
            }
        }
        if (cameraIframe) {
            window.onblur = () => {
                console.log('blur')
                pause()
            }
            window.onfocus = () => {
                console.log('focus')
                play()
            }
            replaceAccessories(selectedAccessories)
            window.onmessage = (e: MessageEvent) => {
                console.log(e)
                cameraMessageHandler(e, config)
            }
        }

        iframe.src = encodeURI(targetUri)
    }

    // Safari doesn't reload the page when navigating to it and its in the bfcache, so instead
    // we explicitly reload the page to force the camera to be reactivated.

    try {
        window.onmessage = null
        showLoader()
        const config = await getConfig(configUri)
        main(config)
    } catch (e) {
        alert(e)
        hideLoader()
    }
}

function play(): void {
    const iframe: any = document.getElementById('vyking-sneaker-window')

    iframe.contentWindow.postMessage(
        {
            type: 'VYKING_SNEAKER_WINDOW_PLAY'
        },
        targetOrigin
    )
}

function pause(): void {
    const iframe: any = document.getElementById('vyking-sneaker-window')

    iframe.contentWindow.postMessage(
        {
            type: 'VYKING_SNEAKER_WINDOW_PAUSE'
        },
        targetOrigin
    )
}

export { initializeVykingAR, play, pause }
