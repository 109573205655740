import React from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import './datePicker.css'

import DatePicker from 'react-datepicker'
import { InputAdornment, IconButton, styled } from '@material-ui/core'
import { setHours, setMinutes } from 'date-fns'
import { FormikErrors } from 'formik'
import { FlexTextField } from 'components/Form/FlexTextField'

import { ReactComponent as ClanedarIcon } from '../../../shared/icons/calendar.svg'
import { ReactComponent as NextArrowIcon } from '../../../shared/icons/nextArrow.svg'
import { ReactComponent as PrevArrowIcon } from '../../../shared/icons/prevArrow.svg'

const ArrowButton = styled(IconButton)({
    padding: 8,
    backgroundColor: '#FF6800',
    margin: 8,
    '&:hover': {
        padding: 8,
        backgroundColor: '#FF6800',
        margin: 8
    }
})

type DatePickerInputProps = {
    inputId: string
    inputName: string
    selectedValue: Date
    className: string
    isPickerOpen: boolean
    onChange: (date: Date | [Date | null, Date | null] | null, event: React.SyntheticEvent<any, Event> | undefined) => void
    onClick: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void
    error?: boolean
    errorMessage?: string | FormikErrors<Date>
}

export const DatePickerInput = ({
    selectedValue, 
    className, 
    onChange, 
    onClick,
    inputId, 
    inputName,
    isPickerOpen,
    error,
    errorMessage
}: DatePickerInputProps) => {
    return (
        <div className="date-input-wrapper">
            <FlexTextField 
                value={`${selectedValue.toLocaleString()}`}
                type="text"
                label="Drop Date" 
                subLabel="Must be at least seven days from today" 
                className={className}
                onClick={onClick}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={onClick}>
                            <ClanedarIcon />
                        </IconButton>
                    </InputAdornment>} 
                error={error}
                errorMessage={errorMessage}
            />
            {isPickerOpen && <DatePicker
                id={inputId}
                name={inputName}
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                    <div className="custom-datepicker-header">
                        {prevMonthButtonDisabled ? 
                            <div className="empty-div-for-header"/>
                            : 
                            <ArrowButton 
                                onClick={decreaseMonth} 
                                disabled={prevMonthButtonDisabled} 
                            >
                                <PrevArrowIcon />
                            </ArrowButton>
                        }
                        <div>{date.toLocaleString('default', { month: 'long' })}</div>
                        <ArrowButton 
                            onClick={increaseMonth} 
                            disabled={nextMonthButtonDisabled} 
                        >
                            <NextArrowIcon />
                        </ArrowButton>
                    </div>
                )}
                inline
                calendarStartDay={1}
                showPopperArrow={false}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="MMMM d, yyyy HH:mm"
                minTime={setHours(setMinutes(new Date(), 0), 10)}
                maxTime={setHours(setMinutes(new Date(), 0), 19)}
                minDate={new Date(new Date().setDate(new Date().getDate() + 7))}
                selected={new Date(selectedValue)}
                onChange={onChange}
            />
            }
        </div>
    )
}