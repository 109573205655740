import React from 'react'

import { Box, Typography } from '@material-ui/core'
import ReactPlayer from 'react-player'

import { useStyles } from './Video.style'

function Video(): JSX.Element {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.playerWrap}>
                <ReactPlayer
                    volume={0}
                    muted={true}
                    playing={true}
                    loop={true}
                    playsinline={true}
                    width="auto"
                    height="auto"
                    url="/puma.mp4"
                />
                <Box
                    className={classes.titleWrap}
                >
                    <Typography variant="h1" className={classes.titleText}>DIGITAL SNEAKERS YOU CAN WEAR.</Typography>
                </Box>
            </div>

        </div>
    )
}

export { Video }
