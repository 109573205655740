import axios from 'axios'

import { handleResponse, handleError } from './response'

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://127.0.0.1:1337/'

const getAll = (resource: string, token?: string, query: string = '') => {
    let config = {}
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }
    return axios
        .get(`${BASE_URL}/${resource}${query}`, config)
        .then(handleResponse)
        .catch(handleError)
}

const getSingle = (resource: string, id: string, token?: string) => {
    let config = {}
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }
    return axios
        .get(`${BASE_URL}/${resource}/${id}`, config)
        .then(handleResponse)
        .catch(handleError)
}

const post = (resource: string, model: any, token?: string) => {
    let config = {}
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }
    return axios
        .post(`${BASE_URL}/${resource}`, model, config)
        .then(handleResponse)
        .catch(handleError)
}

const put = (resource: string, model: any, token?: string) => {
    let config = {}
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }
    return axios
        .put(`${BASE_URL}/${resource}`, model, config)
        .then(handleResponse)
        .catch(handleError)
}

const patch = (resource: string, model: any, token?: string) => {
    let config = {}
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }
    return axios
        .patch(`${BASE_URL}/${resource}`, model, config)
        .then(handleResponse)
        .catch(handleError)
}

const remove = (resource: string, id: string, token?: string) => {
    let config = {}
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }
    return axios
        .delete(`${BASE_URL}/${resource}/${id}`, config)
        .then(handleResponse)
        .catch(handleError)
}

export const apiProvider = {
    getAll,
    getSingle,
    post,
    put,
    patch,
    remove
}
