import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: '8px 14px',
            border: 'solid 1px',
            borderColor: theme.custom.alert.border
        },
        closeButton: {
            cursor: 'pointer'
        }
    }
})
export { useStyles }
