import React, { useCallback, useState, useEffect, useContext } from 'react'

import { Redirect, useParams, useLocation, useHistory, Link } from 'react-router-dom'
import { Layout } from 'components/Layout'
import { Grid, Button, Typography, useMediaQuery, useTheme, Tabs, Tab, CircularProgress, Link as MuiLink } from '@material-ui/core'
import { apiProfiles } from 'services/api/profiles'
import { isProfileACreator } from 'utils/profile'
import { getActiveAccount } from 'utils/beacon-wallet'
import {
    UserModelType,
    ProfileEntity
} from 'entities/Profile.entity'
import { ProductContext } from 'context/ProductPageContext'
import {
    ProductEntity,
    ProductModelType,
    UserProductsModelType,
    UserProductsEntity
} from 'entities/Product.entity'
import { apiProducts } from 'services/api/products'
import { ProductList } from 'components/Product/ProductList'
import { ReactComponent as DiscordIcon } from 'shared/icons/discordBlack.svg'
import { ReactComponent as InstagramIcon } from 'shared/icons/instagramBlack.svg'
import { ReactComponent as TwitterIcon } from 'shared/icons/twitterBlack.svg'
import { ReactComponent as WebsiteIcon } from 'shared/icons/websiteBlack.svg'
import { Avatar } from 'components/Avatar'
import { useAppSelector } from 'hooks/useStoreHooks'
import Copy from 'shared/icons/copy.svg'

import { useStyles } from './Profile.style'

type SocialNetwork = {
    name: 'instagram' | 'twitter' | 'discord' | 'website'
    icon: JSX.Element
}

const socialNetworks: SocialNetwork[] = [
    {
        name: 'instagram',
        icon: <InstagramIcon />
    },
    {
        name: 'twitter',
        icon: <TwitterIcon />
    },
    {
        name: 'discord',
        icon: <DiscordIcon />
    },
    {
        name: 'website',
        icon: <WebsiteIcon />
    }
]

enum TabsTitles {
    CREATED = 'CREATED',
    ON_SALE = 'ON SALE',
    OWNED = 'OWNED',
    SUBMITTED = 'SUBMITTED'
}

function Profile(): JSX.Element {

    const { id } = useParams<{ id: string }>()
    let { search } = useLocation()
    const query = new URLSearchParams(search)
    const activeTab = query.get('tab')
    
    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const { state, dispatch } = useContext(ProductContext)
    const { accessToken } = useAppSelector(state => state.userReducer)
    
    const [profileData, setProfileData] = useState<ProfileEntity>(new ProfileEntity())
    const [error, setError] = useState('')
    const [loading, setLoading] = useState<boolean>(true)
    const [isMyProfile, setIsMyProfile] = useState<boolean>(false)
    const [isCreator, setIsCreator] = useState<boolean>(false)
    const [tab, setTab] = useState<string>(TabsTitles.ON_SALE)
    const [userProducts, setUserProducts] = useState<UserProductsEntity>()
    const [copied, setCopied] = useState<boolean>(false)
    const [isShown, setIsShown] = useState<boolean>(false)
    
    const fetchProfileData = useCallback(async () => {
        try {
            const userData:UserModelType = await apiProfiles.getSingle(id, accessToken)
            const profile:ProfileEntity = new ProfileEntity(userData)
            setIsCreator(isProfileACreator(userData))
            setProfileData(profile)
            const activeAccount = await getActiveAccount()
            setIsMyProfile(activeAccount.address === profile.wallet)
            
        } catch (error) {
            setError(error as string)
        }
    }, [id])

    const fetchUserProducts = useCallback(async () => {
        setLoading(true)
        await apiProducts.getProductsByUser(accessToken, profileData.id).then((products: UserProductsModelType) => {
            const { created, submitted, onSale, owned } = products
            setUserProducts({
                created: created ? created.map((product: ProductModelType) => new ProductEntity(product)) : null,
                onSale: onSale ? onSale.map((product: ProductModelType) => new ProductEntity(product)) : null,
                owned: owned ? owned.map((product: ProductModelType) => new ProductEntity(product)) : null,
                submitted: submitted ? submitted.map((product: ProductModelType) => new ProductEntity(product)) : null
            })
        })
        setLoading(false)
    }, [profileData])

    const redirectToActiveTab = useCallback( () => {
        if (activeTab && Object.keys(TabsTitles).includes(activeTab.toUpperCase())) {
            switch (activeTab) {
                case 'on_sale': 
                    userProducts?.onSale && setTab((TabsTitles as any)[activeTab.toUpperCase()])
                    break
                default: 
                    userProducts && (userProducts as any)[activeTab] && setTab((TabsTitles as any)[activeTab.toUpperCase()])     
            }
        }
    }, [activeTab, userProducts])

    const handleTabChange = (event: any, newTab: TabsTitles) => {
        setTab(newTab)
    }

    const handleWallet = (e: any) => {
        navigator.clipboard.writeText(e.target.getAttribute('data-wallet'))
        setCopied(true)
    }

    useEffect(() => {
        fetchProfileData()
    }, [id, fetchProfileData])

    useEffect(() => {
        if(!profileData.wallet) return
        if (!state.removeSaleModalState) fetchUserProducts()
    }, [profileData.wallet, fetchUserProducts, state.removeSaleModalState])

    useEffect(() => {
        redirectToActiveTab()
    }, [userProducts, redirectToActiveTab])

    return (
        <Layout>
            <Grid className={classes.container}>
                {error && (
                    <Redirect to="/404" />
                )}
                <Grid className={classes.profileImgSection}>
                    <div className={classes.coverImage} style={{ backgroundImage: `url('${profileData.coverImageUrl === '' ? '/defaultCoverImage.png' : profileData.coverImageUrl}')` }} />
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="flex-end"
                        className={classes.profileImage}
                    >
                        <Avatar alt="Avatar Image" src={profileData.profileImageUrl} badgeClassName={classes.avatarBadge} className={classes.avatarImg} creator={isCreator} />
                    </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center" className={classes.profileDataSection}>

                    { !matches && isMyProfile && (
                        <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.redirectButtons}>
                            { isCreator && (
                                <Button component={Link} to="/uploading" className={classes.redirectBtn} variant="outlined" color="primary">
                                Upload a sneaker
                                </Button>
                            ) }
                            <Button
                                component={Link}
                                to={{ pathname: `/profile/${profileData.wallet}/edit`, state: { profileData } }}
                                className={classes.redirectBtn}
                                variant="outlined"
                                color="primary">
                            Edit profile
                            </Button>
                        </Grid>
                    ) }
                    <Typography variant="h3" className={classes.fullName}>{profileData.fullName}</Typography>
                    { profileData.username && <Typography className={classes.userName}>@{profileData.username}</Typography> }
                    { profileData.wallet &&
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.walletContainer}
                                onClick={handleWallet}
                                onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => {setIsShown(false); setCopied(false)}}
                            >
                                <Typography className={classes.wallet} data-wallet={profileData.wallet}>
                                    {profileData.wallet.substr(0, 4) + '...' + profileData.wallet.substr(-4, 4)}
                                </Typography>
                                <img src={Copy} data-wallet={profileData.wallet} />
                                {isShown && (
                                    <Grid item className={classes.copyIconContainer}>
                                        <span className={classes.delta} data-wallet={profileData.wallet}>&#9668;</span>
                                        <span className={classes.copyIcon} data-wallet={profileData.wallet}>
                                        {copied ? 'Copied!' : 'Copy'}
                                    </span>
                                    </Grid>
                                )}
                            </Grid>
                    }
                    <Typography className={classes.bio}>{profileData.bio}</Typography>
                    <Grid container justifyContent="center" className={classes.socialButtons}>
                        {socialNetworks.map((network, index) => {
                            return profileData[network.name] ? (
                                <MuiLink 
                                    key={`${network.name}_${index}`} 
                                    href={profileData[network.name]} 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    {network.icon}
                                </MuiLink>) : null
                        })}
                    </Grid>
                </Grid>
                { loading ? (
                    <div>
                        <CircularProgress className={classes.loadingErrorContainer} size={60}/>
                    </div>
                ) : (
                    <Grid container justifyContent="center" className={classes.productSection}>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            textColor="primary"
                            className={classes.tabs}
                            TabIndicatorProps={{
                                style: {
                                    display: 'none'
                                }
                            }}
                        >
                            {Object.values(TabsTitles).map(tab => {
                                switch(tab) {
                                    case TabsTitles.CREATED:
                                        return userProducts?.created && (
                                            <Tab key={tab} label={tab} value={tab} className={classes.tab} disableRipple={true} />
                                        )
                                    case TabsTitles.SUBMITTED:
                                        return userProducts?.submitted && (
                                            <Tab key={tab} label={tab} value={tab} className={classes.tab} disableRipple={true} />
                                        )
                                    default:
                                        return (
                                            <Tab key={tab} label={tab} value={tab} className={classes.tab} disableRipple={true} />
                                        )
                                }
                            })}
                        </Tabs>
                        <div className={classes.line}></div>
                        {tab === TabsTitles.CREATED && userProducts?.created && <ProductList products={userProducts.created} />}
                        {tab === TabsTitles.ON_SALE && userProducts?.onSale && <ProductList products={userProducts.onSale} onSale={isMyProfile} />}
                        {tab === TabsTitles.ON_SALE && !userProducts?.onSale && (
                            <Grid>
                                <Typography variant="body1" align="center" className={classes.text}>
                                    {isMyProfile ? (
                                        'It looks like you do not have any sneakers for sale. '
                                        + 'On Flex, as soon you purchase a digital sneaker from any creator you can '
                                        + 'resell it to other Flex users.'
                                    ) : (
                                        'This user has no tokens to display in this tab.'
                                    )}
                                </Typography>
                            </Grid>
                        )}
                        {tab === TabsTitles.OWNED && userProducts?.owned && <ProductList products={userProducts.owned} />}
                        {tab === TabsTitles.OWNED && !userProducts?.owned && (
                            <Grid>
                                <Typography variant="body1" align="center" className={classes.text}>
                                    {isMyProfile ? (
                                        'It looks like you haven’t purchased any sneakers yet. '
                                        + 'It feels kind of empty here. To solve that hit that ‘Go shopping’ '
                                        + 'button and start flexin’.'
                                    ) : (
                                        'This user has no tokens to display in this tab.'
                                    )}
                                </Typography>
                                {isMyProfile && (
                                    <Button
                                        className={classes.actionBtn}
                                        component={Link}
                                        to="/"
                                        variant="contained"
                                    >
                                        Go shopping
                                    </Button>
                                )}
                            </Grid>
                        ) }
                        {tab === TabsTitles.SUBMITTED && userProducts?.submitted && <ProductList products={userProducts.submitted} />}
                    </Grid>
                )}
            </Grid>
        </Layout>
    )
}

export { Profile }
