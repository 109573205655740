import React, { useContext, useState } from 'react'

import { Typography, Grid } from '@material-ui/core'
import { ProductPreview } from 'components/Product/ProductPreview'
import { RemoveSaleModal } from 'components/Product/RemoveSaleModal'
import { ProductEntity } from 'entities/Product.entity'
import { ProductActions, ProductContext } from 'context/ProductPageContext'

import { useStyles } from './ProductList.style'

type ProductListProps = {
    products: ProductEntity[],
    title?: string,
    onSale?: boolean
}

function ProductList({ products = [], title, onSale }: ProductListProps): JSX.Element {
    const classes = useStyles()
    const [saleProduct, setSaleProduct] = useState<ProductEntity>(new ProductEntity())
    const { dispatch } = useContext(ProductContext)

    const onRemoveSaleOpen = (product: ProductEntity) => {
        dispatch({ type: ProductActions.REMOVE_SALE_MODAL_STATE, payload: true })
        setSaleProduct(product)
    }

    const productPreviews = products.map((product: ProductEntity) => (
        <div key={product.id} className={classes.previewContainer}>
            <ProductPreview key={product.id} productData={product} onSale={onSale} onRemoveSaleOpen={onRemoveSaleOpen}/>
        </div>
    ))

    return (
        <Grid
            container
            justifyContent="flex-start"
            className={classes.container}
        >
            {title && <Typography className={classes.title}>{title}</Typography>}
            {productPreviews}
            <RemoveSaleModal product={saleProduct}/>
        </Grid>
    )
}

export { ProductList }
