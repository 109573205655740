import axios from 'axios'

import { ApiCore } from '../utilities'
import { handleResponse, handleError } from '../utilities/response'

const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://127.0.0.1:1337/'

const url = 'orders'
const plural = 'orders'
const single = 'order'

class ApiOrders extends ApiCore {
    isOrderActive: any
}

const apiOrders = new ApiOrders({
    allowGetAll: true,
    allowGetSingle: true,
    allowPost: true,
    allowPut: false,
    allowPatch: false,
    allowRemove: false,
    url: url,
    plural: plural,
    single: single
})

apiOrders.isOrderActive = (token: string, orderId: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    return axios
        .get(`${BASE_URL}/${url}/isActive/${orderId}`, config)
        .then(handleResponse)
        .catch(handleError)
}


export { apiOrders }
