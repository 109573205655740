import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            minHeight: 76,
            margin: '0 auto',
            maxWidth: 1440,
            height: '100%',
            padding: '0 15px',
            [theme.breakpoints.down('xs')]: {
                minHeight: 54
            }
        },
        leftCol: {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-between'
            }
        },
        logoWrap: {
            width: 73,
            marginRight: '10px'
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            marginRight: 32
        },
        linkText: {
            '&:hover': {
                fontWeight: 700
            }
        },
        activeLinkText: {
            fontWeight: 700
        },
        logoImg: {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        },
        searchButton: {
            width: 35,
            height: 35,
            padding: 0
        },
        searchIcon: {
            fill: theme.palette.primary.main
        },
        navContainer: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobileNav: {
            position: 'absolute',
            top: '100%',
            backgroundColor: theme.palette.secondary.main,
            left: 0,
            width: '100%',
            padding: '0 0 20px',
            textAlign: 'center'
        },
        buttons: {
            display: 'flex',
            alignItems: 'center'
        },
        menuButton: {
            display: 'none',
            marginLeft: '20px',
            [theme.breakpoints.down('sm')]: {
                display: 'flex'
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                width: '30px',
                height: '2px',
                top: '5px',
                backgroundColor: theme.palette.primary.main,
                transition: 'all .2s ease'
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                width: '30px',
                height: '2px',
                bottom: '5px',
                backgroundColor: theme.palette.primary.main,
                transition: 'all .2s ease'
            },
            '&.active': {
                '&::before': {
                    transform: 'rotate(45deg)',
                    top: '11px'
                },
                '&::after': {
                    transform: 'rotate(-45deg)',
                    bottom: '11px'
                }
            }
        }
    }
})
export { useStyles }
