import React from 'react'

import { PRODUCT_STATUSES } from 'constants/product'

import { Box, Typography } from '@material-ui/core'

import { useStyles } from './Status.style'

type StatusProps = {
    productStatus: string
    mintingStatus: string | undefined
}

function Status({ productStatus, mintingStatus }: StatusProps): JSX.Element {
    const classes = useStyles()

    function generateStatusMessage () {
        switch(productStatus) {
            case PRODUCT_STATUSES.approved: {
                return <>
                    <Typography variant="subtitle1" className={classes.approved}>
                        {!!mintingStatus && `Minting status: ${mintingStatus}`}
                    </Typography></>
            }
            case PRODUCT_STATUSES.rejected: {
                return <>
                    <Typography variant="h4" className={classes.rejected}>
                        {'Rejected -'}
                        <Typography component="span" variant="subtitle1" className={classes.rejected}> 
                            {' Get in touch with our team to understand why.'}
                        </Typography>
                    </Typography></>
            }
            case PRODUCT_STATUSES.pendingApproval: {
                return <>
                    <Typography variant="h4" className={classes.pending}>
                        {'Pending -'}
                        <Typography component="span" variant="subtitle1" className={classes.pending}> 
                            {' Your creative is still under review.'}
                        </Typography>
                    </Typography></>
            }
            case PRODUCT_STATUSES.minted: {
                return <><Typography variant="subtitle1"></Typography></>
            }
        }
    }

    return (
        <Box>
            {generateStatusMessage()}
        </Box>
    )
}

export { Status }
