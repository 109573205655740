import React, { useEffect, useState, useCallback } from 'react'

import { Redirect, useParams, useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { apiProducts } from 'services/api/products'
import { apiTokens } from 'services/api/tokens'
import { Layout } from 'components/Layout'
import { 
    ProductEntity,
    ProductModelType
} from 'entities/Product.entity'
import { TokenModelType } from 'entities/Token.entity'
import { useAppSelector } from 'hooks/useStoreHooks'

import { ProductView } from './ProductView'
import { useStyles } from './Product.style'

function Product({ preview, onlyTestSoldOut }: { preview?: boolean, onlyTestSoldOut?: boolean }): JSX.Element {
    const classes = useStyles() 
    const { id } = useParams<{ id: string }>()
    const { productPreview } = useAppSelector(state => state.productReducer)
    const { accessToken } = useAppSelector(state => state.userReducer)

    const [loading, setLoading] = useState<boolean>(false)
    const [productData, setProductData] = useState<ProductEntity>(new ProductEntity())
    const [tokensForSale, setTokensForSale] = useState<TokenModelType[]>([])
    const [error, setError] = useState('')
    const history = useHistory()

    let data = productData

    const fetchProductData = useCallback(async () => {
        setLoading(true)
        try {
            const productModel: ProductModelType = await apiProducts.getSingle(id, accessToken)
            setProductData(new ProductEntity(productModel))
        } catch (error) {
            setError(error as string)
        } finally {
            setLoading(false)
        }
    }, [id])

    const fetchTokensForSale = useCallback(async () => {
        try {
            const tokensPrimary: TokenModelType[] = await apiTokens.getAll(null, `?product=${id}&marketType=Primary`)
            const tokensSecondary: TokenModelType[] = await apiTokens.getAll(null, `?product=${id}&_sort=price:ASC&marketType=Secondary`)
            const tokens = [...tokensPrimary, ...tokensSecondary]

            setTokensForSale(tokens)
            console.log({ tokens })
        } catch (error) {
            setError(error as string)
        }
    }, [id])

    useEffect(() => {
        if (!id && !productPreview.id) {
            history.push('/uploading')
        }

        if (preview) {
            setProductData(productPreview)
        } else {
            fetchProductData()
            fetchTokensForSale()
        }

        data = onlyTestSoldOut ? { ...productData, count: 0 } : productData
    }, [id])

    return (
        <Layout>
            {loading ? (
                <div>
                    <CircularProgress className={classes.loadingErrorContainer} size={60}/>
                </div>
            ) : error ? (
                <Redirect to="/404" />
            ) : (
                <ProductView productData={data} preview={preview || false} tokens={tokensForSale} />
            )}
        </Layout>
    )
}

export { Product }
