import React from 'react'

import { Switch, Route } from 'react-router-dom'
import { Home } from 'screens/Home'
import { Product } from 'screens/Product'
import { UploadingSneaker } from 'screens/UploadingSneaker'
import { EditProfile } from 'screens/EditProfile'
import { ArCamera } from 'components/ArCamera'
import { Creators} from 'screens/Creators'
import { DropsPage } from 'screens/Drops'
import { NotFound } from 'screens/NotFound'
import { Profile } from 'screens/Profile'
import { Dressing } from 'screens/Dressing'
import { SiteInfo } from 'screens/SiteInfo'

function Routes(): JSX.Element {

    return (
        <Switch>
            <Route path={'/creators'}>
                <Creators />
            </Route>
            <Route exact path={'/profile/:id'}>
                <Profile />
            </Route>
            <Route exact path={'/profile/:id/edit'}>
                <EditProfile /> 
            </Route>           
            <Route path={'/dressing'}>
                <Dressing />
            </Route>
            <Route path={'/uploading'}>
                <UploadingSneaker />
            </Route>
            <Route path={'/drops'}>
                <DropsPage />
            </Route>
            <Route path={'/privacy-policy'}>
                <SiteInfo kind="PRIVACY" />
            </Route>
            <Route path={'/terms-of-use'}>
                <SiteInfo kind="TERMS" />
            </Route>
            <Route path={'/about'}>
                <SiteInfo kind="ABOUT" />
            </Route>
            <Route path={'/products/:id'}>
                <Product />
            </Route>
            <Route path={'/preview'}>
                <Product preview />
            </Route>
            <Route path={'/sold-out/:id'}>
                <Product onlyTestSoldOut />
            </Route>
            <Route path={'/ar-camera/:product_id'}>
                <ArCamera options={{ cameraIframe: true }} />
            </Route>
            <Route exact path={'/'}>
                <Home />
            </Route>
            <Route path={'*'}>
                <NotFound />
            </Route>
        </Switch>
    )
}

export { Routes }
