/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useEffect, useState } from 'react'

import { Typography, InputAdornment, Snackbar, Button } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { Layout } from 'components/Layout'
import { profileFormSchema, ProfileFormProps } from 'utils/schemas/profile'
import { FlexTextField } from 'components/Form/FlexTextField'
import { SocialNetworkInput } from 'components/Form/SocialNetworkInput'
import { AdornedButton } from 'components/Button'
import { apiProfiles } from 'services/api/profiles'
import { Dropzone } from 'components/Upload/Dropzone'
import { Avatar } from 'components/Avatar'
import { useConnectedAccount } from 'hooks/useConnectedAccount'
import { LoginInfo } from 'kukai-embed'
import { srcToFile } from 'utils/helpers'
import { useAppSelector, useAppDispatch } from 'hooks/useStoreHooks'
import { setUser } from 'store/user'

import { ReactComponent as DiscordIcon } from '../../shared/icons/discordBlack.svg'
import { ReactComponent as InstagramIcon } from '../../shared/icons/instagramBlack.svg'
import { ReactComponent as TwitterIcon } from '../../shared/icons/twitterBlack.svg'
import { ReactComponent as WebsiteIcon } from '../../shared/icons/websiteBlack.svg'

import { useStyles } from './EditProfile.style'

const MAX_FILE_SIZE = 4194304 // ~4mb

export const EditProfile = () => {
    const classes = useStyles()

    const history = useHistory()

    const { user, accessToken } = useAppSelector(state => state.userReducer)
    const appDispatch = useAppDispatch()
    const account = useConnectedAccount()

    const [bioLength, setBioLength] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [avatarPath, setAvatarPath] = useState<string>('')
    const [coverPath, setCoverPath] = useState<string>('')

    const formik = useFormik<ProfileFormProps>({
        initialValues: {
            avatarPicture: user?.profile?.profileImage?.url ?? '',
            coverPicture: user?.profile?.coverImage?.url ?? '',
            name: user?.profile?.fullName ?? '',
            username: user?.username ?? '',
            email: user?.profile?.email ?? '',
            bio: user?.profile?.bio ?? '',
            instagramUrl: user?.profile?.instagram ?? '',
            twitterUrl: user?.profile?.twitter ?? '',
            website: user?.profile?.website ?? '',
            discordUrl: user?.profile?.discord ?? ''
        },
        validationSchema: profileFormSchema,
        onSubmit: (values) => {
            saveUserProfileData(values)
        }
    })

    const handleDropAvatar = (acceptedFiles: File[]) => {
        formik.setFieldValue('avatarPicture', acceptedFiles[0])
        setAvatarPath(URL.createObjectURL(acceptedFiles[0]))
    }

    const removeAvatar = () => {
        formik.setFieldValue('avatarPicture', '')
        setAvatarPath('')
    }

    const handleDropCover = (acceptedFiles: File[]) => {
        formik.setFieldValue('coverPicture', acceptedFiles[0])
        setCoverPath(URL.createObjectURL(acceptedFiles[0]))
    }

    const removeCover = () => {
        formik.setFieldValue('coverPicture', '')
        setCoverPath('')
    }

    const handleBioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBioLength(e.target.value.length)
        formik.setFieldValue('bio', e.target.value)
    }

    const handleSnackbarClose = () => {
        setErrorMessage('')
    }

    const handleCancelClick = () => {
        history.goBack()
    }

    const saveUserProfileData = async (profileData: ProfileFormProps) => {
        setLoading(true)
        try {
            const formData = new FormData()
            if (profileData.avatarPicture !== '') {
                formData.append('files.profileImage', profileData.avatarPicture)
            }
            if (profileData.coverPicture !== '') {
                formData.append('files.coverImage', profileData.coverPicture)
            }

            formData.append('data', 
                JSON.stringify({
                    instagram: profileData.instagramUrl,
                    discord: profileData.discordUrl,
                    twitter: profileData.twitterUrl,
                    website: profileData.website,
                    fullName: profileData.name,
                    bio: profileData.bio,
                    email: profileData.email,
                    username: profileData.username || user?.username
                })
            )
            await apiProfiles.updateMe({token: accessToken, data: formData})
            const updatedProfileData = await apiProfiles.getMe({ token: accessToken })
            appDispatch(setUser(updatedProfileData))
            history.push(`/profile/${user?.username}`)
        } catch (error: any) {
            setErrorMessage(error.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setAvatarPath(formik.initialValues.avatarPicture)
        setCoverPath(formik.initialValues.coverPicture)

        if((account?.wallet as LoginInfo)?.userData) {
            let { userData }: any = account?.wallet as LoginInfo
            let fetchFile = async () => {
                if(userData.profileImage) {
                    let avatarFile = await srcToFile(userData.profileImage, 'profileImage', 'image/*')
                    setAvatarPath(userData.profileImage)
                    formik.setFieldValue('avatarPicture', avatarFile)
                }
            }
            fetchFile()
            userData.name && formik.setFieldValue('name', userData.name, false)
            userData.email && formik.setFieldValue('email', userData.email, false)
        }
    }, [account])

    return (
        <Layout>
            <div className={classes.container}>
                <Typography className={classes.profileFormTitle}>Edit your profile</Typography>
                <Typography className={classes.subtitle}>First step to flexing’</Typography>
                <form className={classes.formContainer} onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className={classes.inputContainer}>
                        <Typography variant="h3">Profile picture</Typography>
                        <Typography variant="body2" className={classes.uploadPictureSubtitle}>Must be no larger than 4 MB.</Typography>
                        <Dropzone
                            accept="image/*"
                            noClick={false}
                            className={classes.dropZoneAvatarContainer}
                            handleReset={removeAvatar}
                            handleDrop={handleDropAvatar}
                            dropAccepted={!!avatarPath}
                            maxSize={MAX_FILE_SIZE}
                            profileView
                        >
                            <Avatar alt="Avatar Image" src={avatarPath} className={classes.uploadedAvatarImg} />
                        </Dropzone>
                    </div>
                    <div className={classes.inputContainer}>
                        <Typography variant="h3">Cover picture</Typography>
                        <Typography variant="body2" className={classes.uploadPictureSubtitle}>Must be no larger than 4 MB.</Typography>
                        <Dropzone
                            accept="image/*"
                            className={classes.dropZoneCoverContainer}
                            handleReset={removeCover}
                            handleDrop={handleDropCover}
                            maxSize={MAX_FILE_SIZE}
                            dropAccepted={!!coverPath}
                        >
                            <img src={coverPath} className={classes.uploadedCoverImg} />
                        </Dropzone>
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="name"
                            name="name"
                            className={classes.inputStyle}
                            label="Full name / Organization*"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="email"
                            name="email"
                            className={classes.inputStyle}
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                    Boolean(formik.errors.email)
                            }
                            errorMessage={
                                (formik.touched.email && formik.errors.email) ?
                                    formik.errors.email : undefined
                            }
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="username"
                            name="username"
                            className={classes.inputStyle}
                            label="Username*"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            startAdornment={<InputAdornment position="start">@</InputAdornment>}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="bio"
                            name="bio"
                            className={classes.inputStyle}
                            label="Bio*"
                            value={formik.values.bio}
                            onChange={handleBioChange}
                            inputHelperText={`${bioLength}/240`}
                            isHelperTextInTheEnd
                            error={
                                formik.touched.bio &&
                                    Boolean(formik.errors.bio)
                            }
                            errorMessage={
                                (formik.touched.bio && formik.errors.bio) ?
                                    formik.errors.bio : undefined
                            }
                        />
                    </div>
                    <div className={classes.socailSection}>
                        <Typography className={classes.socialLabel}>Social Links</Typography>
                        <div className={classes.socialInputContainer}>
                            <SocialNetworkInput 
                                socialNetworkIcon={<InstagramIcon />}
                                id="instagramUrl"
                                name="instagramUrl"
                                className={classes.socialInputStyle}
                                value={formik.values.instagramUrl}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className={classes.socialInputContainer}>
                            <SocialNetworkInput 
                                socialNetworkIcon={<TwitterIcon />}
                                id="twitterUrl"
                                name="twitterUrl"
                                className={classes.socialInputStyle}
                                value={formik.values.twitterUrl}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className={classes.socialInputContainer}>
                            <SocialNetworkInput 
                                socialNetworkIcon={<WebsiteIcon />}
                                id="website"
                                name="website"
                                className={classes.socialInputStyle}
                                value={formik.values.website}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className={classes.socialInputContainer}>
                            <SocialNetworkInput 
                                socialNetworkIcon={<DiscordIcon />}
                                id="discordUrl"
                                name="discordUrl"
                                className={classes.socialInputStyle}
                                value={formik.values.discordUrl}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className={classes.buttonsSection}>
                        <Button
                            variant="outlined"
                            onClick={handleCancelClick}
                            className={classes.cancelButton}
                        >
                            {'Cancel'}
                        </Button>
                        <AdornedButton
                            type="submit"
                            variant="contained"
                            className={classes.saveButton}
                            loading={loading}
                            spinnerSize={15}
                            disabled={loading}
                        >
                            {'Save'}
                        </AdornedButton>
                    </div>
                </form>
                <Snackbar
                    open={!!errorMessage}
                    onClose={handleSnackbarClose}
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert severity="error" elevation={6} variant="filled">{errorMessage}</Alert>
                </Snackbar>
            </div>
        </Layout>
    )
}