import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import { ProfileEntity } from 'entities/Profile.entity'

import { useStyles } from './CreatorPreview.style'

function CreatorPreview({creatorData}: {creatorData: ProfileEntity}): JSX.Element {
    const classes = useStyles()

    return (
        <Grid container className={classes.container}>
            <div className={classes.creatorCover} style={{ backgroundImage: `url('${creatorData.coverImageUrl}')`}}></div>
            <Grid
                container
                justifyContent="center"
                alignItems="center"    
            >
                <div className={classes.profileImage} style={{ backgroundImage: `url('${creatorData.profileImageUrl}')` }}></div>
            </Grid>
            <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.creatorName}
            >
                <Typography variant="h3">{creatorData.fullName}</Typography>
                <Typography variant="subtitle1">@{creatorData.username}</Typography>
            </Grid>
        </Grid>
    )
}

export { CreatorPreview }
