import React from 'react'

import { Grid, Typography, Link } from '@material-ui/core'

import { useStyles } from './TransactionStatus.style'

type TransactionStatusProps = {
    status: string | undefined,
    hash: string | undefined
}

export const TransactionStatus = ({status, hash}: TransactionStatusProps): JSX.Element => {
    const classes = useStyles()

    return (
        <>
            <Grid container justifyContent="space-between" className={classes.topBox}>
                <div>
                    <Typography variant="h4" className={classes.infoTitle}>STATUS</Typography>
                    <Typography variant="subtitle2" className={classes.info_desc}>{status}</Typography>
                </div>
            </Grid>
            <Grid container direction="column" justifyContent="space-between" className={classes.hashBox}>
                <Typography variant="h4" className={classes.infoTitle}>TRANSACTION HASH</Typography>
                <Link className={classes.hash} variant="subtitle2" href={`https://hangzhou2net.tzkt.io/${hash}`} target="_blank">{hash}</Link>
            </Grid>
        </>
    )
}
