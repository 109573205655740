import React from 'react'

import { Badge, Avatar as MUIAvatar } from '@material-ui/core'
import CreatorIcon from 'shared/icons/creator.svg'

type AvatarProps = {
    badgeClassName?: string
    className?: string
    creator?: boolean
    src?: string
    alt: string
}

function Avatar({ badgeClassName, className, creator, src, alt }: AvatarProps): JSX.Element {
    return (
        <Badge
            className={badgeClassName}
            overlap="circular"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            badgeContent={creator ? <img src={CreatorIcon} /> : ''}
        >
            <MUIAvatar className={className} alt={alt} src={src ? src : '/defaultProfileImage.png'} />
        </Badge>
    )
}

export { Avatar }
