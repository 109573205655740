import React, { useEffect } from 'react'

import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { mobileTheme, desktopTheme } from 'shared/theme'
import { Provider as StoreProvider } from 'react-redux'
import { store } from 'store'
import { apiProfiles } from 'services/api/profiles'
import { setAccessToken, setUser } from 'store/user'
import { ProductContextProvider } from 'context/ProductPageContext'
import _ from 'lodash'
import { saveState } from 'store/browser-storage'
import { useConnectedAccount } from 'hooks/useConnectedAccount'

import { Routes } from './Routes'

import './App.css'


store.subscribe(
    _.debounce(() => {
        saveState(store.getState())
    }, 800)
)

function App(): JSX.Element {
    const matchSmUp = useMediaQuery(desktopTheme.breakpoints.up('sm'))
    const theme = matchSmUp ? desktopTheme : mobileTheme

    const { user } = store.getState().userReducer
    const activeAccountData = useConnectedAccount()
    const appDispatch = store.dispatch

    const initAccount = async () => {
        if(!user && activeAccountData?.address) {
            const user = await apiProfiles.beaconAuth(activeAccountData)
            appDispatch(setAccessToken(user.jwt))

            const userProfile = await apiProfiles.getMe({ token: user.jwt })
            appDispatch(setUser(userProfile))
        } 
    }
    
    useEffect(() => {
        if(!user) {
            initAccount()
        }
    }, [user])


    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <StoreProvider store={store}>
                    <ProductContextProvider>
                        <BrowserRouter>
                            <Routes />
                        </BrowserRouter>
                    </ProductContextProvider>
                </StoreProvider>
            </ThemeProvider>
        </React.StrictMode>
    )
}

export { App }
