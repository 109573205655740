import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        prodInfo: {
            '&.prodInfoSmall':{},
            width: '265px',
            height: '120px',
            overflow: 'hidden',
            marginBottom: '14px',
            border: '1px solid rgba(213, 213, 213, 0.5)',
            borderRadius: '10px'
        },
        prodTitle: {
            padding: '8px 12px 2px',
            borderBottom: '1px solid #FF6800',
            '& h3': {
                fontSize: '22px',
                lineHeight: '25px',
                color: theme.palette.primary.main
            }
        },
        qr: {
            borderRight: '1px solid #FF6800'
        },
        data: {
            width: '68%',
            padding: '1px 8px 6px',
            boxSizing: 'border-box',
            '& span': {
                fontSize: '9px',
                lineHeight: '10px',
                fontWeight: '700',
                color: theme.palette.primary.main
            },
            '& h4': {
                fontSize: '16px',
                lineHeight: '18px',
                fontWeight: '700',
                color: theme.palette.primary.main
            }
        },
        designer: {
            width: '100%',
            textAlign: 'left'
        },
        bottomBox: {
            textAlign: 'left'
        },
        price: {
            maxWidth: '145px',
            minWidth: '72px',
            '& h4': {
                textTransform: 'none',
                '& i': {
                    fontStyle: 'normal',
                    fontSize: '20px',
                    lineHeight: '16px',
                    fontWeight: '400'
                },
                '& b': {
                    color: theme.custom.common.lightGray
                }
            }
        }
    }
})

export { useStyles }
