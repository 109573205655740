import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            maxWidth: 750,
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        subscribeContainer: {
            position: 'relative',
            maxWidth: 700,
            margin: '24px auto 0',
            gap: 10,
            [theme.breakpoints.down('xs')]: {
                marginTop: 40,
                gap: 11,
                flexDirection: 'column'
            }
        },
        inputSubscribe: {
            height: 58,
            color: '#000',
            background: '#fff',
            ...theme.typography.body1,
            '&:hover, &:focus, &:active, :not(:focus)': {
                background: '#fff'
            }
        },
        inputLabelSubscribe: {
            width: 'inherit',
            boxSizing: 'border-box',
            lineHeight: '24px',
            padding: '20px 16px 16px 2px',
            color: theme.custom.emailSubscriptions.inputLabelText,
            transform: 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            ...theme.typography.body1
        },
        inputHelpText: {
            position: 'absolute',
            left: 0,
            bottom: -30,
            fontSize: 18,
            fontWeight: 400,
            [theme.breakpoints.down('xs')]: {
                bottom: 'initial',
                top: -7,
                fontSize: 16
            }
        },
        buttonSubscribe: {
            width: 'auto',
            backgroundColor: theme.custom.emailSubscriptions.buttonBackground,
            textTransform: 'none',
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        buttonTextSubscribe: {
            lineHeight: '24.1px'
        },
        successMessage: {
            padding: '44px 0 15px',
            color: theme.custom.emailSubscriptions.successMessageColor
        }
    }
})
export { useStyles }
