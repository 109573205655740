import {
    NetworkType,
    PermissionResponseOutput,
    TezosOperationType
} from '@airgap/beacon-sdk'
import { TezosToolkit } from '@taquito/taquito'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { KukaiEmbed, Networks, LoginInfo, TypeOfLogin  } from 'kukai-embed'
import { AccountEntity, SignInProvider } from 'entities/Account.entity'

import { generate_receipt_payload, sign_well_formed_payload } from './tezPay'

const RPC_URL = process.env.REACT_APP_RPC_URL ? process.env.REACT_APP_RPC_URL : 'https://rpc.hangzhounet.teztnets.xyz'
const RPC_TYPE: string = process.env.REACT_APP_RPC_TYPE ? process.env.REACT_APP_RPC_TYPE : 'HANGZHOUNET'

const Tezos = new TezosToolkit(RPC_URL)
const wallet = new BeaconWallet({ name: 'Flex' })
const embed = new KukaiEmbed({ net: Networks.hangzhounet })

Tezos.setWalletProvider(wallet)

async function getActiveAccount(): Promise<AccountEntity> {
    await initKukaiEmbed()
    if(embed.user) {
        return new AccountEntity(embed.user)
    } else {
        let beaconAccount = await wallet.client.getActiveAccount()
        return new AccountEntity(beaconAccount)
    }
}

async function initKukaiEmbed () {
    if(!embed.initialized) {
        await embed.init()
    }
}

async function loginKukaiEmbed () {
    await initKukaiEmbed()
    return await embed.login({ loginOptions: [TypeOfLogin.Facebook, TypeOfLogin.Google, TypeOfLogin.Twitter]})
}

async function loginExtension () {
    return await wallet.client.requestPermissions({
        network: { type: NetworkType[RPC_TYPE] }
    })
}

async function requestPermissions(provider: SignInProvider): Promise<LoginInfo | PermissionResponseOutput | undefined> {
    try {
        if(provider === SignInProvider.EXTENSION) {
            return await loginExtension()
        } else {
            return await loginKukaiEmbed()
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return
    }
}

async function disconnectWallet(): Promise<void> {
    if (embed.user) {
        await embed.logout()
        sessionStorage.clear()
    } else {
        await wallet.clearActiveAccount()
        localStorage.clear()
    }
}

async function getAccountBalance(address: string): Promise<any> {
    const balance = (await Tezos.tz.getBalance(address)).toNumber() / 1000000
    return balance
}

async function transferTez(amount: number, to?: string ): Promise<any> {
    // @TODO - remove wallet address default constant
    const toAddress = to ? to : process.env.REACT_APP_FLEX_WALLET ? process.env.REACT_APP_FLEX_WALLET : 'tz1SMmHA4ckCeYEgff1ALiPu7CiNHBrspWd5'
    try {
        if(embed.user) {
            let res = await embed.send([{
                kind: TezosOperationType.TRANSACTION,
                destination: toAddress,
                amount: `${amount * 1000000}` 
            }])

            return {
                opHash: res
            }
        } else {
            return await Tezos.wallet.transfer({
                to: toAddress,
                amount
            }).send()
        }
    } catch (error: any) {
        console.log('[transferTez]', error.message)
        if (error.message.includes('ABORTED')) {
            throw 'Oops! Transaction was rejected. Retry to buy again.'
        } else {
            throw 'Something happened with connection to your wallet, please try to disconnect and connect your wallet again.'
        }
    }
}

async function generateAndSignReceipt(amount: number, paymentId: string, opHash: string): Promise<any> {
    const receiptPayload = generate_receipt_payload({
        dapp_url: window.origin,
        tez_amount: amount,
        message: `Payment id: ${paymentId}`,
        opg_hash: opHash
    })

    let response = embed.user ? 
        await sign_well_formed_payload(receiptPayload, embed, SignInProvider.WEB_KUKAI) : 
        await sign_well_formed_payload(receiptPayload, wallet.client, SignInProvider.EXTENSION)

    return response
}

export {
    embed,
    Tezos,
    getActiveAccount,
    requestPermissions,
    disconnectWallet,
    getAccountBalance,
    transferTez,
    generateAndSignReceipt
}
