import React, { useContext, useState } from 'react'

import { Grid, Typography, Button, Link, CircularProgress, Box } from '@material-ui/core'
import { Modal } from 'components/Modal'
import { ConnectWalletModule } from 'components/ConnectWalletModule'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import Kukai from 'shared/icons/kukai.svg'
import {
    getActiveAccount,
    requestPermissions
} from 'utils/beacon-wallet'
import { apiProfiles } from 'services/api/profiles'
import { SignInProvider } from 'entities/Account.entity'
import { setUser, setAccessToken } from 'store/user'
import { useAppDispatch } from 'hooks/useStoreHooks'
import { useHistory } from 'react-router-dom'

import { useStyles } from './SignInModal.style'

enum StatusMessage {
    WALLET_CONNECTED = 'Your account will be loaded soon, you can close this window.',
    CONNECT_WALLET = 'Follow the steps to connect your wallet.'
}

export const SignInModal = (): JSX.Element => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<StatusMessage>()
    
    const appDispatch = useAppDispatch()

    const { state, dispatch } = useContext(ProductContext)

    const onModalsClose = () => {
        setLoading(false)
        setMessage(undefined)
        dispatch({ type: ProductActions.SIGNIN_MODAL_STATE, payload: false })
    }

    const connect = async (provider: SignInProvider) => {
        setLoading(true)
        
        try {
            // STEP 1: Wallet connection
            setMessage(StatusMessage.CONNECT_WALLET)
            await requestPermissions(provider)
            const activeAccountData = await getActiveAccount()
            if(activeAccountData.address) {
                setMessage(StatusMessage.WALLET_CONNECTED)
            }

            // STEP 2: Fetching user data
            const user = await apiProfiles.beaconAuth(activeAccountData)
            appDispatch(setAccessToken(user.jwt))
            const userProfile = await apiProfiles.getMe({ token: user.jwt })
            appDispatch(setUser(userProfile))

            // STEP 3: Finish registration
            if(!userProfile.profile) {
                history.push(`/profile/${userProfile.username}`)
            }
            
            onModalsClose()
        } catch {
            setLoading(false)
        }
    }

    return (
        <Modal
            width={600}
            height={270}
            open={state.signInModalState}
            title=''
            onClose={onModalsClose}
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                {loading ? 
                    <>
                        <Grid container alignItems="center" direction="column" wrap="nowrap">
                            <CircularProgress size={60} />
                            {message && <Typography className={classes.message}>{message}</Typography>}
                        </Grid>
                    </>
                    :
                    <>
                        <Typography className={classes.signInTitle} variant="h3">Sign In</Typography>
                        <div className={classes.signInDesc}>
                            <Typography>Welcome to flex. </Typography>
                            <Typography>
                                Let’s get you sorted with a crypto wallet.
                                It’s easy don’t worry. There will be a pop-up appearing, just
                                follow the steps and come back here.
                            </Typography>
                        </div>
                        <Grid container justifyContent="center" className={classes.bottonsContainer}>
                            <Grid
                                container
                                direction="column"
                                className={classes.signInBottonContainer}
                            >
                                <Button onClick={() => connect(SignInProvider.WEB_KUKAI)} className={classes.signInBottons + ' ' + classes.socialSignIn}>
                            SOCIAL SIGN-IN
                                </Button>
                                <Typography className={classes.kukai}>Powered by <img src={Kukai}/></Typography>
                            </Grid>
                            <ConnectWalletModule onConnect={() => connect(SignInProvider.EXTENSION)}/>
                        </Grid>
                        <Link className={classes.walletsInfo} href='#'>Learn more about wallets</Link>
                    </>
                }
            </Grid>

        </Modal>
    )
}
