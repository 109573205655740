import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserModelType } from 'entities/Profile.entity'

interface UserState {
    user: UserModelType | undefined
    accessToken: string
}

const initialState: UserState = {
    user: undefined,
    accessToken: ''
}

export const userSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setUser (state, action: PayloadAction<UserModelType | undefined>) {
            state.user = action.payload
        },
        setAccessToken(state, action: PayloadAction<string>) {
            state.accessToken = action.payload
        },
        resetUser () {
            return initialState
        }
    }
})

export const { setUser, setAccessToken, resetUser } = userSlice.actions

export const userReducer = userSlice.reducer