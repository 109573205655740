import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            width: 'fit-content',
            height: 32,
            background: theme.custom.quantityCounter.background
        },
        iconButton: {
            width: 32,
            height: '100%',
            borderRadius: 0
        },
        countInput: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            boxSizing: 'border-box',
            background: theme.palette.secondary.main,
            border: 'solid 1px',
            borderColor: theme.custom.quantityCounter.background,
            padding: '0 5px',
            '& input': {
                padding: 0,
                color: theme.custom.quantityCounter.text,
                textAlign: 'center'
            }
        },
        disabledLabel: {
            opacity: 0.5
        }
    }
})

export { useStyles }
