import axios from 'axios'

import { handleResponse, handleError } from '../utilities/response'

const API_COINBASE_URL = process.env.API_COINBASE_URL
    ? process.env.API_COINBASE_URL
    : 'https://api.coinbase.com/v2/'

const EXCHANGE_RATES = 'exchange-rates'

const getCurrencies = (currency: string = 'XTZ') => {
    return axios
        .get(`${API_COINBASE_URL}${EXCHANGE_RATES}?currency=${currency}`)
        .then(handleResponse)
        .catch(handleError)
}

export const apiCoinbase = {
    getCurrencies
}
