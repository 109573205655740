import { apiProvider } from './provider'
type APICoreOptions = {
    allowGetAll: boolean
    allowGetSingle: boolean
    allowPost: boolean
    allowPut: boolean
    allowPatch: boolean
    allowRemove: boolean
    url: string
    plural: string
    single: string
}
export class ApiCore {
    getAll: any
    getSingle: any
    post: any
    put: any
    patch: any
    remove: any
    constructor(options: APICoreOptions) {
        this.getAll = (token?: string, query?: string) => {
            return options.allowGetAll
                ? apiProvider.getAll(options.url, token, query)
                : 'Not Allowed'
        }

        this.getSingle = (id: string, token?: string) => {
            return options.allowGetSingle
                ? apiProvider.getSingle(options.url, id, token)
                : 'Not Allowed'
        }

        this.post = (token: string, model: any) => {
            return options.allowPost
                ? apiProvider.post(options.url, model, token)
                : 'Not Allowed'
        }

        this.put = (model: any) => {
            return options.allowPut
                ? apiProvider.put(options.url, model)
                : 'Not Allowed'
        }

        this.patch = (model: any) => {
            return options.allowPatch
                ? apiProvider.patch(options.url, model)
                : 'Not Allowed'
        }

        this.remove = (token: string, id: string) => {
            return options.allowRemove
                ? apiProvider.remove(options.url, id, token)
                : 'Not Allowed'
        }
    }
}
