import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        modalContainer: {
            background: theme.palette.secondary.main
        },
        modalContentWrapper: {
            padding: '20px 30px'
        },
        closeButton: {
            cursor: 'pointer'
        },
        emailSubscriptionContainer: {
            '& h1': {
                paddingTop: '60px',
                fontSize: '36px',
                lineHeight: '41px',
                fontWeight: 700
            }
        },
        subscribeContainer: {
            maxWidth: 550
        },
        emailHeading: {
            fontWeight: 700,
            textTransform: 'uppercase',
            fontStyle: 'italic'
        }
    }
})

export { useStyles }
