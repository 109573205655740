import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            maxWidth: '1440px',
            margin: '0 auto',
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        profileImgSection: {
            position: 'relative'
        },
        coverImage: {
            width: '100%',
            height: '315px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                height: '160px'
            }
        },
        profileImage: {
            position: 'absolute',
            top: 74,
            left: 0,
            width: '100%',
            height: '100%',
            [theme.breakpoints.down('xs')]: {
                top: 46
            }
        },
        avatarImg: {
            width: '148px',
            height: '148px',
            border: `5px solid ${theme.custom.profilePage.profileImageBorder}`,
            borderRadius: '80px',
            [theme.breakpoints.down('xs')]: {
                width: '100px',
                height: '100px'
            }
        },
        avatarBadge: {
            '& > span': {
                right: '15%',
                bottom: '20%'
            }
        },
        profileImageHolder: {
            position: 'relative',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '148px',
            height: '148px',
            marginBottom: '-74px',
            border: `5px solid ${theme.custom.profilePage.profileImageBorder}`,
            borderRadius: '80px',
            [theme.breakpoints.down('xs')]: {
                width: '100px',
                height: '100px',
                marginBottom: '-46px'
            }
        },
        creatorIcon: {
            position: 'absolute',
            width: '26px',
            height: '26px',
            right: '4px',
            bottom: '16px',
            [theme.breakpoints.down('xs')]: {
                right: '0px',
                bottom: '6px'
            }
        },
        profileDataSection: {
            position: 'relative',
            padding: '0 20px',
            marginBottom: 20,
            [theme.breakpoints.down('xs')]: {
                padding: '0 10px',
                marginBottom: 10
            }
        },
        redirectButtons: {
            position: 'absolute',
            marginTop: 15,
            right: 20,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                marginTop: 15,
                right: 10
            }
        },
        redirectBtn: {
            width: 130,
            padding: 12,
            color: theme.palette.primary.main,
            borderColor: theme.custom.profilePage.actionButtonsBorderColor,
            textTransform: 'none',
            lineHeight: 'normal',
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.custom.profilePage.actionButtonsBackgroundColor,
                borderColor: theme.custom.profilePage.actionButtonsBorderColor
            },
            '&:last-child': {
                marginLeft: 20
            },
            [theme.breakpoints.down('sm')]: {
                padding: 10,
                '&:last-child': {
                    marginLeft: 0,
                    marginTop: 10
                }
            }
        },
        actionBtn: {
            textTransform: 'none'
        },
        fullName: {
            marginTop: 97,
            fontSize: '60px',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                marginTop: 55,
                fontSize: '30px'
            }
        },
        userName: {
            fontSize: '21px',
            textAlign: 'center',
            color: theme.custom.profilePage.userNameColor,
            [theme.breakpoints.down('xs')]: {
                fontSize: '12px'
            }
        },
        walletContainer: {
            position: 'relative',
            width: '150px',
            padding: '10px 0 5px',
            cursor: 'pointer',
            '& img': {
                width: '12px',
                height: '13px',
                paddingLeft: '6px'
            }
        },
        wallet: {
            color: theme.custom.common.lightGray,
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '10px'
            }
        },
        copyIconContainer: {
            position: 'absolute',
            top: '10px',
            left: '94px',
            marginLeft: '4px',
            [theme.breakpoints.down('xs')]: {
                left: '85px',
                fontSize: '10px'
            }
        },
        delta: {
            position: 'absolute',
            top: '6px',
            left: '0',
            fontSize: '8px',
            [theme.breakpoints.down('xs')]: {
                top: '3px',
            }
        },
        copyIcon: {
            marginLeft: '6px',
            padding: '6px 12px',
            fontSize: '13px',
            backgroundColor: theme.custom.common.black,
            color: theme.custom.common.white
        },
        bio: {
            marginTop: '10px',
            fontSize: '21px',
            lineHeight: '24px',
            textTransform: 'capitalize',
            textAlign: 'center',
            color: theme.custom.profilePage.bioColor,
            [theme.breakpoints.down('xs')]: {
                width: '150px',
                marginTop: '6px',
                fontSize: '12px',
                lineHeight: '13px'
            }
        },
        socialButtons:{
            marginTop: '26px',
            [theme.breakpoints.down('xs')]: {
                marginTop: '17px'
            },
            '& a': {
                margin: '4px',
                [theme.breakpoints.down('xs')]: {
                    margin: '2px'
                },
                '& img': {
                    [theme.breakpoints.down('xs')]: {
                        width: '20px',
                        height: '20px'
                    }
                }
            }
        },
        productSection: {
            padding: '55px 0 40px',
            [theme.breakpoints.down('xs')]: {
                padding: '7px 0 23px'
            }
        },
        tabs: {
            minHeight: '30px'
        },
        tab: {
            minWidth: 'auto',
            margin: '0 20px',
            padding: '6px 0 15px',
            fontSize: '30px',
            lineHeight: '34px',
            fontWeight: 700,
            color: '#CCC',
            '&.Mui-selected': {
                borderBottom: `4px solid ${theme.custom.profilePage.tabBorderBottom}`,
                [theme.breakpoints.down('xs')]: {
                    borderBottom: `2px solid ${theme.custom.profilePage.tabBorderBottom}`
                }
            },
            [theme.breakpoints.down('xs')]: {
                minHeight: 'auto',
                margin: '0 12px',
                padding: '6px 0 5px',
                fontSize: '15px',
                lineHeight: '17px'
            }
        },
        line: {
            width: '100%',
            height: '1px',
            boxSizing: 'border-box',
            margin: '0 56px',
            backgroundColor: theme.custom.profilePage.lineBackgroundColor,
            [theme.breakpoints.down('xs')]: {
                margin: '0 10px 10px'
            }
        },
        text: {
            color: theme.custom.profilePage.actionButtonsBorderColor,
            maxWidth: '453px',
            margin: '24px 0 40px',
            [theme.breakpoints.down('xs')]: {
                fontSize: 14
            }
        },
        loadingErrorContainer: {
            maxWidth: '1440px',
            margin: '100px auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
})
export { useStyles }
