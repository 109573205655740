import React, { useEffect, useState } from 'react'

import { PRODUCT_STATUS_IDS } from 'constants/product'

import { Box, Typography, useMediaQuery, useTheme, CircularProgress, Grid } from '@material-ui/core'
import { Layout } from 'components/Layout'
import { EmailSubscription } from 'components/EmailSubscription'
import { AboutFlex } from 'components/AboutFlex'
import { 
    ProductEntity,
    ProductModelType
} from 'entities/Product.entity'
import { apiProducts } from 'services/api/products'
import { ProductList } from 'components/Product/ProductList'
import { useAppSelector } from 'hooks/useStoreHooks'

import { Video } from './Video'
import { useStyles } from './Home.style'

function Home(): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()

    const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const [justDroppedProducts, setJustDroppedProducts] = useState<ProductEntity[]>()
    const { accessToken } = useAppSelector(state => state.userReducer)

    const fetchAllProducts = async function () {
        const nowDate = new Date(Date.now())
        const filter = {
            status: PRODUCT_STATUS_IDS['minted'], 
            dropDate: { $lt: nowDate.toISOString() }
        }
        const sort = {
            createdAt: 'desc'
        }
        const qs = new URLSearchParams({ filter:  JSON.stringify(filter), sort: JSON.stringify(sort) })
        const products: ProductModelType[] = await apiProducts.getAll(accessToken, `?${qs}`)
        setJustDroppedProducts(
            products.map((product: ProductModelType) => new ProductEntity(product))
        )
    
    }
    useEffect(() => {
        fetchAllProducts()
    }, [])

    return (
        <Layout>
            <Box>
                <Video />
                {justDroppedProducts ? <ProductList products={justDroppedProducts} /> : (
                    <Grid container justifyContent="center" className={classes.loadingContainer}>
                        <CircularProgress size={60}/>
                    </Grid>
                )}
                <Box className={classes.wrapper}>
                    <EmailSubscription>
                        <Typography variant="h1" className={classes.emailHeading}>
                            Always Flex. {matchSmDown && (<br />)} Never miss a Drop.
                        </Typography>
                    </EmailSubscription>
                </Box>
                <AboutFlex />
            </Box>
        </Layout>
    )
}

export { Home }
