import { configureStore } from '@reduxjs/toolkit'

import {loadState} from './browser-storage'
import { productReducer } from './product'
import { searchReducer } from './search'
import { userReducer } from './user'

export const store = configureStore({
    reducer: { productReducer, searchReducer, userReducer },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    preloadedState: loadState()
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch