import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingTop: 110,
            paddingBottom: 55,
            [theme.breakpoints.down('xs')]: {
                paddingTop: 55,
                paddingBottom: 25
            }
        },
        hugeCaption: {
            marginTop: 80,
            marginBottom: 50,
            fontSize: 96,
            lineHeight: '1.1',
            fontWeight: 700,
            [theme.breakpoints.down('xs')]: {
                marginTop: 45,
                marginBottom: 30,
                fontSize: 60
            }
        },
        title: {
            fontSize: 60,
            fontWeight: 700,
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
                fontSize: 45
            }
        },
        date: {
            color: '#D0D0D0', // b4dPotato: Will be remove, now only static pages.
            fontSize: 24,
            fontWeight: 700,
            marginBottom: 80,
            [theme.breakpoints.down('xs')]: {
                fontSize: 18,
                marginBottom: 40
            }
        },
        articleTitle: {
            marginTop: 30,
            color: theme.custom.common.orange,
            fontSize: 36,
            fontWeight: 700,
            [theme.breakpoints.down('xs')]: {
                fontSize: 26,
                marginBottom: 15
            }
        },
        infoTitle: {
            marginTop: 20,
            fontSize: 22,
            fontWeight: 700,
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginBottom: 10
            }
        },
        infoText: {
            fontSize: 22,
            [theme.breakpoints.down('xs')]: {
                fontSize: 16
            }
        },
        spaceContainer: {
            '&>*': {
                marginTop: 20
            },
            [theme.breakpoints.down('xs')]: {
                '&>*': {
                    marginTop: 15
                }
            }
        }
    }
})
