import { SigningType } from '@airgap/beacon-sdk'
import { char2Bytes } from '@taquito/utils'
import { SignInProvider } from 'entities/Account.entity'

interface ReceiptPayload {
    dapp_url: string
    tez_amount: number
    receiver_address?: string
    message: string
    opg_hash: string
} 

function generate_receipt_payload({ dapp_url, tez_amount, receiver_address, message, opg_hash }: ReceiptPayload) {
    // @TODO - remove wallet address default constant
    const toAddress = receiver_address ? receiver_address : process.env.REACT_APP_FLEX_WALLET ? process.env.REACT_APP_FLEX_WALLET : 'tz1SMmHA4ckCeYEgff1ALiPu7CiNHBrspWd5'
    let receipt = `Tezos Signed Message: ${dapp_url} ${new Date().toISOString()} Payment slip for ${tez_amount} tez to ${toAddress} with message '${message}' and on-chain locator ${opg_hash}`
    console.log(receipt)
    let receipt_hex = char2Bytes(receipt)
    let receipt_length_hex = (~~(receipt_hex.length / 2)).toString(16).padStart(8, '0')
    let payload = '0501' + receipt_length_hex + receipt_hex
    return payload
}

async function sign_well_formed_payload(payload: any, signer: any, signerType: SignInProvider) {
    let account, response

    switch(signerType) {
        case SignInProvider.EXTENSION: {
            account = await signer.getActiveAccount()
            response = await signer.requestSignPayload({
                signingType: SigningType.MICHELINE,
                payload
            })

            return response?.signature ? {
                payload,
                signature: response.signature,
                public_key: account.publicKey
            } : null
        }
        case SignInProvider.WEB_KUKAI: {
            response = await signer.signExpr(payload)

            return response ? {
                payload,
                signature: response,
                public_key: signer.user.pk
            } : null
        }
    }
};

export {
    generate_receipt_payload,
    sign_well_formed_payload
}