import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
    return {
        dropsContainer: {
            maxWidth: '1440px',
            margin: '0 auto',
            [theme.breakpoints.down('xs')]: {
                marginTop: '30px'
            }
        },
        loadingContainer: {
            margin: '100px auto'
        },
        dropMonthLabel: {
            paddingLeft: '40px',
            fontSize: 36,
            fontWeight: 700,
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '20px',
                fontSize: 28
            }
        },
        wrapper: {
            width: '100%',
            padding: '94px 0 180px',
            background: theme.custom.emailSubscriptions.background,
            boxSizing: 'border-box',
            [theme.breakpoints.down('xs')]: {
                padding: '30px 32px 80px'
            }
        }
    }
})
