import React, { useRef, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Typography, Grid, CircularProgress } from '@material-ui/core'
import { initializeVykingAR, play, pause } from 'services/api/vyking-ar'
import {
    ProductEntity,
    ProductModelType
} from 'entities/Product.entity'
import { apiProducts } from 'services/api/products'

import { useStyles } from './ArCamera.style'

type ArCameraProps = {
    className?: string
    productId?: string
    imgFile?: any
    options?: any
}

function ArCamera({ productId, imgFile, options, className }: ArCameraProps): JSX.Element {
    const sneakerId = useParams<{ product_id: string }>().product_id || productId || ''
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://flex-nft-api.herokuapp.com'
    const selectedAccessories = `${apiUrl}/products/${sneakerId}/ar/offsets.json`
    const classes = useStyles()
    const history = useHistory()

    const iframe = useRef<HTMLIFrameElement>(null)
    const loader = useRef<HTMLDivElement>(null)

    const [productData, setProductData] = useState<ProductEntity>(new ProductEntity())
    const { cameraIframe } = options

    const fetchData = async (id: string) => {
        apiProducts.getSingle(id).then((product: ProductModelType) => {
            setProductData(new ProductEntity(product))
        })
    }

    useEffect(() => {
        fetchData(sneakerId).catch((error: string) => {
            console.log(error)
            history.replace({ pathname: '/' })
        })

        initializeVykingAR({
            loader: loader.current,
            iframe: iframe.current,
            targetPath: cameraIframe ? '/1/index.html' : '/flex/2/v2.0.0-23-g228f80d/index.html',
            selectedAccessories,
            imgFile,
            options
        })

        return () => {
            window.onpageshow = null
            window.onmessage = null
            window.onblur = null
            window.onfocus = null
        }
    }, [history, selectedAccessories])

    const backHandler = () => {
        history.push({ pathname: `/products/${sneakerId}` })
    }

    return (
        <div className={`${classes.arCamera} ${className}`} onClick={cameraIframe && play}>
            {cameraIframe && <Grid container justifyContent="center" className={classes.header}>
                <button onClick={backHandler} className={classes.backBtn}>
                    <ArrowBackIosIcon color="primary" fontSize="large" />
                </button>
                <Typography className={classes.itemName}>
                    {productData.title}
                </Typography>
            </Grid>}

            <section className={cameraIframe ? classes.camera : classes.picture }>
                <iframe
                    ref={iframe}
                    className="vyking-sneaker-window"
                    id="vyking-sneaker-window"
                    title="Vyking Sneakerkit Window"
                    allow="camera"
                ></iframe>
            </section>

            <div ref={loader} className={classes.loaderWrp}>
                <CircularProgress color="primary" size={50} />
            </div>
                
            {cameraIframe && <button onClick={pause} className={classes.takePictureBtn} />}
        </div>
    )
}

export { ArCamera }
