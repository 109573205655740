import { createTheme } from '@material-ui/core/styles'

import { typography, palette, overrides, custom } from './commonTheme'

const desktopTheme = createTheme({
    typography,
    palette,
    overrides,
    custom
})

export { desktopTheme }
