import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        productPreviewModal: {
            width: '380px',
            height: '450px',
            margin: '25px 0 0 0',
            padding: '0px 10px 32px',
            boxSizing: 'border-box',
            border: `1px solid ${theme.custom.buyModal.productPreviewBorder}`,
            borderRadius: 0
        },
        buySection: {
            width: '275px',
            paddingLeft: '54px',
            boxSizing: 'border-box'
        },
        errorMsg: {
            wordBreak: 'keep-all',
            maxWidth: '100%',
            marginTop: 30,
            color: theme.custom.common.red
        }
    }
})

export { useStyles }
