import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        table: {
            borderCollapse: 'separate',
            borderSpacing: '0 13px'
        },
        tableContainer: {
            height: 400
        },
        tableHeadCell: {
            backgroundColor: theme.custom.table.stickyHeader
        },
        tableCell: {
            textAlign: 'center',
            '&:first-child': {
                textAlign: 'left'
            },
            '&:last-child': {
                textAlign: 'right'
            }
        },
        tableCellBody: {
            '&:last-child div': {
                float: 'right'
            }
        },
        tableRow: {
            '& td:first-child': {
                borderLeft: `1px solid ${theme.custom.table.rowBorder}`
            },
            '& td:last-child': {
                borderRight: `1px solid ${theme.custom.table.rowBorder}`
            }
        }
    }
})

export { useStyles }
