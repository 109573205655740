import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        topBox: {
            paddingBottom: 70
        },
        infoTitle: {
            paddingBottom: 5,
            fontSize: 20
        },
        info_desc: {
            paddingTop: 3,
            paddingRight: 10,
            fontSize: 12,
            lineHeight: '14px',
            color: theme.custom.buyModal.balanceColor
        }
    }
})

export { useStyles }
