import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            width: '100%',
            maxWidth: 1130,
            margin: '0 auto',
            gap: 260,
            padding: '80px 40px 110px',
            [theme.breakpoints.down('md')]: {
                gap: 150
            },
            [theme.breakpoints.down('sm')]: {
                gap: 70,
                padding: '73px 25px 200px'
            },
            [theme.breakpoints.down('xs')]: {
                gap: 70,
                flexWrap: 'wrap',
                padding: '60px 25px'
            }
        },
        footerCopyright: {
            height: '100%',
            alignItems: 'flex-start',
            [theme.breakpoints.down('xs')]: {
                alignItems: 'center'
            }
        },
        footerBox: {
            [theme.breakpoints.down('xs')]: {
                maxWidth: 400,
                gap: 20
            }
        },
        navMenu: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 14,
            gap: 12
        },
        linkText: {
            color: theme.palette.secondary.main
        },
        link: {
            textDecoration: 'none'
        },
        title: {
            color: theme.palette.secondary.main,
            textTransform: 'uppercase',
            opacity: 0.5
        },
        copyright: {
            color: theme.palette.secondary.main,
            marginTop: 30,
            whiteSpace: 'nowrap'
        },
        logoWrap: {
            width: 120,
            [theme.breakpoints.down('xs')]: {
                width: 97
            }
        },
        logoImg: {
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            marginTop: 30,
            filter: 'brightness(0) invert(1)'
        }
    }
})
export { useStyles }
