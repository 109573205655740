import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        table: {
            marginTop: 20
        },
        tableCellHeader: {
            color: theme.custom.purchaseModal.tableHeaderLabel
        },
        tableDataCell: {
            textTransform: 'uppercase'
        },
        buyButton: {
            justifySelf: 'end',
            width: '200px',
            height: '40px',
            marginTop: 30
        },
        buyError: {
            marginTop: 30,
            marginRight: 30,
            color: theme.custom.common.red,
            '& a': {
                color: theme.custom.common.red,
                textDecoration: 'underline',
                '&:hover': {
                    textDecoration: 'none'
                }
            }
        }
    }
})

export { useStyles }
