import React, { useMemo, useReducer } from 'react'

import { OrderModelType } from 'entities/Order.entity' 
import { PaymentTransactionModelType } from 'entities/PaymentTransaction.entity' 

const initialState = {
    isImageVisible: true,
    isWearSectionVisible: false,
    isWearPhoto: '',
    photoUploaded: false,
    percent: 0,
    purchaseModalState: false,
    buyModalState: false,
    buyModalOrder: null,
    buyModalPayment: null,
    sellModalState: false,
    removeSaleModalState: false,
    pricingHelperModalState: false,
    emailSubscriptionModalState: false,
    signInModalState: false,
    moonpayModalState: false
}

export enum ProductActions {
    IMAGE_VISIBLE_STATE = 'imageVisibleState',
    WEAR_SECTION_VISIBLE_STATE = 'wearSectionVisibleState',
    WEAR_PHOTO_STATE = 'wearPhotoState',
    PHOTO_UPLOADED_STATE = 'photoUploadedState',
    PERCENT_STATE = 'percentState',
    PURCHASE_MODAL_STATE = 'purchaseModalState',
    BUY_MODAL_STATE = 'buyModalState',
    BUY_MODAL_ORDER = 'buyModalOrder',
    BUY_MODAL_PAYMENT = 'buyModalPayment',
    SELL_MODAL_STATE = 'sellModalState',
    REMOVE_SALE_MODAL_STATE = 'removeSaleModalState',
    PRICING_HELPER_MODAL_STATE = 'pricingHelperModalState',
    EMAIL_SUBSCRIPTION_MODAL_STATE = 'emailSubscriptionModalState',
    SIGNIN_MODAL_STATE = 'signInModalState',
    MOONPAY_MODAL_STATE = 'moonpayModalState'
}

interface ProductContextProps {
    state: ReducerStateProps
    dispatch: React.Dispatch<ReducerActionProps>
}

export const ProductContext = React.createContext<ProductContextProps>({
    state: initialState,
    dispatch: ({}: ReducerActionProps) => undefined
})

interface ProviderProps {
    children: React.ReactNode
}

interface ReducerStateProps {
    isImageVisible: boolean
    isWearSectionVisible: boolean
    isWearPhoto: string
    photoUploaded: boolean
    percent: number
    purchaseModalState: boolean
    buyModalState: boolean
    sellModalState: boolean
    removeSaleModalState: boolean
    pricingHelperModalState: boolean
    emailSubscriptionModalState: boolean
    buyModalOrder: OrderModelType | null
    buyModalPayment: PaymentTransactionModelType | null
    signInModalState: boolean
    moonpayModalState: boolean
}

interface ReducerActionProps {
    type: ProductActions
    payload?: any
}

function reducer(state: ReducerStateProps, action: ReducerActionProps) {
    switch (action.type) {
        case 'imageVisibleState':
            return { ...state, isImageVisible: !state.isImageVisible }
        case 'wearSectionVisibleState':
            return {
                ...state,
                isWearSectionVisible: !state.isWearSectionVisible
            }
        case 'wearPhotoState':
            return {
                ...state,
                isWearPhoto: action.payload ? action.payload : ''
            }
        case 'photoUploadedState':
            return { ...state, photoUploaded: action.payload }
        case 'percentState':
            return {
                ...state,
                percent: action.payload !== 0 ? action.payload : 0
            }
        case 'purchaseModalState':
            return {
                ...state,
                purchaseModalState: action.payload
            }
        case 'buyModalState':
            return {
                ...state,
                buyModalState: action.payload
            }
        case 'buyModalOrder':
            return {
                ...state,
                buyModalOrder: action.payload
            }
        case 'buyModalPayment':
            return {
                ...state,
                buyModalPayment: action.payload
            }    
        case 'sellModalState':
            return {
                ...state,
                sellModalState: action.payload
            }
        case 'removeSaleModalState':
            return {
                ...state,
                removeSaleModalState: action.payload
            }
        case 'pricingHelperModalState':
            return {
                ...state,
                pricingHelperModalState: action.payload
            }
        case 'emailSubscriptionModalState':
            return {
                ...state,
                emailSubscriptionModalState: action.payload
            }
        case 'signInModalState':
            return {
                ...state,
                signInModalState: action.payload
            }
        case 'moonpayModalState':
            return {
                ...state,
                moonpayModalState: action.payload
            }
        default:
            return state
    }
}

function ProductContextProvider(props: ProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(reducer, initialState)

    const providerValue = useMemo(
        () => ({
            state,
            dispatch
        }),
        [state]
    )

    return (
        <ProductContext.Provider value={providerValue}>
            {props.children}
        </ProductContext.Provider>
    )
}

export { ProductContextProvider }
