import React, { useCallback, useEffect, useState, useRef } from 'react'

import { Redirect } from 'react-router'
import download from 'downloadjs'
import * as htmlToImage from 'html-to-image'
import { Grid, Typography, Button, Box, Link, useTheme, useMediaQuery } from '@material-ui/core'
import { Layout } from 'components/Layout'
import { SocialIcons } from 'components/SocialIcons'
import { Dropzone } from 'components/Upload/Dropzone'
import { apiProducts } from 'services/api/products'
import { apiProfileGalleries } from 'services/api/profileGalleries'
import { Scrollbar } from 'react-scrollbars-custom'
import { UserProductsModelType } from 'entities/Product.entity'
import { ProductEntity, ProductModelType } from 'entities/Product.entity'
import { useAppSelector } from 'hooks/useStoreHooks'
import { ArCamera } from 'components/ArCamera'
import Logo from 'shared/icons/white_logo.png'
import { ProductPreviewInfo } from 'components/Product/ProductPreviewInfo'

import { useStyles } from './Dressing.style'

function Dressing(): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    const [imgPath, setImgPath] = useState<string>('')
    const [imgFile, setImgFile] = useState<File>()
    
    const [imgProcessedPath, setImgProcessedPath] = useState<string>('')
    const [imgCardPath, setImgCardPath] = useState<string>('')
    const [imgProcessed, setImgProcessed] = useState<boolean>(false)
    const [feetDetected, setFeetDetected] = useState<boolean>(false)
    const [products, setUserProducts] = useState<ProductEntity[]>([])
    const [product, selectProduct] = useState<ProductEntity|undefined>()

    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const img = useRef<HTMLImageElement>(null)

    const { user, accessToken }: { user: any, accessToken: string } = useAppSelector(state => state.userReducer)
    const resetVykingState = () => {
        setImgProcessedPath('')
        setImgCardPath('')
        setImgProcessed(false)
    }

    const removeImg = () => {
        resetVykingState()
        setImgCardPath('')
        setImgPath('')
    }

    const handleProductSelect = (item: ProductEntity) => {
        selectProduct(item)
        resetVykingState()
    }

    const handleDrop = (acceptedFiles: File[]) => {
        removeImg()
        resetVykingState()
        selectProduct(undefined)

        setImgPath(URL.createObjectURL(acceptedFiles[0]))
        setImgFile(acceptedFiles[0])
    }

    const handleFeetDetection = (feetDetected: boolean) => {
        if(!feetDetected) {
            setFeetDetected(false)
            setImgProcessed(true)
            selectProduct(undefined)
            setImgFile(undefined)
        }
    }

    const handleTakePhoto = async (data: any) => {
        setFeetDetected(true)
        setImgProcessedPath(data.value.dataURL)
        setImgProcessed(true)
        await handleUpload()
    }

    const handleUpload = async () => {
        const dressingImage = document.getElementById('dressingImage')
        if(dressingImage && product) {
            let dataUrl = await htmlToImage.toPng(dressingImage)
            const title = product.title || ''
            await uploadImageToProfileGallery(
                dataUrl,
                title.replaceAll(/\s+/g, '_') + '.png',
                'image/png'
            )
        }
    }

    const srcToFile = async (src: string, fileName: string, mimeType: string) => {
        const file = await fetch(src)
        const buffer = await file.arrayBuffer()
        return new File([buffer], fileName, {type:mimeType})
    }

    const uploadImageToProfileGallery = async (src: string, fileName: string, mimeType: string) => {
        const formData = new FormData()
        const imageFile = await srcToFile(src, fileName, mimeType)
        formData.append('files.image', imageFile)
        formData.append('data', JSON.stringify({
            user: user._id
        }))

        try {
            const response = await apiProfileGalleries.post(accessToken, formData)
            setImgCardPath(response?.image?.url)
        } catch (e) {
            console.error(e)
        }
    }

    const handleDownload = useCallback(() => {
        const dressingImage = document.getElementById('dressingImage')
        if(dressingImage && product && imgProcessed) {
            htmlToImage.toPng(dressingImage)
                .then(function(dataUrl: string) {
                    const title = product.title
                    download(
                        dataUrl,
                        title.replaceAll(/\s+/g, '_') + '.png',
                        'image/png'
                    )
                })
                .catch(function(error: string) {
                    // eslint-disable-next-line no-console
                    console.error('oops, something went wrong!', error)
                })
        }
    }, [product, imgProcessed])

    const sneakerBoxStyles = ( matches && products.length > 0 && (products.length*130+products.length*11 > 350) )
        ? { width: `${products.length*130+products.length*11}px`}
        : {}

    const fetchUserProducts = useCallback(async () => {
        await apiProducts.getProductsByUser(accessToken, user.id).then((products: UserProductsModelType) => {
            const { created, submitted, onSale, owned } = products

            let allProducts = {
                created: created ? [...created.map((product: ProductModelType) => new ProductEntity(product))] : [],
                onSale: onSale ? onSale.map((product: ProductModelType) => new ProductEntity(product)) : [],
                owned: owned ? owned.map((product: ProductModelType) => new ProductEntity(product)) : [],
                submitted: submitted ? submitted.map((product: ProductModelType) => new ProductEntity(product)) : []
            }

            let dressProducts = [...allProducts.created, ...allProducts.owned, ...allProducts.onSale]
            let uniqueProducts = [...Array.from(new Map(dressProducts.map(product => [product.id, product])).values())]
            setUserProducts(uniqueProducts)
        })
    }, [user.id])

    useEffect(() => {
        fetchUserProducts()
    }, [user.id, fetchUserProducts])

    return (
        <Layout>
            <Grid className={`${classes.container}`} container direction="row" justifyContent="center">
                { !user && (<Redirect to="/404"/>) }
                <Grid item md={8} xs={12} className={classes.dressBlock}>
                    {!imgPath && <Typography variant="caption" className={classes.stepCaption}>I. UPLOAD A PHOTO</Typography>}
                    <Dropzone
                        className={classes.dropzone}
                        accept="image/*"
                        uploadAltText={!feetDetected && imgProcessed ? 'If you want to flex, please make sure your feet are in view on your picture. ' : 'New Photo? Drag and Drop or '}
                        viewUploadAlt={!matchSmDown}
                        dropAccepted={imgProcessed || Boolean(imgPath)}
                        handleReset={removeImg}
                        handleDrop={handleDrop}
                        maxSize={4194304} // ~4mb
                        description={'Must be no larger than 4 MB.'}
                    >
                        <>
                            <Box id="dressingImage" className={classes.imgWrap}>
                                {product && !imgProcessed && 
                                    <ArCamera 
                                        className={classes.arCamera}
                                        productId={product.id}
                                        imgFile={imgFile}
                                        options={{ handleTakePhoto, handleFeetDetection, pictureIframe: true }} 
                                    />}
                                {feetDetected && imgProcessed &&
                                    <>
                                        <img src={Logo} className={classes.logo} />
                                        {product && <ProductPreviewInfo className={classes.prodInfoSmall} product={product} hidePrice />}
                                    </>}
                                {!feetDetected && imgProcessed ?
                                    <Typography className={classes.errorMsg} variant="h3">
                                        {'Oops we were not able to detect any feet on your picture :('}
                                    </Typography> : 
                                    <img ref={img} src={imgProcessedPath || imgPath} className={classes.uploadedImg} />}
                            </Box>
                        </>
                    </Dropzone>
                    {imgPath && <SocialIcons imgUrl={imgCardPath} disabled={!imgCardPath} handleDownload={handleDownload} />}
                </Grid>
                <Grid item md={4} xs={12} className={classes.dressBlock}>
                    <Typography variant="caption" className={classes.stepCaption}>II. PICK A SNEAKER</Typography>
                    <Grid className={classes.productListContainer} container direction="column" wrap="nowrap" justifyContent="space-between">
                        <Scrollbar
                            className={classes.scrollbar}
                            trackXProps={{
                                renderer: ({ elementRef, ...restProps }) => (
                                    <span {...restProps} ref={elementRef} className={classes.trackX} />
                                )
                            }}
                            trackYProps={{
                                renderer: ({ elementRef, ...restProps }) => (
                                    <span {...restProps} ref={elementRef} className={classes.trackY} />
                                )
                            }}
                        >
                            <Grid
                                container
                                justifyContent="flex-start"
                                className={classes.sneakerBox}
                                style={sneakerBoxStyles}
                            >
                                { products.length > 0 && (
                                    products.map((item:ProductEntity, index) => (
                                        <Link id={String(index)} key={item.id} className={classes.sneakerImgWrapper} onClick={() => handleProductSelect(item)}>
                                            <img src={item.imageUrl} />
                                        </Link>
                                    ))
                                )}
                                { !products.length && (
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        className={classes.noProductsText}>
                                        Oops looks you do not have any sneakers in your wardrobe - hit that ‘Go Shopping’ button and start flexin’
                                    </Typography>
                                )}
                            </Grid>
                        </Scrollbar>
                        { !matches && (
                            <Grid item>
                                <Button
                                    href="/"
                                    variant="contained"
                                    color="primary"
                                    className={classes.shoppingBtn}
                                >
                                    Go Shopping
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
}

export { Dressing }
