import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            background: theme.custom.productPage.background
        },
        productTitle: {
            textTransform: 'uppercase'
        },
        productTitleTimer: {
            color: theme.custom.productPage.labelText
        },
        productModel: {
            position: 'relative',
            background: theme.custom.productPage.modelBackground,
            [theme.breakpoints.down('sm')]: {
                height: 380
            }
        },
        productInfo: {
            padding: '15px 35px 20px',
            border: 'solid 1px',
            borderColor: theme.custom.productPage.infoBorder,
            background: theme.custom.productPage.infoBackground,
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                padding: '20px 10px 0'
            }
        },
        productData: {
            marginTop: 50,
            [theme.breakpoints.down('sm')]: {
                marginTop: 15
            }
        },
        productInfoLabel: {
            color: theme.custom.productPage.labelText
        },
        productInfoText: {
            marginBottom: 25
        },
        productPriceDollar: {
            color: theme.custom.productPage.priceText
        },
        qrCodeWrap: {
            marginTop: 25
        },
        qrCodeLabel: {
            margin: 0,
            marginLeft: 7,
            fontSize: 10,
            fontWeight: 700
        },
        actionsContainer: {
            marginTop: 45,
            [theme.breakpoints.down('sm')]: {
                marginTop: 20,
                padding: '0 30px'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '0 15px'
            }
        },
        mintContainer: {
            marginTop: 90,
            [theme.breakpoints.down('sm')]: {
                marginTop: 20,
                padding: '0 30px'
            }
        },
        actionsWarn: {
            marginTop: 20
        },
        actionBtn: {
            '&:last-child': {
                marginLeft: 30
            }
        },
        dressUpBtn: {
            marginTop: 15
        },
        mobileBtn: {
            textTransform: 'none',
            borderRadius: 10,
            '&:last-child': {
                marginTop: 15
            }
        },
        tryOnText: {
            marginLeft: 7,
            fontSize: 16
        },
        notifySection: {
            marginTop: 108,
            [theme.breakpoints.down('sm')]: {
                marginTop: 25
            }
        },
        dropsIn: {
            color: theme.custom.productPage.dropsIn
        },
        time: {
            paddingTop: '8px',
            fontSize: '40px',
            lineHeight: '45px',
            fontWeight: 700
        },
        notifyButtonContainer: {
            marginTop: 38
        },
        notifyButton: {
            textTransform: 'none',
            fontSize: '18px',
            lineHeight: '21px',
            fontWeight: 700,
            borderColor: theme.custom.productPage.notifyButtonBorderColor,
            '&:hover': {
                background: theme.custom.productPage.notifyButtonBackgroundHover,
                color: theme.custom.productPage.notifyButtonColorHover
            }
        },
        mobEmailContainer: {
            padding: '0 12px'
        },
        mobEmailSubContainer: {
            marginTop: 20
        },
        mobDropsInSection: {
            '& h4': {
                fontSize: '14px'
            },
            '& p': {
                fontSize: '35px'
            }
        }
    }
})
export { useStyles }
