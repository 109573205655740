import { PRIMARY_MARKET_TYPE } from 'constants/product'

import { UserModelType } from './Profile.entity'
import { ProductModelType } from './Product.entity'

export type TokenModelType = {
    _id: string
    price: string
    onSale: number
    seller: UserModelType
    product: ProductModelType
    marketType: string
}

export interface TokenEntity {
    id: string
    key: string
    onSale: number
    total: number
    price: number
    sellerName: string | undefined
    sellerId: string
    sellerAddress: string
    productId: string
    marketType: string
}

export class TokenEntity implements TokenEntity {
    constructor(token?: TokenModelType) {
        if (!token) return
        this.id = token._id
        this.key = token._id
        this.onSale = token.onSale
        this.total = token.product.numberOfModels
        this.price = parseFloat(token.price)
        this.sellerName = token.seller.profile?.username
        this.sellerId = token.seller._id
        this.sellerAddress = token.seller.username
        this.productId = token.product._id
        this.marketType = token.marketType ? token.marketType : PRIMARY_MARKET_TYPE
    }
}
