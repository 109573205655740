import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            maxWidth: 1440,
            margin: '0 auto',
            padding: '0 20px'
        },
        model3d: {
            width: 400,
            height: 400,
            marginBottom: 0
        },
        dropzoneModel: {
            minHeight: 240
        },
        dropzoneImg: {
            minHeight: 120
        },
        dropZoneContainer: {
            width: 650,
            maxWidth: '100%'
        },
        uploadedImg: {
            width: '100%',
            maxHeight: 450
        },
        uploadTitle: {
            fontSize: 60,
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        subtitle: {
            fontSize: 28,
            fontWeight: 700,
            color: theme.custom.form.subtitleColor,
            textTransform: 'uppercase'
        },
        formContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            marginTop: 120
        },
        inputContainer: {
            marginTop: 65,
            '&:first-child': {
                marginTop: 0
            }
        },
        uplod3dTitle: {
            marginBottom: 50
        },
        gifSubtitle: {
            color: theme.custom.form.inputSubLabelColor,
            marginBottom: 40
        },
        inputStyle: {
            width: 640,
            '& .MuiInputLabel-shrink': {
                transform: 'translate(0, -2px)'
            },
            '& label.Mui-focused': {
                color: theme.custom.form.activeInputColor
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.custom.form.activeInputColor
            },
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
        benefitInfo: {
            color: theme.custom.form.benefitInfoColor,
            marginTop: 42
        },
        buttonsSection: {
            maxWidth: '415px',
            margin: '55px 0 32px',
            gap: 15
        },
        requiredLabel: {
            margin: '32px 0 95px',
            color: theme.custom.form.infoTextColor
        }
    }
})