import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductEntity } from 'entities/Product.entity'
import { FormProductEntity } from 'entities/FormProduct.entity'

interface ProductState {
    productPreview: ProductEntity
    productForm: FormProductEntity
}

const initialState: ProductState = {
    productPreview: new ProductEntity(),
    productForm: new FormProductEntity()
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductPreview (state, action: PayloadAction<ProductEntity>) {
            state.productPreview = action.payload
        },
        setFormValues (state, action: PayloadAction<FormProductEntity>) {
            state.productForm = action.payload
        },
        reset () {
            return initialState
        }
    }
})

export const { setProductPreview, setFormValues, reset } = productSlice.actions

export const productReducer = productSlice.reducer