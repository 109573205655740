import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        dragAndDropContainer: {
            padding: 20,
            cursor: 'pointer',
            border: '1px dashed',
            transition: '0.1s',
            borderColor: theme.custom.common.primaryGray,
            flexGrow: 1,
            '&:hover': {
                borderColor: theme.custom.common.orange
            }
        },
        dragAndDropActive: {
            transform: 'scale(0.9)',
            borderColor: theme.custom.common.orange
        },
        captureInput: {
            position: 'absolute',
            fontSize: 0, // Necessary style for avoiding all cursors except pointer
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer'
        },
        chooseFileButton: {
            position: 'relative',
            width: 'fit-content',
            padding: '5px 40px',
            fontSize: 12,
            textTransform: 'none',
            [theme.breakpoints.down('sm')]: {
                width: 168,
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        dropIcon: {
            color: theme.custom.common.orange
        },
        dropInput: {
            '&:focus-visible': { 
                outline: 'none' 
            }
        },
        contentContainer: {
            flexGrow: 1
        },
        childWrap: {
            position: 'relative',
            display: 'inline-block'
        },
        uploadedFileContainer: {
            position: 'relative',
            width: 'fit-content',
            margin: '0 auto',
            textAlign: 'center',
            maxHeight: 520,
            cursor: 'auto',
            '& img': {
                userDrag: 'none',
                '-webkit-user-drag': 'none',
                userSelect: 'none',
                '-moz-user-select': 'none',
                '-webkit-user-select': 'none',
                '-ms-user-select': 'none'
            }
        },
        closeBtn: {
            top: -13,
            right: -13,
            padding: 5,
            color: theme.custom.dropzone.secondaryText,
            fontSize: '1.2rem',
            position: 'absolute',
            background: theme.custom.dropzone.iconBackground,
            transition: '0.1s',
            border: '1px solid',
            borderColor: theme.custom.dropzone.btnBorder,
            borderRadius: '50%',
            cursor: 'pointer',
            zIndex: 15,
            '&:hover': {
                color: theme.custom.dropzone.iconHover,
                borderColor: theme.custom.dropzone.iconHover
            }
        },
        newPhotoText: {
            marginTop: 15
        },
        chooseText: {
            cursor: 'pointer'
        },
        description: {
            marginTop: 15
        },
        errorText: {
            marginTop: 10,
            ...theme.typography.body1
        }
    }
})
export { useStyles }
