import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        topBox: {
            paddingBottom: 30
        },
        infoTitle: {
            fontSize: 18
        },
        info_desc: {
            paddingTop: 3,
            color: theme.custom.buyModal.balanceColor
        },
        hashBox: {
            overflow: 'hidden'
        },
        hash: {
            paddingTop: 3,
            textDecoration: 'underline',
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            color: theme.custom.buyModal.balanceColor,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    }
})

export { useStyles }
