import axios from 'axios'

import { ApiCore } from '../utilities'
import { handleResponse, handleError } from '../utilities/response'

const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://127.0.0.1:1337/'

const url = 'receipts'
const plural = 'receipts'
const single = 'receipt'

class ApiReceipts extends ApiCore {
    saveSignedReceipt: any
}

const apiReceipts = new ApiReceipts({
    allowGetAll: true,
    allowGetSingle: true,
    allowPost: true,
    allowPut: false,
    allowPatch: false,
    allowRemove: false,
    url: url,
    plural: plural,
    single: single
})

apiReceipts.saveSignedReceipt = (token: string, payload: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    return axios
        .post(`${BASE_URL}/${url}/saveSignedReceipt`, payload, config)
        .then(handleResponse)
        .catch(handleError)
}


export { apiReceipts }
