import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        socials: {
            marginTop: 15,
            '&.active': {
                '& button': {
                    background:
                        theme.custom.productPage.socialsActiveBackground
                }
            },
            '& button': {
                width: '42px',
                minWidth: '42px',
                height: '42px',
                margin: '0 5px',
                borderRadius: '21px',
                background: theme.custom.productPage.socialsBackground,
                '&:hover': {
                    background: theme.custom.productPage.socialsBackground
                }
            }
        }
    }
})
export { useStyles }