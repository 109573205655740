import React from 'react'

import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { ProfileEntity } from 'entities/Profile.entity'

import { CreatorPreview } from '../CreatorPreview'

import { useStyles } from './CreatorsList.style'

export const CreatorsList = ({ creatorsList }: { creatorsList: ProfileEntity[]}): JSX.Element => {
    const classes = useStyles()

    const creators = creatorsList.map((item) => (
        <Link
            to={`/profile/${item.wallet}?tab=created`}
            className={classes.link}
            key={item.wallet}
        >
            <CreatorPreview creatorData={item} />
        </Link>
    ))

    return (
        <Grid
            container
            justifyContent="center"
            className={classes.container}
        >
            {creators}
        </Grid>
    )
}
