import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            maxWidth: 1440,
            margin: '0 auto',
            padding: '0 15px'
        },
        dropZoneAvatarContainer: {
            width: 120,
            height: 120,
            borderRadius: '50%',
            padding: 0,
            boxSizing: 'content-box' 
        },
        dropZoneCoverContainer: {
            width: 650,
            minHeight: 240,
            maxHeight: 620,
            maxWidth: '100%'
        },
        uploadedAvatarImg: {
            width: 120,
            height: 120
        },
        uploadedCoverImg: {
            width: '100%',
            maxHeight: 450
        },
        profileFormTitle: {
            fontSize: 60,
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        subtitle: {
            fontSize: 28,
            fontWeight: 700,
            color: theme.custom.form.subtitleColor,
            textTransform: 'uppercase'
        },
        formContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            margin: '120px 0 90px'
        },
        inputContainer: {
            marginTop: 70,
            '&:first-child': {
                marginTop: 0
            }
        },
        inputStyle: {
            width: 650,
            '& .MuiInputLabel-shrink': {
                transform: 'translate(0, -2px)'
            },
            '& label.Mui-focused': {
                color: theme.custom.form.activeInputColor
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.custom.form.activeInputColor
            }
        },
        socailSection: {
            marginBottom: 90
        },
        socialInputContainer: {
            marginTop: 40,
            '&:first-child': {
                marginTop: 0
            }
        },
        socialInputStyle: {
            width: 600
        },
        socialLabel: {
            fontSize: 22,
            fontWeight: 400,
            marginBottom: 90
        },
        uploadPictureSubtitle: {
            color: theme.custom.form.inputSubLabelColor,
            marginTop: 12,
            marginBottom: 40
        },
        buttonsSection: {
            maxWidth: '415px',
            margin: '55px 0 32px',
            position: 'relative'
        },
        cancelButton: {
            width: 'calc(50% - 15px)',
            marginRight: 15,
            fontSize: 18,
            fontWeight: 700
        },
        saveButton: { 
            width: 'calc(50% - 15px)',
            fontSize: 18,
            fontWeight: 700
        }
    }
})