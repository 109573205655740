import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        pending: {
            color: theme.custom.status.pending
        },
        rejected: {
            color: theme.custom.status.rejected
        },
        approved: {
            color: theme.custom.status.approved
        }
    }
})
export { useStyles }
