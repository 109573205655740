import React from 'react'

import { useHistory } from 'react-router-dom'
import { Link, Box, Typography } from '@material-ui/core'
import { PurchaseModal } from 'components/Product/PurchaseModal'
import { BuyModal } from 'components/Product/BuyModal'
import { SellModal } from 'components/Product/SellModal'
import { Alert } from 'components/Alert'
import { PricingHelperModal } from 'components/Product/PricingHelperModal'
import { EmailSubscriptionModal } from 'components/Product/EmailSubscriptionModal'
import { ProductInfo } from 'components/Product/ProductInfo'
import { ProductPreview } from 'components/Product/ProductPreview'
import { ProductEntity } from 'entities/Product.entity'
import { TokenModelType } from 'entities/Token.entity'
import { useAppSelector } from 'hooks/useStoreHooks'

import { useStyles } from './ProductView.style'

type ProductViewProps = { 
    productData: ProductEntity,
    preview: boolean,
    tokens: TokenModelType[]
}

function ProductView({ productData, preview, tokens }: ProductViewProps): JSX.Element {
    const classes = useStyles()
    const history = useHistory()
    const { accessToken } = useAppSelector(state => state.userReducer)

    return (
        <React.Fragment>
            <Box className={classes.container}>
                {
                    preview ? 
                        <>
                            <Alert className={classes.alert}>
                                <>
                                    {'This is a preview of your sneaker listing. Click '}
                                    <Link onClick={() => history.go(-1)} className={classes.link} underline="always">here</Link> 
                                    {' to go back to editing.'}
                                </>
                            </Alert>
                            <Typography className={`${classes.productTitle} ${classes.infoTitle}`}>THIS IS WHAT YOUR PRODUCT PAGE LOOKS LIKE</Typography>
                            <ProductInfo productData={productData} titleTimer qrCodeUrl={'Your QR-code link will be automatically generated after reviewing your product.'} />
                            <Typography className={`${classes.productTitle} ${classes.cardTitle}`}>THIS IS WHAT YOUR PRODUCT TILE LOOKS LIKE</Typography>
                            <Box className={classes.previewContainer}>
                                <ProductPreview productData={productData} qrCodeUrl={'Your QR-code link will be automatically generated after reviewing your product.'} />
                            </Box>

                        </>
                        : <ProductInfo productData={productData} />
                
                }
            </Box>
            {
                !preview &&
                <>
                    <PurchaseModal tokens={tokens} />
                    <BuyModal productData={productData} />
                    { accessToken && <SellModal productData={productData} /> }
                    { accessToken && <PricingHelperModal /> }
                    <EmailSubscriptionModal />
                </>
            }
        </React.Fragment>
    )
}

export { ProductView }
