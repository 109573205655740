import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        title: {
            color: theme.custom.aboutFlex.text,
            textAlign: 'center',
            margin: '150px auto'
        }
    }
})
export { useStyles }
