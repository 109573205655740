const KEY = 'redux'

export function loadState() {
    const serializedState = localStorage.getItem(KEY)
    if (!serializedState) return
    return JSON.parse(serializedState)
}

export async function saveState(state: any) {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(KEY, serializedState)
}