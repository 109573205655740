import React, { useState, useEffect, useCallback } from 'react'

import { Snackbar, Typography, Link, Button, InputAdornment, Grid, CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import pick from 'lodash.pick'
import { Redirect, useHistory } from 'react-router-dom'
import { ProductEntity, ProductModelType  } from 'entities/Product.entity'
import { FormProductEntity  } from 'entities/FormProduct.entity'
import { Layout } from 'components/Layout'
import { AdornedButton } from 'components/Button'
import { apiProducts } from 'services/api/products'
import { FlexTextField } from 'components/Form/FlexTextField'
import { useFormik } from 'formik'
import { checkZipARPackage, get3DModel } from 'utils/uploadingHelpers'
import { uploadingDataSchema } from 'utils/schemas/uploadSneaker'
import { DatePickerInput } from 'components/Upload/DatePickerInput'
import { isProfileACreator } from 'utils/profile'
import { Dropzone } from 'components/Upload/Dropzone'
import { Model3D } from 'components/Model3D'
import { useAppDispatch, useAppSelector } from 'hooks/useStoreHooks'
import { setProductPreview, setFormValues, reset } from 'store/product'
import { getUSD, getNumber } from 'utils/helpers'
import { useGetCurrencyRate } from 'hooks/useGetCurrencyRate'
import { ReactComponent as TezosLogo } from 'shared/icons/tezos_logo.svg'

import { useStyles } from './UploadingSneaker.style'

function UploadingSneaker(): JSX.Element {
    const classes = useStyles()

    const { productPreview, productForm } = useAppSelector(state => state.productReducer)
    const { user, accessToken }: { user: any, accessToken: string } = useAppSelector(state => state.userReducer)

    const dispatch = useAppDispatch()
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [descriptionLength, setDescriptionLength] = useState(0)
    const [modelNameLength, setModelNameLength] = useState(0)
    const [isPickerOpen, setIsOpen] = useState(false)
    const [modelPath, setModelPath] = useState<string>(productPreview.modelUrl || '')
    const [imgPath, setImgPath] = useState<string>(productPreview.imageUrl || '')
    const [usdPrice, setUsdPrice] = useState<number|boolean>(0)
    const priceField = React.createRef<HTMLInputElement>()

    const formik = useFormik<FormProductEntity>({
        initialValues: productForm,
        validationSchema: uploadingDataSchema,
        onSubmit: (values) => {
            uploadProductToBackend(values)
        }
    })

    const redirectToPreview = async () => {
        const product = {
            ...formik.values,
            _id: 'productmockid',
            createdBy: {
                _id: user.id,
                username: 'USERNAME',
                profile: user.profile,
                role: null
            },
            arPackage: {
                url: formik.values.productZip
            },
            arModel: {
                url: modelPath
            },
            onSale: formik.values.numberOfModels,
            productImage: {
                url: imgPath
            }
        } as ProductModelType

        dispatch(setFormValues(formik.values))
        dispatch(setProductPreview(new ProductEntity(product)))
        history.push({ pathname: '/preview' })
    }
    
    const handleDropModel = async (acceptedFiles: File[]) => {
        const checkResult = await checkZipARPackage(acceptedFiles[0])
        if (checkResult.status) {
            formik.setFieldValue('product3DModel', acceptedFiles[0])
            const fileData = await get3DModel(acceptedFiles[0])
            formik.setFieldValue('productZip', fileData)

            if (!fileData) return
            const path = URL.createObjectURL(fileData)
            setModelPath(path)
        }
    }

    const handleDropImg = (acceptedFiles: File[]) => {
        let fileData = acceptedFiles[0]
        formik.setFieldValue('productImage', acceptedFiles[0])
        if (typeof fileData === 'string') {
            setImgPath(fileData)
            return
        }
        setImgPath(URL.createObjectURL(fileData))
    }

    const removeModel = () => {
        formik.setFieldValue('product3DModel', '')
        formik.setFieldValue('productZip', '')
    }
    
    const removeImg = () => {
        formik.setFieldValue('productImage', '')
        setImgPath('')
    }

    const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescriptionLength(e.target.value.length)
        formik.setFieldValue('description', e.target.value)
    }

    const handleModelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setModelNameLength(e.target.value.length)
        formik.setFieldValue('productName', e.target.value)
    }

    const handleSnackbarClose = () => {
        setErrorMessage('')
    }

    const uploadProductToBackend = async (formValues: FormProductEntity) => {
        try {
            if (!user?._id) {
                throw new Error('First you need to log in with your wallet.')
            }
            setLoading(true)
            const formData = new FormData()
            formData.append('files.arPackage', formValues.product3DModel)
            formData.append('files.arModel', formValues.productZip)
            formData.append('files.productImage', formValues.productImage)
            formData.append('data', 
                JSON.stringify({
                    ...pick(formValues, [
                        'productName',
                        'description',
                        'dropDate',
                        'numberOfModels',
                        'price'
                    ]),
                    createdBy: user._id,
                    onSale: formValues.numberOfModels,
                    status: '61531effd986457139c91201'
                })
            )
    
            await apiProducts.post(accessToken, formData)
            dispatch(reset())
            history.push('/')
        } catch (error: any) {
            setErrorMessage(error.message)
        } finally {
            setLoading(false)
        }
    }

    const currencyRate = useGetCurrencyRate()
    const handlePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        let num = getNumber(e.target.value)
        if(num) {
            formik.setFieldValue('price', num)
            currencyRate && setUsdPrice(getUSD(parseFloat(num), currencyRate))
        }else{
            formik.setFieldValue('price', 0)
            setUsdPrice(0)
        }
    }

    const handleNumberOfModels = (e: React.ChangeEvent<HTMLInputElement>) => {
        let num = getNumber(e.target.value)
        num ? formik.setFieldValue('numberOfModels', num) : formik.setFieldValue('numberOfModels', 0)
    }

    const checkPriceField = useCallback((currencyRate: number|undefined) => {
        if(priceField.current && priceField.current.value){
            currencyRate && setUsdPrice(getUSD(parseFloat(priceField.current.value), currencyRate))
        }
    }, [priceField])

    useEffect(() => {
        checkPriceField(currencyRate)
    }, [formik.values.price, checkPriceField, currencyRate])

    return (
        <Layout>
            <div className={classes.container}>
                { !isProfileACreator(user) && <Redirect to="/404" /> }
                <Typography className={classes.uploadTitle}>Upload a sneaker</Typography>
                <Typography className={classes.subtitle}>First step to hustlin’</Typography>
                <form className={classes.formContainer} onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className={classes.inputContainer}>
                        <Typography variant="body1" className={classes.uplod3dTitle}>Upload your 3D Asset package</Typography>
                        <Grid container className={classes.dropZoneContainer}>
                            <Dropzone
                                errorMessage={formik.touched.product3DModel ? formik.errors.product3DModel : ''}
                                dropAccepted={Boolean(modelPath)}
                                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                className={classes.dropzoneModel}
                                handleReset={removeModel}
                                handleDrop={handleDropModel}
                                uploadAltText="New Model? Drag and Drop or "
                            >
                                {modelPath ? <Model3D filePath={modelPath} className={classes.model3d} /> : <CircularProgress color="primary" size={50} />}
                            </Dropzone>
                        </Grid>
                    </div>
                    <div className={classes.inputContainer}>
                        <Typography variant="body1">Upload a GIF of your 3D sneaker</Typography>
                        <Typography variant="body1" className={classes.gifSubtitle}>
                            Must be a one-rotation 7 seconds GIF on a white background. Size: 512 x 512. Click&nbsp;
                            <Link
                                className={classes.gifSubtitle}
                                underline="always"
                                href="https://giphy.com/gifs/jaNrvJi6fTcBhg7H0C"
                                target="_blank">here
                            </Link>
                            &nbsp;for reference.
                        </Typography>
                        <Dropzone
                            errorMessage={formik.touched.productImage ? formik.errors.productImage : ''}
                            dropAccepted={Boolean(imgPath)}
                            accept="image/gif"
                            className={classes.dropzoneImg}
                            handleReset={removeImg}
                            handleDrop={handleDropImg}
                        >
                            <img src={imgPath} className={classes.uploadedImg} />
                        </Dropzone>
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="productName"
                            name="productName"
                            className={classes.inputStyle} 
                            label="Model Name" 
                            value={formik.values.productName}
                            onChange={handleModelNameChange}
                            inputHelperText={`${modelNameLength}/20`}
                            isHelperTextInTheEnd 
                            error={
                                formik.touched.productName &&
                                Boolean(formik.errors.productName)
                            }
                            errorMessage={
                                (formik.touched.productName && formik.errors.productName) ?
                                    formik.errors.productName : undefined
                            }
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="description"
                            name="description"
                            className={classes.inputStyle} 
                            label="Description" 
                            value={formik.values.description}
                            onChange={handleDescChange}
                            inputHelperText={`${descriptionLength}/350`} 
                            isHelperTextInTheEnd
                            error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                            }
                            errorMessage={
                                (formik.touched.description && formik.errors.description) ?
                                    formik.errors.description : undefined
                            }
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <DatePickerInput 
                            inputId="dropDate" 
                            inputName="dropDate"
                            className={classes.inputStyle}
                            selectedValue={formik.values.dropDate}
                            onClick={() => setIsOpen(!isPickerOpen)}
                            isPickerOpen={isPickerOpen}
                            onChange={(date) => {
                                formik.setFieldValue('dropDate', date)
                                setIsOpen(!isPickerOpen)
                            }}
                            error={
                                formik.touched.dropDate && Boolean(formik.errors.dropDate)
                            }
                            errorMessage={
                                (formik.touched.dropDate && formik.errors.dropDate) ?
                                    formik.errors.dropDate : undefined
                            }
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="numberOfModels"
                            name="numberOfModels"
                            className={classes.inputStyle} 
                            label="Number of models" 
                            subLabel="Maximum 1000." 
                            type="text"
                            value={formik.values.numberOfModels}
                            onChange={handleNumberOfModels}
                            error={
                                formik.touched.numberOfModels &&
                                Boolean(formik.errors.numberOfModels)
                            }
                            errorMessage={
                                (formik.touched.numberOfModels && formik.errors.numberOfModels) ?
                                    formik.errors.numberOfModels : undefined
                            }
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <FlexTextField 
                            id="price"
                            name="price"
                            className={classes.inputStyle} 
                            label="Price" 
                            type="text"
                            value={formik.values.price}
                            onChange={handlePrice}
                            ref={priceField}
                            inputHelperText={formik.values.price ? `Dollar equivalent: ~ $${usdPrice}` : undefined}
                            endAdornment={<InputAdornment position="end"><TezosLogo className="tezos-logo" /></InputAdornment>}
                            error={
                                formik.touched.price &&
                                        Boolean(formik.errors.price)
                            }
                            errorMessage={(formik.touched.price && formik.errors.price) ? 
                                formik.errors.price 
                                : undefined
                            }
                        />
                    </div>

                    <Typography className={classes.benefitInfo}>Creators benefit from a 5% royalty on every secondary sale on Flex.</Typography>
                    <Grid container justifyContent="space-between" direction="row" wrap="nowrap" className={classes.buttonsSection}>
                        <Button
                            variant="outlined" 
                            color="primary"
                            onClick={redirectToPreview}
                        >
                            Preview
                        </Button>
                        <AdornedButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                            disabled={loading}
                        >
                            Submit
                        </AdornedButton>
                    </Grid>
                    <Typography variant="body1" className={classes.requiredLabel}>All fields required.</Typography>
                </form>
                <Snackbar
                    open={!!errorMessage}
                    onClose={handleSnackbarClose}
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert severity="error" elevation={6} variant="filled">{errorMessage}</Alert>
                </Snackbar>
            </div>
        </Layout>
    )
}

export { UploadingSneaker }
