import React, { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'

import { useStyles } from './CountdownTimer.style'

interface CountdownTimerProps {
    date: number
    classTimer?: string
    classTimerText?: string
}

// Only test version
function CountdownTimer({ date, classTimer, classTimerText }: CountdownTimerProps): JSX.Element {
    const classes = useStyles()

    const offsetHours = new Date(0).getHours() * 60 * 60 * 1000

    const zeroDate = Date.now() - offsetHours
    const difference = date - zeroDate
    const [restTime, setTime] = useState<string>()

    const formatDate = (date: number) => {
        return new Date(date).toLocaleTimeString('ru')
    }

    useEffect(() => {
        const updateTime = setInterval(() => {
            if (difference >= 0) {
                let formattedTime = formatDate(difference - offsetHours)
                setTime(formattedTime)
            }
        }, 1000)

        return () => {
            clearInterval(updateTime)
        }
    })

    return (
        <Box className={`${classes.container} ${classTimer}`}>
            <Typography variant="caption"  className={`${classes.time} ${classTimerText}`}>{restTime}</Typography>
        </Box>
    )
}

export { CountdownTimer }
