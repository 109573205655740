import React, { useState } from 'react'

import { useForm, ValidationError } from '@formspree/react'
import {
    Grid,
    Container,
    TextField,
    Typography,
    Button
} from '@material-ui/core'
import clsx from 'clsx'
import { checkEmail } from 'utils/validators'

import { useStyles } from './EmailSubscription.style'

type EmailSubscriptionProps = {
    classNames?: {
        container?: string,
        subscribeContainer?: string
    },
    children?: JSX.Element,
    buttonLabel?: string
}

function EmailSubscription({classNames, children, buttonLabel}: EmailSubscriptionProps): JSX.Element {
    const classes = useStyles()

    const [state, handleSubmit] = useForm('mknynplw')

    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(true)
    const [emailFocus, setEmailFocus] = useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        !email && setEmailValid(true)
    }

    const handleFocus = () => {
        setEmailFocus(true)
    }

    const handleBlur = () => {
        !email && setEmailFocus(false)
    }

    const handleClick = () => {
        setEmailValid(checkEmail(email))
    }

    return (
        <Container className={clsx(classes.container, classNames && classNames.container)}>
            {children}
            {!state.succeeded &&
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap="nowrap"
                        className={clsx(classes.subscribeContainer, classNames && classNames.subscribeContainer)}
                    >
                        <TextField
                            error={Boolean(email && !emailValid)}
                            id="email"
                            type="email"
                            name="email"
                            autoComplete="off"
                            label={emailFocus ? '' : 'Email Address'}
                            helperText={
                                email && !emailValid ? 'Please enter a valid email address.' : ''
                            }
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            fullWidth
                            value={email}
                            variant="filled"
                            FormHelperTextProps={{
                                className: classes.inputHelpText
                            }}
                            InputLabelProps={{
                                className: classes.inputLabelSubscribe,
                                shrink: false
                            }}
                            InputProps={{
                                className: classes.inputSubscribe
                            }}
                        />
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                        <Button
                            variant="contained"
                            className={classes.buttonSubscribe}
                            onClick={handleClick}
                            disabled={state.submitting}
                            type='submit'
                        >
                            <Typography variant="body2" color="secondary" className={classes.buttonTextSubscribe}>
                                { buttonLabel ? buttonLabel : 'Subscribe' }
                            </Typography>
                        </Button>
                    </Grid>
                </form>
            }
            {state.succeeded &&
                <Typography variant="body2" className={classes.successMessage}>
                    Thanks for signing up! We’ll keep you up to date.
                </Typography>
            }
        </Container>
    )
}

export { EmailSubscription }
