import { makeStyles, alpha } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        walletButtonContainer: {
            width: '130px',
            marginLeft: '30px',
        },
        connectWalletButton: {
            height: '33px',
            padding: 0,
            fontWeight: 700,
            textTransform: 'uppercase',
            '& span': {
                padding: 0
            }
        },
        connectWalletLabel: {
            lineHeight: '22px'
        },
        profileMenuPaper: {
            borderRadius: 0,
            padding: '14px 17px'
        },
        profileMenu: {
            gap: 10
        },
        profileSubMenuButton: {
            textTransform: 'none',
            padding: 0
        },
        profileMenuButton: {
            padding: '7px 38px',
            textTransform: 'none'
        },
        profileSubMenuBalance: {
            lineHeight: '16px'
        },
        profileBalanceBox: {
            marginRight: 13,
            marginLeft: 17
        },
        profileAvatar: {
            width: 40,
            height: 40,
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.35)
            }
        },
        profileProgressBar: {
            height: 26
        }
    }
})
export { useStyles }
