import React, { useContext, useState, useEffect } from 'react'

import { SECONDARY_MARKET_TYPE } from 'constants/product'

import { useHistory } from 'react-router-dom'
import { Grid, Typography, Button } from '@material-ui/core'
import { TransactionWalletOperation } from '@taquito/taquito'
import { Modal } from 'components/Modal'
import { ProductPreview } from 'components/Product/ProductPreview'
import { FollowSteps } from 'components/Product/FollowSteps'
import { TransactionStatus } from 'components/Product/TransactionStatus'
import { NotAvailableEdition } from 'components/Product/NotAvailableEdition'
import { ProductActions, ProductContext } from 'context/ProductPageContext'
import { ProductEntity } from 'entities/Product.entity'
import { ReactComponent as TezosLogo } from 'shared/icons/tezos_logo.svg'
import { getAccountBalance } from 'utils/beacon-wallet'
import { apiOrders } from 'services/api/orders'
import { apiReceipts } from 'services/api/receipts'
import { transferTez, generateAndSignReceipt } from 'utils/beacon-wallet'
import { useAppSelector } from 'hooks/useStoreHooks'

import { useStyles } from './BuyModal.style'


const BUY_TOKEN_STEPS = { 
    INITIAL: 'INITIAL',
    APPROVING_TRANSFER: 'APPROVE_TRANSFER',
    CREATING_TRANSACTION: 'CREATING_TRANSACTION',
    TRANSACTION_CREATED: 'TRANSACTION_CREATED',
    ORDER_EXPIRED: 'ORDER_EXPIRED'
}

type BuyModalProps = {
    productData: ProductEntity
}

export const BuyModal = ({ productData }: BuyModalProps): JSX.Element => {
    const classes = useStyles()
    const history = useHistory()
    const { state, dispatch } = useContext(ProductContext)
    // @TODO - move to store/profile
    const [accountBalance, setAccountBalance] = useState<number>(0)
    const [operationHash, setOperationHash] = useState<string>('')
    const [buyTokenStep, setBuyTokenStep] = useState<string>(BUY_TOKEN_STEPS.INITIAL)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const orderData = state.buyModalOrder
    const paymentData = state.buyModalPayment
    const { user, accessToken } = useAppSelector(state => state.userReducer)

    const fetchBalance = async () => {
        if (!user) return 0
        const balance = (await getAccountBalance(user.username)).toString()
        setAccountBalance(balance)
    }

    const onModalsClose = () => {
        dispatch({ type: ProductActions.PURCHASE_MODAL_STATE, payload: false })
        dispatch({ type: ProductActions.BUY_MODAL_STATE, payload: false })

        dispatch({ type: ProductActions.BUY_MODAL_ORDER, payload: null })
        dispatch({ type: ProductActions.BUY_MODAL_PAYMENT, payload: null })
        setErrorMessage('')
        setOperationHash('')
        setBuyTokenStep(BUY_TOKEN_STEPS.INITIAL)
    }

    const onBuyOrder = async () => {
        if (!orderData) return
        setBuyTokenStep(BUY_TOKEN_STEPS.APPROVING_TRANSFER)
        // check order state
        const isOrderActive = await apiOrders.isOrderActive(accessToken,  orderData._id)
        
        if (isOrderActive !== true) {
            setBuyTokenStep(BUY_TOKEN_STEPS.ORDER_EXPIRED)
            return
        }
        const walletResponse: TransactionWalletOperation = await initializePayment()
        if (!walletResponse) {
            setBuyTokenStep(BUY_TOKEN_STEPS.INITIAL)
            return
        }
        console.log({ walletResponse })
        setOperationHash(walletResponse.opHash)
        setBuyTokenStep(BUY_TOKEN_STEPS.CREATING_TRANSACTION)
        setBuyTokenStep(BUY_TOKEN_STEPS.TRANSACTION_CREATED)
    }

    const actionHandler = () => {
        switch (buyTokenStep) {
            case BUY_TOKEN_STEPS.INITIAL: 
                setErrorMessage('')
                onBuyOrder()
                break
            case BUY_TOKEN_STEPS.APPROVING_TRANSFER:
            case BUY_TOKEN_STEPS.CREATING_TRANSACTION:
                setBuyTokenStep(BUY_TOKEN_STEPS.INITIAL)
                break
            case BUY_TOKEN_STEPS.TRANSACTION_CREATED:
                history.push('/dressing')
                break
            case BUY_TOKEN_STEPS.ORDER_EXPIRED:
                setBuyTokenStep(BUY_TOKEN_STEPS.INITIAL)
                dispatch({ type: ProductActions.PURCHASE_MODAL_STATE, payload: true })
                dispatch({ type: ProductActions.BUY_MODAL_STATE, payload: false })

        }
    }

    const initializePayment = async () => {
        if (!paymentData) return
        let response = null
        try {
            response = await transferTez(paymentData.amount)
        } catch(error: any) {
            console.log('[initializePayment] ', error)
            setErrorMessage(error)
        }
        
        console.log({ response })
        if (response && response.opHash) {
            const receiptResponse = await generateAndSignReceipt(paymentData.amount, paymentData._id, response.opHash)
            console.log({ receiptResponse })
            // send receipt data to the BE
            await apiReceipts.saveSignedReceipt(accessToken, receiptResponse)
        }
        return response
    }

    useEffect(() => {
        if (state.buyModalState) {
            fetchBalance()
        }
    }, [state.buyModalState])

    return (
        <Modal
            width={741}
            height={564}
            open={state.buyModalState}
            title={
                [BUY_TOKEN_STEPS.APPROVING_TRANSFER, BUY_TOKEN_STEPS.CREATING_TRANSACTION].includes(buyTokenStep) ?
                    'FOLLOW STEPS' :
                    buyTokenStep === BUY_TOKEN_STEPS.TRANSACTION_CREATED  ?
                        `BUYING ${orderData?.product.productName}`:
                        'YOUR ORDER'
            }
            onClose={onModalsClose}
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
            >
                <ProductPreview className={classes.productPreviewModal} productData={productData} />
                <div className={classes.buySection}>
                    { [BUY_TOKEN_STEPS.APPROVING_TRANSFER, BUY_TOKEN_STEPS.CREATING_TRANSACTION].includes(buyTokenStep) ? (
                        <FollowSteps approveLoading={buyTokenStep === BUY_TOKEN_STEPS.APPROVING_TRANSFER} purchaseLoading={buyTokenStep === BUY_TOKEN_STEPS.CREATING_TRANSACTION} />
                    ) : buyTokenStep === BUY_TOKEN_STEPS.TRANSACTION_CREATED ?
                        (
                            <TransactionStatus status={'Confirmed'} hash={operationHash} />
                        ) : buyTokenStep === BUY_TOKEN_STEPS.ORDER_EXPIRED ?
                            ( <NotAvailableEdition /> )
                            :
                            (
                                <>
                                    <Typography variant="subtitle2" className={classes.balance}>BALANCE: <span className={classes.subSpan}>{accountBalance} <TezosLogo className="tezos-logo--small tezos-logo--orange" /></span></Typography>
                                    <Typography variant="subtitle2" className={classes.price}>PRICE <span className={classes.subSpan}>{orderData?.token.price}<TezosLogo className="tezos-logo--small" /></span></Typography>
                                    <Typography variant="subtitle2" className={classes.quantity}>QUANTITY <span className={classes.subSpan}>{orderData?.quantity}</span></Typography>
                                    <span className={classes.line}></span>
                                    <Typography variant="h5" className={classes.total}>TOTAL <span className={classes.subSpan}>{orderData?.total}<TezosLogo className="tezos-logo--small" /></span></Typography>
                                </>
                            )
                    }
                    <Typography className={classes.errorMsg} variant="h5">{errorMessage}</Typography>
                    <Button variant="outlined" color="primary" className={classes.buyButton} onClick={actionHandler} disabled={buyTokenStep === BUY_TOKEN_STEPS.CREATING_TRANSACTION}>
                        {
                            [BUY_TOKEN_STEPS.APPROVING_TRANSFER, BUY_TOKEN_STEPS.CREATING_TRANSACTION].includes(buyTokenStep) ?
                                'Cancel' :
                                buyTokenStep === BUY_TOKEN_STEPS.TRANSACTION_CREATED  ?
                                    'Go to dressing room':
                                    buyTokenStep === BUY_TOKEN_STEPS.ORDER_EXPIRED ?
                                        'Back':
                                        'Buy'
                        }
                    </Button>
                </div>
            </Grid>
            
        </Modal>
    )
}
