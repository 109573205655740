import { UserModelType } from './Profile.entity'

export type ProductModelType = {
    _id: string
    description: string
    productName: string
    productVideo?: any
    productImage: any
    arPackage?: any
    arModel?: any
    numberOfModels: any
    dropDate: any
    colorOne?: string
    colorTwo?: string
    price: number
    status?: any
    createdBy: UserModelType
    onSale: any
    mintingStatus?: string | null
    owned?: number
    idx?: string
    tokenId?: string
}

export type UserProductsModelType = {
    created: ProductModelType[] | null
    onSale: ProductModelType[] | null
    owned: ProductModelType[] | null
    submitted: ProductModelType[] | null
}
export interface ProductEntity {
    id: string
    title: string
    description: string
    videoUrl: string | undefined
    modelUrl: string | undefined
    arPackageUrl: string | undefined
    imageUrl: string | undefined
    dropDate: string
    brand: string
    count: number
    onSale: number
    price: number
    status: string
    createdBy: string
    designerId: string
    designerName: string | undefined
    mintingStatus: string | undefined
    owned: number
    idx: string
    tokenId: string | undefined
}

export type UserProductsEntity = {
    created: ProductEntity[] | null
    onSale: ProductEntity[] | null
    owned: ProductEntity[] | null
    submitted: ProductEntity[] | null
}

export class ProductEntity implements ProductEntity {
    constructor (product?: ProductModelType) {
        if(!product) return

        this.id = product._id || ''
        this.title = product.productName || ''
        this.description = product.description || ''
        this.videoUrl = product.productVideo?.url || undefined
        this.modelUrl = product.arModel?.url || undefined
        this.arPackageUrl = product.arPackage?.url || undefined
        this.imageUrl = product.productImage?.url || undefined
        this.dropDate = product.dropDate || ''
        this.brand = '../../brand2.png', // @TODO: should be fetched from API later
        this.count = product.numberOfModels || 0
        this.onSale =  product.onSale || 0 
        this.price = product.price || 0
        this.status =  product.status?.slug || ''
        this.createdBy = product.createdBy.username || ''
        this.designerId  = product.createdBy._id || ''
        this.designerName  = product.createdBy.profile?.fullName || ''
        this.mintingStatus = product.mintingStatus || undefined
        this.owned = Number(product.owned) || 0
        this.idx = product.idx || ''
        this.tokenId = product.tokenId
    }
}